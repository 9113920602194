import React, { useEffect, useState } from 'react';
import './productsubcatalogpage.scss';
import { useParams } from 'react-router-dom';
import productData from '../../data/ProductData.js';

const ProductSubCatalogPage = () => {
  const { categoryName, subcategoryName } = useParams(); // capture subcategoryName from route if available
  const [category, setCategory] = useState(null);
  const [subcategory, setSubcategory] = useState(null);

  useEffect(() => {
    const categoryData = productData.find(cat => cat.id === categoryName);
    setCategory(categoryData);

    if (categoryData && subcategoryName) {
      const foundSubcategory = categoryData.subcategories.find(sub => sub.id === subcategoryName);
      setSubcategory(foundSubcategory);
    } else {
      setSubcategory(null);
    }
  }, [categoryName, subcategoryName]);

  if (!category) {
    return <div>Loading...</div>;
  }

  const currentData = subcategory || category; // use subcategory data if it exists, otherwise use category data

  return (
    <div className="product-subcatalog-page">
      <div className="container">
        <div className="breadcrumb">
          <span className="breadcrumb-path">
            <span><a href="/"><i className="fa fa-home" aria-hidden="true"></i> Home</a></span>
            <span> &gt; </span> 
            <span><a href="/products">Products</a></span> 
            <span> &gt; </span> 
            <span><a href={"/products/" + category.id }>{category.category}</a></span>
            {subcategory && <>
              <span> &gt; </span>
              <span>{subcategory.category}</span>
            </>}
          </span>
        </div>
        <h1 className="title">{currentData.category}</h1>
        <p className='subcatalog-description'>{currentData.description}</p>
        <div className="subcatalog-list">
          {currentData.products ?
          (currentData.products.map((product, index) => (
            <div key={index} className="subcatalog-item">
              <a href={subcategory ? `/products/${category.id}/${subcategory.id}/p/${product.id}` : `/products/${currentData.id}/p/${product.id}`}>
                <img
                  src={product.image}
                  alt={product.name}
                />
                <h2>{product.name}</h2>
              </a>
            </div>
          ))) :
          (currentData.subcategories.map((subcategory, index) => (
            <div key={index} className="subcatalog-item">
              <a href={`/products/${currentData.id}/${subcategory.id}`}>
                <img
                  src={subcategory.categoryImage}
                  alt={subcategory.category}
                />
                <h2>{subcategory.category}</h2>
              </a>
            </div>
          ))) 
        }
        </div>
      </div>
    </div>
  );
};

export default ProductSubCatalogPage;