import './topcategoriesstyle2.scss';
import image1 from '../../../assets/frontpage/categories/Shoplot\ 3D\ Box\ Up\ Signboard.jpg'
import image2 from '../../../assets/frontpage/categories/3D LED Backlit Metal Gold Signage for Office.jpeg'
import image3 from '../../../assets/frontpage/categories/High Heel Logo customized double sided lightbox.jpg'

const TopCategoriesStyle2 = () => {
    const cardData = [
        { image: image1, title: 'Signboard', link: '/products/shop-lot-signboard' },
        { image: image2, title: '3D LED Signboard', link: '/products/3d-signboard' },
        { image: image3, title: 'Lightbox', link: '/products/lightbox' },
    ];

    return (
        <div className="top-categories-style-2">

            <div className="card-container">
                {cardData.map((card, index) => (
                    <div className="card" key={index}>
                        <div className={"card-image card-image-" + (index+1)}></div>
                        <div className="card-content">
                            <div className="card-content-inner">
                                
                            <h3 className="card-title">{card.title}</h3>
                            <a className="card-link" href={card.link}>Read More</a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TopCategoriesStyle2;
