import React, { useEffect, useRef } from 'react';
import './introduction.scss';

export default function Introduction() {
    const introductionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('fade-in-left');
                    entry.target.classList.remove('hidden');
                    observer.unobserve(entry.target);
                }
            });
        }, { threshold: 0.1 });

        if (introductionRef.current) {
            introductionRef.current.classList.add('hidden');
            observer.observe(introductionRef.current);
        }

        return () => observer.disconnect();
    }, []);

    return (
        <div className='panel-background'>
            <div className='container content-container'>
                <div className='row landing-wrapper'>
                    <div className='col-lg-6 separator'>
                        <div className='row introduction-text' ref={introductionRef}>
                            <h2 style={{ color: "#F68D26", marginBottom: 30 }}><b>LalaSigns</b></h2>
                            <p>Lalasigns is an experienced signboard manufacturer and supplier located in Selangor, Malaysia.</p>
                            <p>We aimed to provide affordable pricing for local businesses so that they can shine even with a limited budget.</p>
                            <p>Whether you need a 3D LED Signs for your business to shine bright like a diamond, or just an acrylic frame poster to decorate your place, lalasigns got you cover.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
