import React, { useEffect, useRef } from 'react';
import './whyus.scss';
import { FaBolt, FaStar, FaCogs } from 'react-icons/fa'; // Importing example icons from react-icons

const WhyUs = () => {
  const itemRefs = useRef([]);

  const info = [
    { title: "Fast", description: "We aimed to be the fastest signboard supplier in Malaysia: Quote Fast, Manufacture Fast, delivery Fast", icon: <FaBolt /> },
    { title: "Quality", description: "We promised to deliver quality products even when we are fast.", icon: <FaStar /> },
    { title: "Unique", description: "We develop latest signage manufacturing technology to make the most unique signboard for your business to stand out.", icon: <FaCogs /> }
  ];

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = entry.target.getAttribute('data-index');
          setTimeout(() => {
            entry.target.classList.add('fade-in-right');
            entry.target.classList.remove('hidden');
            observer.unobserve(entry.target);
          }, index * 300); // Stagger the animations by 300ms
        }
      });
    }, { threshold: 0.1 });

    itemRefs.current.forEach((item, index) => {
      if (item) {
        item.classList.add('hidden');
        item.setAttribute('data-index', index);
        observer.observe(item);
      }
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="whyus-container">
      <div className="row w-100">
        <div className="col-12 col-md-6 d-flex align-items-center justify-content-center" style={{backgroundColor: '#F68D26'}}>
          <div className="whyus-title"><span className='text-white'>Why Us?</span></div>
        </div>
        <div className="col-12 col-md-6 whyus-content">
          {info.map((item, index) => (
            <div
              key={index}
              className="item"
              ref={el => itemRefs.current[index] = el}
            >
              <div className="circle">
                {item.icon}
              </div>
              <div className="text">
                <h2 className="item-title">{item.title}</h2>
                <p className="item-description">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
