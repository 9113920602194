import './services.scss';
import designLogo from '../../../assets/icon/design-tool-paint.svg';
import workerLogo from '../../../assets/icon/worker.svg';
import { useEffect } from 'react';

const Services = () => {
    useEffect(() => {
        const serviceBlocks = document.querySelectorAll('.services-block');

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (entry.target.classList.contains('left')) {
                        entry.target.classList.add('fade-in-left');
                    } else if (entry.target.classList.contains('right')) {
                        entry.target.classList.add('fade-in-right');
                    }
                    entry.target.classList.remove('hidden');
                    observer.unobserve(entry.target);
                }
            });
        }, { threshold: 0.1 });

        serviceBlocks.forEach((block, index) => {
            block.classList.add('hidden');
            if (index === 0) {
                block.classList.add('left');
            } else if (index === 1) {
                block.classList.add('right');
            }
            observer.observe(block);
        });

        return () => observer.disconnect();
    }, []);

    return (
        <div className='services-container'>
            <h2 className='services-container-title'>
                <b>Expert <span style={{ color: '#A63C11' }}> signage solutions</span> and <span style={{ color: '#A63C11' }}>custom signboards</span> for all needs!</b>
            </h2>
            <div className="services-section">
                <div className="services-block">
                    <div className="icon"><img src={designLogo} alt="Design Logo" /></div>
                    <h2 className="services-title">Signage Solutions</h2>
                    <p className="services-text">
                        Our comprehensive signage solutions cover everything from design and fabrication to installation and maintenance.
                    </p>
                </div>
                <div className="services-block">
                    <div className="icon"><img src={workerLogo} alt="Worker Logo" /></div>
                    <h2 className="services-title">Signboard Manufacturing</h2>
                    <p className="services-text">
                        We design and produce a wide range of signboards from our factory to suit your specific needs.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Services;
