import React from 'react';
import './aboutus.scss';

const AboutUs = () => {
  return (
    <div className="about-us-page">
      <div className="main-content" style={{ paddingTop: '70px', paddingBottom: '70px' }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <div className="about-section mb-5">
                <h2 className="text-center mb-4">Signboard Company in Malaysia</h2>
                <p>
                  As a signboard solutions provider, our mission at Lalasigns is simple: to empower local businesses, and beyond with visually striking and effective signage that drives success. Whether you're a small boutique shop or a bustling restaurant, we believe that great signage shouldn't break the bank. That's why we're committed to providing you with cost-effective solutions without compromising on quality or creativity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-us-section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 text-center">
              <h1 className="mb-3 mt-5">Contact Us</h1>
              <h3 className="mb-4">Ready to take your business to the next level with our affordable signage solutions?</h3>
              <a href="/contact">
                <button className="btn btn-primary btn-lg btn-animated text-white">
                  <span>Enquire Now</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
