import './whyuscomponent.scss';

const features = [
  {
    icon: 'fa-bolt',
    title: 'Fast',
    description: 'We aimed to be the fastest signboard supplier in Malaysia: Quote Fast, Manufacture Fast, delivery Fast',
  },
  {
    icon: 'fa-check-circle',
    title: 'Quality',
    description: 'We promised to deliver quality products even when we are fast.',
  },
  {
    icon: 'fa-star',
    title: 'Unique',
    description: 'We develop latest signage manufacturing technology to make the most unique signboard for your business to stand out.',
  },
];

const WhyUsComponent = () => {
  return (
    <div className="features-container">
      {features.map((feature, index) => (
        <div key={index} className="feature-card">
          <h3 className="feature-title">
            <span className="icon-wrapper"><i className={`fa ${feature.icon}`} aria-hidden="true"></i></span> {feature.title}
          </h3>
          <p className="feature-description">{feature.description}</p>
        </div>
      ))}
    </div>
  );
};

export default WhyUsComponent;
