import './topcategories.scss';
import categories from '../../../data/ProductData'; // Import the product data


export default function TopCategories() {
    return (
        <section id="services-1829">
            <div className="cs-container">
                <div className="cs-content">
                    <h2 className="cs-title">Featured Categories</h2>
                    <p className="cs-text">Lalasigns provides competitive pricing for Malaysia SMEs</p>
                </div>
                <ul className="cs-card-group">
                    {categories.slice(0,4).map((category, index) => (
                        <li className="cs-item" key={index}>
                            <a href={"/Products/" + category.id} className="cs-link">
                                <picture className="cs-picture">
                                    <source media="(max-width: 600px)" srcSet={category.categoryImage} />
                                    <source media="(min-width: 601px)" srcSet={category.categoryImage} />
                                    <img loading="lazy" decoding="async" src={category.categoryImage} alt={`Category ${index + 1}`} />
                                </picture>
                                <div className="cs-flex">
                                    <h3 className="cs-h3">{ category.category}</h3>
                                </div>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
}
