import { Link } from "react-router-dom";
import { useState } from "react"; // Import useState
import '../Navbar/navbar.scss';
import logo_dark from '../../assets/logo_dark.jpg';

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false); // State for toggling the navbar

    // Function to toggle the navbar
    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-dark custom-navbar">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={logo_dark} alt="Lalasigns" height="70" />
                    </Link>
                    {/* Hamburger menu button */}
                    <button className="navbar-toggler" type="button" onClick={toggleNavbar} >
                        <span className="navbar-toggler-icon" ></span>
                    </button>
                    {/* Navbar items */}
                    <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className="nav-link" to="/" onClick={toggleNavbar}>HOME</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/about" onClick={toggleNavbar}>ABOUT US</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/Products" onClick={toggleNavbar}>PRODUCTS</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact" onClick={toggleNavbar}>CONTACT US</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}
