import './app.scss';
import './assets/scss/custom.scss'
import "@popperjs/core/dist/umd/popper.min.js";
import Bootstrap from 'bootstrap';
import Router from './page/Router';
import Navbar from './component/Navbar/Navbar';
import Footer from './component/Footer/Footer';

function App() {
	return (
		<div className='app-content'>
			<Navbar />
			<div className='page-content'>
				<Router />
			</div>
			<Footer />
		</div>
	);
}

export default App;
