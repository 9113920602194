const productData = [
  {
    category: "3D ALUMINIUM BOX UP LETTERING",
    categoryImage: require("../assets/products/3D ALUMINIUM BOX UP LETTERING/3D LED ALUMINIUM BOX UP LETTERING SIGN SUPPLY AT TANJONG SEPAT _ TELOK DATOK _ TELOK PANGLIMA GARANG _ TONGKAH.webp"),
    id: "3d-aluminium-box-up-lettering",
    description: ``,
    products: [
      {
        name: "3D LED ALUMINIUM BOX UP LETTERING SIGN SUPPLY AT TANJONG SEPAT _ TELOK DATOK _ TELOK PANGLIMA GARANG _ TONGKAH",
        image: require("../assets/products/3D ALUMINIUM BOX UP LETTERING/3D LED ALUMINIUM BOX UP LETTERING SIGN SUPPLY AT TANJONG SEPAT _ TELOK DATOK _ TELOK PANGLIMA GARANG _ TONGKAH.webp"),
        id: "3d-led-aluminium-box-up-lettering-sign-supply-at-tanjong-sepat-telok-datok-telok-panglima-garang-tongkah"
      },
      {
        name: "3D LED ALUMINIUM FRONT-LIT LANDMARK SIGNAGE AT SEREMBAN _ SENAWANG _ PORT DICKSON _ MELAKA _ ROMPIN",
        image: require("../assets/products/3D ALUMINIUM BOX UP LETTERING/3D LED ALUMINIUM FRONT-LIT LANDMARK SIGNAGE AT SEREMBAN _ SENAWANG _ PORT DICKSON _ MELAKA _ ROMPIN.webp"),
        id: "3d-led-aluminium-front-lit-landmark-signage-at-seremban-senawang-port-dickson-melaka-rompin"
      },
      {
        name: "BUILDING 3D ALUMINIUM BOX UP LETTERING FRONT-LIT SIGNAGE SPECIALIST AT SELANGOR _ KUALA LUMPUR (KL) _ SEPANG _ DENGKIL _ SEKINCHAN",
        image: require("../assets/products/3D ALUMINIUM BOX UP LETTERING/BUILDING 3D ALUMINIUM BOX UP LETTERING FRONT-LIT SIGNAGE SPECIALIST AT SELANGOR _ KUALA LUMPUR (KL) _ SEPANG _ DENGKIL _ SEKINCHAN.webp"),
        id: "building-3d-aluminium-box-up-lettering-front-lit-signage-specialist-at-selangor-kuala-lumpur-kl-sepang-dengkil-sekinchan"
      },
      {
        name: "CUSTOM 3D ALUMINIUM BOX UP LETTERING SIGNAGE EXPERTS AT KUALA LUMPUR (KL) _ KEPONG _ CHERAS _ SALAK SOUTH",
        image: require("../assets/products/3D ALUMINIUM BOX UP LETTERING/CUSTOM 3D ALUMINIUM BOX UP LETTERING SIGNAGE EXPERTS AT KUALA LUMPUR (KL) _ KEPONG _ CHERAS _ SALAK SOUTH.webp"),
        id: "custom-3d-aluminium-box-up-lettering-signage-experts-at-kuala-lumpur-kl-kepong-cheras-salak-south"
      },
      {
        name: "ICONIC 3D LED ALUMINIUM FRONT-LIT SIGN MAKER AT SEPANG _ DENGKIL _ SEKINCHAN _ JINJANG _ KEPONG _ SELANGOR",
        image: require("../assets/products/3D ALUMINIUM BOX UP LETTERING/ICONIC 3D LED ALUMINIUM FRONT-LIT SIGN MAKER AT SEPANG _ DENGKIL _ SEKINCHAN _ JINJANG _ KEPONG _ SELANGOR.webp"),
        id: "iconic-3d-led-aluminium-front-lit-sign-maker-at-sepang-dengkil-sekinchan-jinjang-kepong-selangor"
      },
      {
        name: "LARGE 3D LED FRONTL-LIT ALUMINUM BOX UP LETTERING SIGNBOARD MANUFACTURER IN MALAYSIA _ KAJANG _ RAWANG _ SUNGAI BULOH _ SEGAMBUT",
        image: require("../assets/products/3D ALUMINIUM BOX UP LETTERING/LARGE 3D LED FRONTL-LIT ALUMINUM BOX UP LETTERING SIGNBOARD MANUFACTURER IN MALAYSIA _ KAJANG _ RAWANG _ SUNGAI BULOH _ SEGAMBUT.webp"),
        id: "large-3d-led-frontl-lit-aluminum-box-up-lettering-signboard-manufacturer-in-malaysia-kajang-rawang-sungai-buloh-segambut"
      },
      {
        name: "ROOF TOP 3D ALUMINIUM BOX UP LETTERING SIGNAGE INSTALLED AT KUALA LUMPUR (KL) _ SEPANG _ CHERAS _ MELAKA",
        image: require("../assets/products/3D ALUMINIUM BOX UP LETTERING/ROOF TOP 3D ALUMINIUM BOX UP LETTERING SIGNAGE INSTALLED AT KUALA LUMPUR (KL) _ SEPANG _ CHERAS _ MELAKA.webp"),
        id: "roof-top-3d-aluminium-box-up-lettering-signage-installed-at-kuala-lumpur-kl-sepang-cheras-melaka"
      },
      {
        name: "STOREFRONT 3D LED ALUMINUM LETTERING SIGNBOARD SUPPLIER AT MALAYSIA _ BANTING _ SIJANGKANG _ DENGKIL _ SETAPAK",
        image: require("../assets/products/3D ALUMINIUM BOX UP LETTERING/STOREFRONT 3D LED ALUMINUM LETTERING SIGNBOARD SUPPLIER AT MALAYSIA _ BANTING _ SIJANGKANG _ DENGKIL _ SETAPAK.webp"),
        id: "storefront-3d-led-aluminum-lettering-signboard-supplier-at-malaysia-banting-sijangkang-dengkil-setapak"
      },
    ],
  },
  {
    category: "3D EG Box Up Lettering",
    categoryImage: require("../assets/products/3D EG Box Up Lettering/EG BOX UP LETTERING/Direction Signage.webp"),
    id: "3d-eg-box-up-lettering",
    description: ``,
    subcategories: [
      {
        category: "EG BOX UP LETTERING",
        categoryImage: require("../assets/products/3D EG Box Up Lettering/EG BOX UP LETTERING/Direction Signage.webp"),
        id: "eg-box-up-lettering",
        description: ``,
        products: [
          {
            name: "Direction Signage",
            image: require("../assets/products/3D EG Box Up Lettering/EG BOX UP LETTERING/Direction Signage.webp"),
            id: "direction-signage"
          },
          {
            name: "HOSPITAL BUILDING 3D EG BOX UP SIGNBOARD INSTALLED AT KLANG _ SUBANG JAYA _ USJ _ CHERAS",
            image: require("../assets/products/3D EG Box Up Lettering/EG BOX UP LETTERING/HOSPITAL BUILDING 3D EG BOX UP SIGNBOARD INSTALLED AT KLANG _ SUBANG JAYA _ USJ _ CHERAS.webp"),
            id: "hospital-building-3d-eg-box-up-signboard-installed-at-klang-subang-jaya-usj-cheras"
          },
          {
            name: "INDOOR EG BOX UP LETTERING SIGN SPECIALIST AT HICOM GLENMARIE _ JALAN IPOH _ JALAN YAP KWAN SENG",
            image: require("../assets/products/3D EG Box Up Lettering/EG BOX UP LETTERING/INDOOR EG BOX UP LETTERING SIGN SPECIALIST AT HICOM GLENMARIE _ JALAN IPOH _ JALAN YAP KWAN SENG.webp"),
            id: "indoor-eg-box-up-lettering-sign-specialist-at-hicom-glenmarie-jalan-ipoh-jalan-yap-kwan-seng"
          },
          {
            name: "_HSL_ Box Up Lettering",
            image: require("../assets/products/3D EG Box Up Lettering/EG BOX UP LETTERING/_HSL_ Box Up Lettering.webp"),
            id: "hsl-box-up-lettering"
          },
        ]
      },
      {
        category: "LED BACK-LIT EG BOX UP LETTERING",
        categoryImage: require("../assets/products/3D EG Box Up Lettering/LED BACK-LIT EG BOX UP LETTERING/3D EG LED BACK-LIT BOX UP LETTERING SIGNAGE AT KUALA LUMPUR (KL).webp"),
        id: "led-back-lit-eg-box-up-lettering",
        description: ``,
        products: [
          {
            name: "3D EG LED BACK-LIT BOX UP LETTERING SIGNAGE AT KUALA LUMPUR (KL)",
            image: require("../assets/products/3D EG Box Up Lettering/LED BACK-LIT EG BOX UP LETTERING/3D EG LED BACK-LIT BOX UP LETTERING SIGNAGE AT KUALA LUMPUR (KL).webp"),
            id: "3d-eg-led-back-lit-box-up-lettering-signage-at-kuala-lumpur-kl"
          },
          {
            name: "3D EG LED BACK-LIT BOX UP LOGO SIGNAGE IN MALAYSIA",
            image: require("../assets/products/3D EG Box Up Lettering/LED BACK-LIT EG BOX UP LETTERING/3D EG LED BACK-LIT BOX UP LOGO SIGNAGE IN MALAYSIA.webp"),
            id: "3d-eg-led-back-lit-box-up-logo-signage-in-malaysia"
          },
          {
            name: "3D LED BACK-LIT EG BOX UP LETTERING SIGN MAKER AT PUCHONG JAYA _ BUKIT PUCHONG _ BANDAR SUNWAY _ SS15",
            image: require("../assets/products/3D EG Box Up Lettering/LED BACK-LIT EG BOX UP LETTERING/3D LED BACK-LIT EG BOX UP LETTERING SIGN MAKER AT PUCHONG JAYA _ BUKIT PUCHONG _ BANDAR SUNWAY _ SS15.webp"),
            id: "3d-led-back-lit-eg-box-up-lettering-sign-maker-at-puchong-jaya-bukit-puchong-bandar-sunway-ss15"
          },
          {
            name: "DOUBLE-SIDED SIGNAGE WITH LED BACK-LIT EG BOX UP LETTERING SUPPLY AT SELANGOR _ MALAYSIA _ MELAKA _ PETALING JAYA (PJ) _ PANDAMARAN JAYA",
            image: require("../assets/products/3D EG Box Up Lettering/LED BACK-LIT EG BOX UP LETTERING/DOUBLE-SIDED SIGNAGE WITH LED BACK-LIT EG BOX UP LETTERING SUPPLY AT SELANGOR _ MALAYSIA _ MELAKA _ PETALING JAYA (PJ) _ PANDAMARAN JAYA.webp"),
            id: "double-sided-signage-with-led-back-lit-eg-box-up-lettering-supply-at-selangor-malaysia-melaka-petaling-jaya-pj-pandamaran-jaya"
          },
          {
            name: "INDOOR 3D LED BACK-LIT EG BOX UP LETTERING SIGN MAKER AT SENTUL _ TAMAN MELAWATI _ TAMAN SRI GOMBAK _ TAMAN SELAYANG MUTIARA",
            image: require("../assets/products/3D EG Box Up Lettering/LED BACK-LIT EG BOX UP LETTERING/INDOOR 3D LED BACK-LIT EG BOX UP LETTERING SIGN MAKER AT SENTUL _ TAMAN MELAWATI _ TAMAN SRI GOMBAK _ TAMAN SELAYANG MUTIARA.webp"),
            id: "indoor-3d-led-back-lit-eg-box-up-lettering-sign-maker-at-sentul-taman-melawati-taman-sri-gombak-taman-selayang-mutiara"
          },
          {
            name: "KEDAI PAPAN TANDA EG DI SHAH ALAM _ KOTA KEMUNING _ SETAPAK _ RAWANG",
            image: require("../assets/products/3D EG Box Up Lettering/LED BACK-LIT EG BOX UP LETTERING/KEDAI PAPAN TANDA EG DI SHAH ALAM _ KOTA KEMUNING _ SETAPAK _ RAWANG.webp"),
            id: "kedai-papan-tanda-eg-di-shah-alam-kota-kemuning-setapak-rawang"
          },
          {
            name: "OFFICE 3D LED BACK-LIT EG BOX UP LETTERING SIGN AT KUALA LUMPUR (KL) _ SERDANG _ AMPANG _ PUTRAJAYA",
            image: require("../assets/products/3D EG Box Up Lettering/LED BACK-LIT EG BOX UP LETTERING/OFFICE 3D LED BACK-LIT EG BOX UP LETTERING SIGN AT KUALA LUMPUR (KL) _ SERDANG _ AMPANG _ PUTRAJAYA.webp"),
            id: "office-3d-led-back-lit-eg-box-up-lettering-sign-at-kuala-lumpur-kl-serdang-ampang-putrajaya"
          },
        ]
      },
      {
        category: "LED FRONT-LIT & BACK-LIT EG BOX UP LETTERING",
        categoryImage: require("../assets/products/3D EG Box Up Lettering/LED FRONT-LIT & BACK-LIT EG BOX UP LETTERING/3D LED FRONT & BACKLIT EG BOX UP LETTERING SIGNAGE MAKER AT KEPONG _ IJOK _ AMPANG.webp"),
        id: "led-front-lit-back-lit-eg-box-up-lettering",
        description: ``,
        products: [
          {
            name: "3D LED FRONT & BACKLIT EG BOX UP LETTERING SIGNAGE MAKER AT KEPONG _ IJOK _ AMPANG",
            image: require("../assets/products/3D EG Box Up Lettering/LED FRONT-LIT & BACK-LIT EG BOX UP LETTERING/3D LED FRONT & BACKLIT EG BOX UP LETTERING SIGNAGE MAKER AT KEPONG _ IJOK _ AMPANG.webp"),
            id: "3d-led-front-backlit-eg-box-up-lettering-signage-maker-at-kepong-ijok-ampang"
          },
        ]
      },
      {
        category: "LED FRONT-LIT EG BOX UP LETTERING",
        categoryImage: require("../assets/products/3D EG Box Up Lettering/LED FRONT-LIT EG BOX UP LETTERING/3D LED FRONT-LIT EG BOX UP LETTERING SIGNAGE.webp"),
        id: "led-front-lit-eg-box-up-lettering",
        description: ``,
        products: [
          {
            name: "3D LED FRONT-LIT EG BOX UP LETTERING SIGNAGE",
            image: require("../assets/products/3D EG Box Up Lettering/LED FRONT-LIT EG BOX UP LETTERING/3D LED FRONT-LIT EG BOX UP LETTERING SIGNAGE.webp"),
            id: "3d-led-front-lit-eg-box-up-lettering-signage"
          },
          {
            name: "CLINIC 3D EG LED FRONT-LIT LETTERING SIGNAGE MAKER AT BANDAR SUNWAY _ BUKIT TINGGI KLANG _ USJ _ SRI HARTAMAS",
            image: require("../assets/products/3D EG Box Up Lettering/LED FRONT-LIT EG BOX UP LETTERING/CLINIC 3D EG LED FRONT-LIT LETTERING SIGNAGE MAKER AT BANDAR SUNWAY _ BUKIT TINGGI KLANG _ USJ _ SRI HARTAMAS.webp"),
            id: "clinic-3d-eg-led-front-lit-lettering-signage-maker-at-bandar-sunway-bukit-tinggi-klang-usj-sri-hartamas"
          },
          {
            name: "INDOOR 3D LED FRONT-LIT EG BOX UP SIGNBOARD SUPPLY AT SELANGOR _ SHAH ALAM _ SRI MUDA _ PUNCAK JALIL",
            image: require("../assets/products/3D EG Box Up Lettering/LED FRONT-LIT EG BOX UP LETTERING/INDOOR 3D LED FRONT-LIT EG BOX UP SIGNBOARD SUPPLY AT SELANGOR _ SHAH ALAM _ SRI MUDA _ PUNCAK JALIL.webp"),
            id: "indoor-3d-led-front-lit-eg-box-up-signboard-supply-at-selangor-shah-alam-sri-muda-puncak-jalil"
          },
          {
            name: "OFFICE 3D LED EG FRONT-LIT LOGO SIGNBOARD SUPPLIER AT BUKIT KIARA _ DAMANSARA HEIGHTS _ DESA PARK CITY _ JALAN DUTA",
            image: require("../assets/products/3D EG Box Up Lettering/LED FRONT-LIT EG BOX UP LETTERING/OFFICE 3D LED EG FRONT-LIT LOGO SIGNBOARD SUPPLIER AT BUKIT KIARA _ DAMANSARA HEIGHTS _ DESA PARK CITY _ JALAN DUTA.webp"),
            id: "office-3d-led-eg-front-lit-logo-signboard-supplier-at-bukit-kiara-damansara-heights-desa-park-city-jalan-duta"
          },
          {
            name: "OUTDOOR 3D LED EG FRONT-LIT LOGO SIGNBOARD INSTALLED AT BUKIT BINTANG _ SAUJANA IMPIAN _ TAMAN PUTRA PERDANA _ TAMAN MAS",
            image: require("../assets/products/3D EG Box Up Lettering/LED FRONT-LIT EG BOX UP LETTERING/OUTDOOR 3D LED EG FRONT-LIT LOGO SIGNBOARD INSTALLED AT BUKIT BINTANG _ SAUJANA IMPIAN _ TAMAN PUTRA PERDANA _ TAMAN MAS.webp"),
            id: "outdoor-3d-led-eg-front-lit-logo-signboard-installed-at-bukit-bintang-saujana-impian-taman-putra-perdana-taman-mas"
          },
        ]
      },
    ],
  },
  {
    category: "3D INFINITY MIRROR SIGN",
    categoryImage: require("../assets/products/3D INFINITY MIRROR SIGN/3D INFINITY MIRROR NEON SIGNAGE SUPPLY AT SELANGOR _ NILAI _ KAJANG _ SEREMBAN.webp"),
    id: "3d-infinity-mirror-sign",
    description: ``,
    products: [
      {
        name: "3D INFINITY MIRROR NEON SIGNAGE SUPPLY AT SELANGOR _ NILAI _ KAJANG _ SEREMBAN",
        image: require("../assets/products/3D INFINITY MIRROR SIGN/3D INFINITY MIRROR NEON SIGNAGE SUPPLY AT SELANGOR _ NILAI _ KAJANG _ SEREMBAN.webp"),
        id: "3d-infinity-mirror-neon-signage-supply-at-selangor-nilai-kajang-seremban"
      },
      {
        name: "CUSTOM 3D LIGHT INFINITY MIRROR SIGNAGE EXPERTS AT BANTING _ TELOK PANGIMA GARANG _ JUGRA _ MORIB _ BANDAR SAUJANA PUTRA",
        image: require("../assets/products/3D INFINITY MIRROR SIGN/CUSTOM 3D LIGHT INFINITY MIRROR SIGNAGE EXPERTS AT BANTING _ TELOK PANGIMA GARANG _ JUGRA _ MORIB _ BANDAR SAUJANA PUTRA.webp"),
        id: "custom-3d-light-infinity-mirror-signage-experts-at-banting-telok-pangima-garang-jugra-morib-bandar-saujana-putra"
      },
      {
        name: "MODERN 3D LED INFINITY MIRROR SIGN MAKER AT KLANG _ SUBANG _ PUCHONG _ CHERAS",
        image: require("../assets/products/3D INFINITY MIRROR SIGN/MODERN 3D LED INFINITY MIRROR SIGN MAKER AT KLANG _ SUBANG _ PUCHONG _ CHERAS.webp"),
        id: "modern-3d-led-infinity-mirror-sign-maker-at-klang-subang-puchong-cheras"
      },
      {
        name: "VIRAL 3D INFINITY MIRROR SIGN",
        image: require("../assets/products/3D INFINITY MIRROR SIGN/VIRAL 3D INFINITY MIRROR SIGN.jpg"),
        id: "viral-3d-infinity-mirror-sign"
      },
    ],
  },
  {
    category: "3D LED LETTERING SIGNBOARD",
    categoryImage: require("../assets/products/3D LED LETTERING SIGNBOARD/3D BUILDING SIGNAGE INSTALLED AT SAUJANA IMPIAN _ SAUJANA PUTRA _ SUBANG HEIGHTS.webp"),
    id: "3d-led-lettering-signboard",
    description: ``,
    products: [
      {
        name: "3D BUILDING SIGNAGE INSTALLED AT SAUJANA IMPIAN _ SAUJANA PUTRA _ SUBANG HEIGHTS",
        image: require("../assets/products/3D LED LETTERING SIGNBOARD/3D BUILDING SIGNAGE INSTALLED AT SAUJANA IMPIAN _ SAUJANA PUTRA _ SUBANG HEIGHTS.webp"),
        id: "3d-building-signage-installed-at-saujana-impian-saujana-putra-subang-heights"
      },
      {
        name: "3D LED CONCEAL BOX UP LETTERING SIGNAGE AT MALAYSIA _ KUALA LUMPUR (KL)",
        image: require("../assets/products/3D LED LETTERING SIGNBOARD/3D LED CONCEAL BOX UP LETTERING SIGNAGE AT MALAYSIA _ KUALA LUMPUR (KL).webp"),
        id: "3d-led-conceal-box-up-lettering-signage-at-malaysia-kuala-lumpur-kl"
      },
      {
        name: "BUILDING 3D LED LETTERING SIGNAGE AT KUALA LUMPUR (KL) _ MALAYSIA _ SEREMBAN _ SELANGOR",
        image: require("../assets/products/3D LED LETTERING SIGNBOARD/BUILDING 3D LED LETTERING SIGNAGE AT KUALA LUMPUR (KL) _ MALAYSIA _ SEREMBAN _ SELANGOR.webp"),
        id: "building-3d-led-lettering-signage-at-kuala-lumpur-kl-malaysia-seremban-selangor"
      },
      {
        name: "CLINIC 3D LED SIGNAGE AT KUALA LUMPUR (KL) _ PUCHONG _ TAMAN OUG _ CHERAS _ IJOK",
        image: require("../assets/products/3D LED LETTERING SIGNBOARD/CLINIC 3D LED SIGNAGE AT KUALA LUMPUR (KL) _ PUCHONG _ TAMAN OUG _ CHERAS _ IJOK.webp"),
        id: "clinic-3d-led-signage-at-kuala-lumpur-kl-puchong-taman-oug-cheras-ijok"
      },
      {
        name: "FACTORY 3D LED LETTERING WALL DECOR SIGNAGE AT KUALA LUMPUR (KL) _ PUCHONG _ TAMAN OUG _ CHERAS _ IJOK",
        image: require("../assets/products/3D LED LETTERING SIGNBOARD/FACTORY 3D LED LETTERING WALL DECOR SIGNAGE AT KUALA LUMPUR (KL) _ PUCHONG _ TAMAN OUG _ CHERAS _ IJOK.webp"),
        id: "factory-3d-led-lettering-wall-decor-signage-at-kuala-lumpur-kl-puchong-taman-oug-cheras-ijok"
      },
      {
        name: "HOTEL 3D LED SIGNAGE AT KUALA LUMPUR (KL) _ PUCHONG _ TAMAN OUG _ CHERAS _ IJOK",
        image: require("../assets/products/3D LED LETTERING SIGNBOARD/HOTEL 3D LED SIGNAGE AT KUALA LUMPUR (KL) _ PUCHONG _ TAMAN OUG _ CHERAS _ IJOK.webp"),
        id: "hotel-3d-led-signage-at-kuala-lumpur-kl-puchong-taman-oug-cheras-ijok"
      },
      {
        name: "KEDAI PAPAN TANDA 3D MALAYSIA",
        image: require("../assets/products/3D LED LETTERING SIGNBOARD/KEDAI PAPAN TANDA 3D MALAYSIA.webp"),
        id: "kedai-papan-tanda-3d-malaysia"
      },
      {
        name: "LED 3D LETTERING SIGNAGE AT KUALA LUMPUR (KL) _ MALAYSIA _ SEREMBAN _ SELANGOR",
        image: require("../assets/products/3D LED LETTERING SIGNBOARD/LED 3D LETTERING SIGNAGE AT KUALA LUMPUR (KL) _ MALAYSIA _ SEREMBAN _ SELANGOR.webp"),
        id: "led-3d-lettering-signage-at-kuala-lumpur-kl-malaysia-seremban-selangor"
      },
      {
        name: "PET SHOP 3D LED LETTERING SIGNAGE AT KUALA LUMPUR (KL) _ MALAYSIA _ SEREMBAN _ SELANGOR",
        image: require("../assets/products/3D LED LETTERING SIGNBOARD/PET SHOP 3D LED LETTERING SIGNAGE AT KUALA LUMPUR (KL) _ MALAYSIA _ SEREMBAN _ SELANGOR.webp"),
        id: "pet-shop-3d-led-lettering-signage-at-kuala-lumpur-kl-malaysia-seremban-selangor"
      },
      {
        name: "RESTAURANT 3D LED SIGNAGE AT KUALA LUMPUR (KL) _ PUCHONG _ TAMAN OUG _ CHERAS _ IJOK",
        image: require("../assets/products/3D LED LETTERING SIGNBOARD/RESTAURANT 3D LED SIGNAGE AT KUALA LUMPUR (KL) _ PUCHONG _ TAMAN OUG _ CHERAS _ IJOK.webp"),
        id: "restaurant-3d-led-signage-at-kuala-lumpur-kl-puchong-taman-oug-cheras-ijok"
      },
      {
        name: "SHOPFRONT 3D LED LETTERING SIGNAGE AT KUALA LUMPUR (KL) _ MALAYSIA _ SEREMBAN _ SELANGOR",
        image: require("../assets/products/3D LED LETTERING SIGNBOARD/SHOPFRONT 3D LED LETTERING SIGNAGE AT KUALA LUMPUR (KL) _ MALAYSIA _ SEREMBAN _ SELANGOR.webp"),
        id: "shopfront-3d-led-lettering-signage-at-kuala-lumpur-kl-malaysia-seremban-selangor"
      },
      {
        name: "SHOPFRONT 3D LED SIGNAGE AT KUALA LUMPUR (KL) _ PUCHONG _ TAMAN OUG _ CHERAS _ IJOK",
        image: require("../assets/products/3D LED LETTERING SIGNBOARD/SHOPFRONT 3D LED SIGNAGE AT KUALA LUMPUR (KL) _ PUCHONG _ TAMAN OUG _ CHERAS _ IJOK.webp"),
        id: "shopfront-3d-led-signage-at-kuala-lumpur-kl-puchong-taman-oug-cheras-ijok"
      },
    ],
  },
  {
    category: "3D LED LETTERING THROUGH-HOLE PIXEL",
    categoryImage: require("../assets/products/3D LED LETTERING THROUGH-HOLE PIXEL/3D LED LETTERING THROUGH-HOLE PIXEL INSTALLED AT SUBANG _ PUCHONG _ CHERAS.webp"),
    id: "3d-led-lettering-through-hole-pixel",
    description: ``,
    products: [
      {
        name: "3D LED LETTERING THROUGH-HOLE PIXEL INSTALLED AT SUBANG _ PUCHONG _ CHERAS",
        image: require("../assets/products/3D LED LETTERING THROUGH-HOLE PIXEL/3D LED LETTERING THROUGH-HOLE PIXEL INSTALLED AT SUBANG _ PUCHONG _ CHERAS.webp"),
        id: "3d-led-lettering-through-hole-pixel-installed-at-subang-puchong-cheras"
      },
      {
        name: "OUTDOOR 3D LED LETTERING THROUGH-HOLE PIXEL SUPPLY AT KOTA DAMANSARA _ SRI HARTAMAS",
        image: require("../assets/products/3D LED LETTERING THROUGH-HOLE PIXEL/OUTDOOR 3D LED LETTERING THROUGH-HOLE PIXEL SUPPLY AT KOTA DAMANSARA _ SRI HARTAMAS.webp"),
        id: "outdoor-3d-led-lettering-through-hole-pixel-supply-at-kota-damansara-sri-hartamas"
      },
    ],
  },
  {
    category: "3D PRINTER LETTERING",
    categoryImage: require("../assets/products/3D PRINTER LETTERING/BEST 3D PRINTER LETTERING SIGNAGE SUPPLY AT SELANGOR _ KLANG.webp"),
    id: "3d-printer-lettering",
    description: ``,
    products: [
      {
        name: "BEST 3D PRINTER LETTERING SIGNAGE SUPPLY AT SELANGOR _ KLANG",
        image: require("../assets/products/3D PRINTER LETTERING/BEST 3D PRINTER LETTERING SIGNAGE SUPPLY AT SELANGOR _ KLANG.webp"),
        id: "best-3d-printer-lettering-signage-supply-at-selangor-klang"
      },
    ],
  },
  {
    category: "3D Signboard",
    categoryImage: require("../assets/products/3D Signboard/3D LED Backlit Metal Gold Signage for Office.jpeg"),
    id: "3d-signboard",
    description: `Elevate your business with our high-quality 3D signboards, designed for durability and a striking visual appeal. Perfect for indoor and outdoor use, these signboards add depth and character to your brand. Ideal for storefronts, offices, and events, we also offer custom designs to meet your specific needs. Contact us to create a unique 3D signboard that makes a bold statement for your business.`,
    products: [
      {
        name: "3D LED Backlit Metal Gold Signage for Office",
        image: require("../assets/products/3D Signboard/3D LED Backlit Metal Gold Signage for Office.jpeg"),
        id: "3d-led-backlit-metal-gold-signage-for-office"
      },
      {
        name: "3D LED Backlit Metal Gold Signage",
        image: require("../assets/products/3D Signboard/3D LED Backlit Metal Gold Signage.jpeg"),
        id: "3d-led-backlit-metal-gold-signage"
      },
      {
        name: "3D LED Backlit Signage ",
        image: require("../assets/products/3D Signboard/3D LED Backlit Signage .jpeg"),
        id: "3d-led-backlit-signage"
      },
      {
        name: "3D LED Frontlit Signage",
        image: require("../assets/products/3D Signboard/3D LED Frontlit Signage.jpg"),
        id: "3d-led-frontlit-signage"
      },
      {
        name: "Acrylic 3D Signage Indoor",
        image: require("../assets/products/3D Signboard/Acrylic 3D Signage Indoor.jpg"),
        id: "acrylic-3d-signage-indoor"
      },
      {
        name: "Acrylic 3D Signage",
        image: require("../assets/products/3D Signboard/Acrylic 3D Signage.jpeg"),
        id: "acrylic-3d-signage"
      },
      {
        name: "Acrylic 3D Signboard Indoor Signboard",
        image: require("../assets/products/3D Signboard/Acrylic 3D Signboard Indoor Signboard.jpeg"),
        id: "acrylic-3d-signboard-indoor-signboard"
      },
      {
        name: "Building 3D Signage ",
        image: require("../assets/products/3D Signboard/Building 3D Signage .jpeg"),
        id: "building-3d-signage"
      },
      {
        name: "Cafe 3D LED Backlit Signage",
        image: require("../assets/products/3D Signboard/Cafe 3D LED Backlit Signage.jpg"),
        id: "cafe-3d-led-backlit-signage"
      },
      {
        name: "Cafe Backlit Signage",
        image: require("../assets/products/3D Signboard/Cafe Backlit Signage.jpg"),
        id: "cafe-backlit-signage"
      },
      {
        name: "Clinic 3D Aesthetic Signage",
        image: require("../assets/products/3D Signboard/Clinic 3D Aesthetic Signage.jpg"),
        id: "clinic-3d-aesthetic-signage"
      },
      {
        name: "Clinic 3D LED Backlit Signboard",
        image: require("../assets/products/3D Signboard/Clinic 3D LED Backlit Signboard.jpeg"),
        id: "clinic-3d-led-backlit-signboard"
      },
      {
        name: "Factory Front Gate 3D Signage",
        image: require("../assets/products/3D Signboard/Factory Front Gate 3D Signage.jpg"),
        id: "factory-front-gate-3d-signage"
      },
      {
        name: "Factory Gate 3D Metal Signage",
        image: require("../assets/products/3D Signboard/Factory Gate 3D Metal Signage.jpeg"),
        id: "factory-gate-3d-metal-signage"
      },
      {
        name: "Hot Pot Shop 3D LED Frontlit Signage ",
        image: require("../assets/products/3D Signboard/Hot Pot Shop 3D LED Frontlit Signage .jpeg"),
        id: "hot-pot-shop-3d-led-frontlit-signage"
      },
      {
        name: "I love guang hua zhao pai",
        image: require("../assets/products/3D Signboard/I love guang hua zhao pai.jpg"),
        id: "i-love-guang-hua-zhao-pai"
      },
      {
        name: "Kafe 3D Boxup Signboard",
        image: require("../assets/products/3D Signboard/Kafe 3D Boxup Signboard.jpeg"),
        id: "kafe-3d-boxup-signboard"
      },
      {
        name: "Kafe 3D Frontlit Signage",
        image: require("../assets/products/3D Signboard/Kafe 3D Frontlit Signage.jpg"),
        id: "kafe-3d-frontlit-signage"
      },
      {
        name: "Kopitiam 3D Boxup Signboard",
        image: require("../assets/products/3D Signboard/Kopitiam 3D Boxup Signboard.jpeg"),
        id: "kopitiam-3d-boxup-signboard"
      },
      {
        name: "Kuang Hwa 3D Landmark Signage",
        image: require("../assets/products/3D Signboard/Kuang Hwa 3D Landmark Signage.jpg"),
        id: "kuang-hwa-3d-landmark-signage"
      },
      {
        name: "Maersk 3D LED Frontlit Signage",
        image: require("../assets/products/3D Signboard/Maersk 3D LED Frontlit Signage.jpeg"),
        id: "maersk-3d-led-frontlit-signage"
      },
      {
        name: "Poliklinik 3D Boxup Signboard",
        image: require("../assets/products/3D Signboard/Poliklinik 3D Boxup Signboard.jpeg"),
        id: "poliklinik-3d-boxup-signboard"
      },
      {
        name: "Shoplot 3D Box Up Signboard",
        image: require("../assets/products/3D Signboard/Shoplot 3D Box Up Signboard.jpg"),
        id: "shoplot-3d-box-up-signboard"
      },
      {
        name: "Sirim Academy 3D Building Signage",
        image: require("../assets/products/3D Signboard/Sirim Academy 3D Building Signage.jpeg"),
        id: "sirim-academy-3d-building-signage"
      },
    ],
  },
  {
    category: "3D Stainless Steel Box Up Lettering",
    categoryImage: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL BLACK CHROME BOX UP/EXTERIOR STAINLESS STEEL BLACK CHROME BOX UP LETTERING SIGN MAKER ATS NILAI _ SEREMBAN _ MELAKA.webp"),
    id: "3d-stainless-steel-box-up-lettering",
    description: ``,
    subcategories: [
      {
        category: "STAINLESS STEEL BLACK CHROME BOX UP",
        categoryImage: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL BLACK CHROME BOX UP/EXTERIOR STAINLESS STEEL BLACK CHROME BOX UP LETTERING SIGN MAKER ATS NILAI _ SEREMBAN _ MELAKA.webp"),
        id: "stainless-steel-black-chrome-box-up",
        description: ``,
        products: [
          {
            name: "EXTERIOR STAINLESS STEEL BLACK CHROME BOX UP LETTERING SIGN MAKER ATS NILAI _ SEREMBAN _ MELAKA",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL BLACK CHROME BOX UP/EXTERIOR STAINLESS STEEL BLACK CHROME BOX UP LETTERING SIGN MAKER ATS NILAI _ SEREMBAN _ MELAKA.webp"),
            id: "exterior-stainless-steel-black-chrome-box-up-lettering-sign-maker-ats-nilai-seremban-melaka"
          },
          {
            name: "STAINLESS STEEL LED BACK-LIT BLACK CHROME BOX UP SIGNBOARD SUPPLY AT PORT KLANG _ BUKIT RAJA _ SETIA ALAM _ PUNCAK ALAM",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL BLACK CHROME BOX UP/STAINLESS STEEL LED BACK-LIT BLACK CHROME BOX UP SIGNBOARD SUPPLY AT PORT KLANG _ BUKIT RAJA _ SETIA ALAM _ PUNCAK ALAM.webp"),
            id: "stainless-steel-led-back-lit-black-chrome-box-up-signboard-supply-at-port-klang-bukit-raja-setia-alam-puncak-alam"
          },
        ]
      },
      {
        category: "STAINLESS STEEL GOLD BOX UP",
        categoryImage: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL GOLD BOX UP/BALLROOM 3D LED BACK-LIT STAINLESS STEEL GOLD HAIRLINE SIGNAGE MAKER AT KUALA LUMPUR (KL) _ AMPANG _ IJOK _ NILAI.webp"),
        id: "stainless-steel-gold-box-up",
        description: ``,
        products: [
          {
            name: "BALLROOM 3D LED BACK-LIT STAINLESS STEEL GOLD HAIRLINE SIGNAGE MAKER AT KUALA LUMPUR (KL) _ AMPANG _ IJOK _ NILAI",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL GOLD BOX UP/BALLROOM 3D LED BACK-LIT STAINLESS STEEL GOLD HAIRLINE SIGNAGE MAKER AT KUALA LUMPUR (KL) _ AMPANG _ IJOK _ NILAI.webp"),
            id: "ballroom-3d-led-back-lit-stainless-steel-gold-hairline-signage-maker-at-kuala-lumpur-kl-ampang-ijok-nilai"
          },
          {
            name: "CUSTOM STAINLESS STEEL GOLD MIRROR BOX UP WALL DECOR SIGN AT KLANG _ SHAH ALAM _ ALAM IMPIAN _ KOTA KEMUNING",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL GOLD BOX UP/CUSTOM STAINLESS STEEL GOLD MIRROR BOX UP WALL DECOR SIGN AT KLANG _ SHAH ALAM _ ALAM IMPIAN _ KOTA KEMUNING.webp"),
            id: "custom-stainless-steel-gold-mirror-box-up-wall-decor-sign-at-klang-shah-alam-alam-impian-kota-kemuning"
          },
          {
            name: "INDOOR STAINLESS STEEL GOLD BOX UP MIRROR SIGNAGE AT AMPANG _ NILAI _ KAJANG _ CHERAS",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL GOLD BOX UP/INDOOR STAINLESS STEEL GOLD BOX UP MIRROR SIGNAGE AT AMPANG _ NILAI _ KAJANG _ CHERAS.webp"),
            id: "indoor-stainless-steel-gold-box-up-mirror-signage-at-ampang-nilai-kajang-cheras"
          },
          {
            name: "OFFICE STAINLESS STEEL GOLD HAIRLINE BACK-LIT BOX UP LETTERING SIGNAGE AT PUCHONG _ CHERAS _ KAJANG _ C180",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL GOLD BOX UP/OFFICE STAINLESS STEEL GOLD HAIRLINE BACK-LIT BOX UP LETTERING SIGNAGE AT PUCHONG _ CHERAS _ KAJANG _ C180.webp"),
            id: "office-stainless-steel-gold-hairline-back-lit-box-up-lettering-signage-at-puchong-cheras-kajang-c180"
          },
          {
            name: "RESTAURANT 3D STAINLESS STEEL GOLD MIRROR LED BACK-LIT LETTERING SIGN MANUFACTURER AT GOMBAK _ BANGI _ SENAWANG _ RAWANG",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL GOLD BOX UP/RESTAURANT 3D STAINLESS STEEL GOLD MIRROR LED BACK-LIT LETTERING SIGN MANUFACTURER AT GOMBAK _ BANGI _ SENAWANG _ RAWANG.webp"),
            id: "restaurant-3d-stainless-steel-gold-mirror-led-back-lit-lettering-sign-manufacturer-at-gombak-bangi-senawang-rawang"
          },
          {
            name: "SHOPFRONT 3D LED BACK-LIT STAINLESS STEEL GOLD MIRROR BOX UP LETTERING SIGNBOARD AT BUKIT JALIL _ PUNCAK JALIL _ ALAM IMPIAN",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL GOLD BOX UP/SHOPFRONT 3D LED BACK-LIT STAINLESS STEEL GOLD MIRROR BOX UP LETTERING SIGNBOARD AT BUKIT JALIL _ PUNCAK JALIL _ ALAM IMPIAN.webp"),
            id: "shopfront-3d-led-back-lit-stainless-steel-gold-mirror-box-up-lettering-signboard-at-bukit-jalil-puncak-jalil-alam-impian"
          },
          {
            name: "STAINLESS STEEL GOLD HAIRLINE BACK-LIT BOX UP LOGO SIGN AT IJOK _ KLANG _ SUBANG _ PUCHONG",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL GOLD BOX UP/STAINLESS STEEL GOLD HAIRLINE BACK-LIT BOX UP LOGO SIGN AT IJOK _ KLANG _ SUBANG _ PUCHONG.webp"),
            id: "stainless-steel-gold-hairline-back-lit-box-up-logo-sign-at-ijok-klang-subang-puchong"
          },
          {
            name: "STAINLESS STEEL GOLD HAIRLINE BOX UP SIGN SUPPLIER AT KAPAR _ BUKIT RAJA _ BESTARI JAYA _ SUNGAI BULOH",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL GOLD BOX UP/STAINLESS STEEL GOLD HAIRLINE BOX UP SIGN SUPPLIER AT KAPAR _ BUKIT RAJA _ BESTARI JAYA _ SUNGAI BULOH.webp"),
            id: "stainless-steel-gold-hairline-box-up-sign-supplier-at-kapar-bukit-raja-bestari-jaya-sungai-buloh"
          },
          {
            name: "STAINLESS STEEL GOLD HAIRLINE BOX UP SURFACE SIGNAGE SPECIALIST AT SRI HARTMAS _ MONT KIARA _ SUNGAI BULOH _ KEPONG",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL GOLD BOX UP/STAINLESS STEEL GOLD HAIRLINE BOX UP SURFACE SIGNAGE SPECIALIST AT SRI HARTMAS _ MONT KIARA _ SUNGAI BULOH _ KEPONG.webp"),
            id: "stainless-steel-gold-hairline-box-up-surface-signage-specialist-at-sri-hartmas-mont-kiara-sungai-buloh-kepong"
          },
          {
            name: "STAINLESS STEEL GOLD MIRROR LED BACK-LIT SIGNBOARD MAKER AT SETIA ALAM _ BUKIT RAJA _ PUNCAK ALAM",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL GOLD BOX UP/STAINLESS STEEL GOLD MIRROR LED BACK-LIT SIGNBOARD MAKER AT SETIA ALAM _ BUKIT RAJA _ PUNCAK ALAM.webp"),
            id: "stainless-steel-gold-mirror-led-back-lit-signboard-maker-at-setia-alam-bukit-raja-puncak-alam"
          },
        ]
      },
      {
        category: "STAINLESS STEEL RED WINE BOX UP",
        categoryImage: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL RED WINE BOX UP/3D STAINLESS STEEL RED WINE BACK-LIT SIGNAGE SUPPLIER AT KUALA LUMPUR (KL) _ KEPONG _ CHERAS _ BANGI.webp"),
        id: "stainless-steel-red-wine-box-up",
        description: ``,
        products: [
          {
            name: "3D STAINLESS STEEL RED WINE BACK-LIT SIGNAGE SUPPLIER AT KUALA LUMPUR (KL) _ KEPONG _ CHERAS _ BANGI",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL RED WINE BOX UP/3D STAINLESS STEEL RED WINE BACK-LIT SIGNAGE SUPPLIER AT KUALA LUMPUR (KL) _ KEPONG _ CHERAS _ BANGI.webp"),
            id: "3d-stainless-steel-red-wine-back-lit-signage-supplier-at-kuala-lumpur-kl-kepong-cheras-bangi"
          },
          {
            name: "CLINIC 3D STAINLESS STEEL RED WINE BOX UP LETTERING SIGN SUPPLY AT KAJANG _ BUKIT JALIL _ BUKIT BINTANG _ PUDU",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL RED WINE BOX UP/CLINIC 3D STAINLESS STEEL RED WINE BOX UP LETTERING SIGN SUPPLY AT KAJANG _ BUKIT JALIL _ BUKIT BINTANG _ PUDU.webp"),
            id: "clinic-3d-stainless-steel-red-wine-box-up-lettering-sign-supply-at-kajang-bukit-jalil-bukit-bintang-pudu"
          },
        ]
      },
      {
        category: "STAINLESS STEEL ROSE GOLD BOX UP",
        categoryImage: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL ROSE GOLD BOX UP/CONDO STAINLESS STEEL ROSE GOLD HAIRLINE BOX UP LETTERING INSTALLED AT SELANGOR _ PETALING JAYA (PJ) _ MALAYSIA.webp"),
        id: "stainless-steel-rose-gold-box-up",
        description: ``,
        products: [
          {
            name: "CONDO STAINLESS STEEL ROSE GOLD HAIRLINE BOX UP LETTERING INSTALLED AT SELANGOR _ PETALING JAYA (PJ) _ MALAYSIA",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL ROSE GOLD BOX UP/CONDO STAINLESS STEEL ROSE GOLD HAIRLINE BOX UP LETTERING INSTALLED AT SELANGOR _ PETALING JAYA (PJ) _ MALAYSIA.webp"),
            id: "condo-stainless-steel-rose-gold-hairline-box-up-lettering-installed-at-selangor-petaling-jaya-pj-malaysia"
          },
          {
            name: "FACTORY STAINLESS STEEL ROSE GOLD BOX UP LETTERING WALL DECOR SIGN AT CHERAS _ BALAKONG _ TAMING JAYA",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL ROSE GOLD BOX UP/FACTORY STAINLESS STEEL ROSE GOLD BOX UP LETTERING WALL DECOR SIGN AT CHERAS _ BALAKONG _ TAMING JAYA.webp"),
            id: "factory-stainless-steel-rose-gold-box-up-lettering-wall-decor-sign-at-cheras-balakong-taming-jaya"
          },
          {
            name: "OFFICE 3D LED BACK-LIT STAINLESS STEEL ROSE GOLD MIRROR SIGNAGE MAKER AT ROMPIN _ LABU _ SENAWANG _ NILAI",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL ROSE GOLD BOX UP/OFFICE 3D LED BACK-LIT STAINLESS STEEL ROSE GOLD MIRROR SIGNAGE MAKER AT ROMPIN _ LABU _ SENAWANG _ NILAI.webp"),
            id: "office-3d-led-back-lit-stainless-steel-rose-gold-mirror-signage-maker-at-rompin-labu-senawang-nilai"
          },
          {
            name: "STAINELSS STEEL ROSE GOLD BOX UP LETTERING SIGNBOARD AT KAJANG _ IJOK _ SENAWANG _ SEGAMBUT",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL ROSE GOLD BOX UP/STAINELSS STEEL ROSE GOLD BOX UP LETTERING SIGNBOARD AT KAJANG _ IJOK _ SENAWANG _ SEGAMBUT.webp"),
            id: "stainelss-steel-rose-gold-box-up-lettering-signboard-at-kajang-ijok-senawang-segambut"
          },
        ]
      },
      {
        category: "STAINLESS STEEL SILVER BOX UP",
        categoryImage: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL SILVER BOX UP/3D STAINLESS STEEL HAIRLINE BOX UP LETTERING LANDMARK SIGNBOARD SUPPLY AT RIMBAYU _ ELMINA _ KEPONG _ JINJANG.webp"),
        id: "stainless-steel-silver-box-up",
        description: ``,
        products: [
          {
            name: "3D STAINLESS STEEL HAIRLINE BOX UP LETTERING LANDMARK SIGNBOARD SUPPLY AT RIMBAYU _ ELMINA _ KEPONG _ JINJANG",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL SILVER BOX UP/3D STAINLESS STEEL HAIRLINE BOX UP LETTERING LANDMARK SIGNBOARD SUPPLY AT RIMBAYU _ ELMINA _ KEPONG _ JINJANG.webp"),
            id: "3d-stainless-steel-hairline-box-up-lettering-landmark-signboard-supply-at-rimbayu-elmina-kepong-jinjang"
          },
          {
            name: "BUILDING STAINLESS STEEL SILVER HAIRLINE BOX UP LETTERING SIGN INSTALLED AT KLANG _ SELANGOR _ MALAYSIA",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL SILVER BOX UP/BUILDING STAINLESS STEEL SILVER HAIRLINE BOX UP LETTERING SIGN INSTALLED AT KLANG _ SELANGOR _ MALAYSIA.webp"),
            id: "building-stainless-steel-silver-hairline-box-up-lettering-sign-installed-at-klang-selangor-malaysia"
          },
          {
            name: "INDOOR STAINLESS STEEL BOX UP LETTERING SIGNBOARD AT KLANG _ GOMBAK _ IJOK _ BESTARI JAYA",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL SILVER BOX UP/INDOOR STAINLESS STEEL BOX UP LETTERING SIGNBOARD AT KLANG _ GOMBAK _ IJOK _ BESTARI JAYA.webp"),
            id: "indoor-stainless-steel-box-up-lettering-signboard-at-klang-gombak-ijok-bestari-jaya"
          },
          {
            name: "INDOOR STAINLESS STEEL SILVER HAIRLINE LETTERING PLATE MAKER AT KUCHAI LAMA _ KELANA JAYA _ BANDAR KINRARA",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL SILVER BOX UP/INDOOR STAINLESS STEEL SILVER HAIRLINE LETTERING PLATE MAKER AT KUCHAI LAMA _ KELANA JAYA _ BANDAR KINRARA.webp"),
            id: "indoor-stainless-steel-silver-hairline-lettering-plate-maker-at-kuchai-lama-kelana-jaya-bandar-kinrara"
          },
          {
            name: "INTERIOR 3D LED BACK-LIT STAINLESS STEEL SILVER HAIRLINE BOX UP LETTERING SIGN AT MELAKA _ SEREMBAN _ SENAWANG",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL SILVER BOX UP/INTERIOR 3D LED BACK-LIT STAINLESS STEEL SILVER HAIRLINE BOX UP LETTERING SIGN AT MELAKA _ SEREMBAN _ SENAWANG.webp"),
            id: "interior-3d-led-back-lit-stainless-steel-silver-hairline-box-up-lettering-sign-at-melaka-seremban-senawang"
          },
          {
            name: "OFFICE 3D LED BACK-LIT STAINLESS STEEL LOGO SIGN MAKER AT MALAYSIA _ KUALA LUMPUR (KL)",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL SILVER BOX UP/OFFICE 3D LED BACK-LIT STAINLESS STEEL LOGO SIGN MAKER AT MALAYSIA _ KUALA LUMPUR (KL).webp"),
            id: "office-3d-led-back-lit-stainless-steel-logo-sign-maker-at-malaysia-kuala-lumpur-kl"
          },
          {
            name: "OUTDOOR STAINLESS STEEL SILVER MIRROR BOX UP SIGNAGE AT AMPANG _ KOTA DAMANSARA _ SUNWAY DAMANSARA_ IJOK",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL SILVER BOX UP/OUTDOOR STAINLESS STEEL SILVER MIRROR BOX UP SIGNAGE AT AMPANG _ KOTA DAMANSARA _ SUNWAY DAMANSARA_ IJOK.webp"),
            id: "outdoor-stainless-steel-silver-mirror-box-up-signage-at-ampang-kota-damansara-sunway-damansara-ijok"
          },
          {
            name: "STAINLESS STEEL SILVER MIRROR BOX UP LETTERING LANDMARK SIGN SUPPLY AT SELANGOR _ KUALA LUMPUR (KL) _ BANGI",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL SILVER BOX UP/STAINLESS STEEL SILVER MIRROR BOX UP LETTERING LANDMARK SIGN SUPPLY AT SELANGOR _ KUALA LUMPUR (KL) _ BANGI.webp"),
            id: "stainless-steel-silver-mirror-box-up-lettering-landmark-sign-supply-at-selangor-kuala-lumpur-kl-bangi"
          },
          {
            name: "STAINLESS STEEL SILVER MIRROR BOX UP SIGNAGE VENDOR AT KAJANG _ ALAM IMPIAN _ PUNCAK ALAM _ SETIA ALAM",
            image: require("../assets/products/3D Stainless Steel Box Up Lettering/STAINLESS STEEL SILVER BOX UP/STAINLESS STEEL SILVER MIRROR BOX UP SIGNAGE VENDOR AT KAJANG _ ALAM IMPIAN _ PUNCAK ALAM _ SETIA ALAM.webp"),
            id: "stainless-steel-silver-mirror-box-up-signage-vendor-at-kajang-alam-impian-puncak-alam-setia-alam"
          },
        ]
      },
    ],
  },
  {
    category: "Acrylic Signage",
    categoryImage: require("../assets/products/Acrylic Signage/3D LED ACRYLIC BOX UP LETTERING SIGN/Acrylic Box Up Lettering.webp"),
    id: "acrylic-signage",
    description: ``,
    subcategories: [
      {
        category: "ACRYLIC POSTER SIGN",
        categoryImage: require("../assets/products/Acrylic Signage/ACRYLIC POSTER SIGN/ACRYLIC POSTER FRAME SIGN MAKER AT IJOK _ KEPONG _ TAMAN KIP _ SUNGAI BESI _ SUNGAI CHUA.webp"),
        id: "acrylic-poster-sign",
        description: ``,
        products: [
          {
            name: "ACRYLIC POSTER FRAME SIGN MAKER AT IJOK _ KEPONG _ TAMAN KIP _ SUNGAI BESI _ SUNGAI CHUA",
            image: require("../assets/products/Acrylic Signage/ACRYLIC POSTER SIGN/ACRYLIC POSTER FRAME SIGN MAKER AT IJOK _ KEPONG _ TAMAN KIP _ SUNGAI BESI _ SUNGAI CHUA.webp"),
            id: "acrylic-poster-frame-sign-maker-at-ijok-kepong-taman-kip-sungai-besi-sungai-chua"
          },
          {
            name: "ACRYLIC SIGNAGE MAKER AT KLANG _ KL _ PJ _ MALAYSIA",
            image: require("../assets/products/Acrylic Signage/ACRYLIC POSTER SIGN/ACRYLIC SIGNAGE MAKER AT KLANG _ KL _ PJ _ MALAYSIA.webp"),
            id: "acrylic-signage-maker-at-klang-kl-pj-malaysia"
          },
          {
            name: "ARCYLIC POSTER FRAME SIGN SUPPLIER AT MALAYSIA _ KUALA LUMPUR (KL)",
            image: require("../assets/products/Acrylic Signage/ACRYLIC POSTER SIGN/ARCYLIC POSTER FRAME SIGN SUPPLIER AT MALAYSIA _ KUALA LUMPUR (KL).webp"),
            id: "arcylic-poster-frame-sign-supplier-at-malaysia-kuala-lumpur-kl"
          },
          {
            name: "ARCYLIC POSTER FRAME SIGN SUPPLIER AT SEREMBAN _ GOMBAK _ SEPUTEH _ AMPANG",
            image: require("../assets/products/Acrylic Signage/ACRYLIC POSTER SIGN/ARCYLIC POSTER FRAME SIGN SUPPLIER AT SEREMBAN _ GOMBAK _ SEPUTEH _ AMPANG.webp"),
            id: "arcylic-poster-frame-sign-supplier-at-seremban-gombak-seputeh-ampang"
          },
          {
            name: "Acrylic Frame Wall Decor AT SHAH ALAM _ BATU TIGA _ SUNGAI BULOH _ SUNWAY DAMANSARA _ KOTA DAMANSARA",
            image: require("../assets/products/Acrylic Signage/ACRYLIC POSTER SIGN/Acrylic Frame Wall Decor AT SHAH ALAM _ BATU TIGA _ SUNGAI BULOH _ SUNWAY DAMANSARA _ KOTA DAMANSARA.webp"),
            id: "acrylic-frame-wall-decor-at-shah-alam-batu-tiga-sungai-buloh-sunway-damansara-kota-damansara"
          },
          {
            name: "Acrylic wall decor _ wall instruction board",
            image: require("../assets/products/Acrylic Signage/ACRYLIC POSTER SIGN/Acrylic wall decor _ wall instruction board.webp"),
            id: "acrylic-wall-decor-wall-instruction-board"
          },
          {
            name: "BUSINESS ACRYLIC POSTER WALL DECOR SIGN SPECIALIST AT NILAI _ KUANTAN _ RAWANG _ KUALA SELANGOR _ SUNGAI BESAR",
            image: require("../assets/products/Acrylic Signage/ACRYLIC POSTER SIGN/BUSINESS ACRYLIC POSTER WALL DECOR SIGN SPECIALIST AT NILAI _ KUANTAN _ RAWANG _ KUALA SELANGOR _ SUNGAI BESAR.webp"),
            id: "business-acrylic-poster-wall-decor-sign-specialist-at-nilai-kuantan-rawang-kuala-selangor-sungai-besar"
          },
          {
            name: "OFFICE ACRYLIC POSTER FRAME AT PUCHONG, TAMAN OUG, CHERAS",
            image: require("../assets/products/Acrylic Signage/ACRYLIC POSTER SIGN/OFFICE ACRYLIC POSTER FRAME AT PUCHONG, TAMAN OUG, CHERAS.webp"),
            id: "office-acrylic-poster-frame-at-puchong-taman-oug-cheras"
          },
          {
            name: "OFFICE ACRYLIC POSTER FRAME SIGN SUPPLY AT SENAWANG _ PORT DICKSON _ NILAI _ SERDANG",
            image: require("../assets/products/Acrylic Signage/ACRYLIC POSTER SIGN/OFFICE ACRYLIC POSTER FRAME SIGN SUPPLY AT SENAWANG _ PORT DICKSON _ NILAI _ SERDANG.webp"),
            id: "office-acrylic-poster-frame-sign-supply-at-senawang-port-dickson-nilai-serdang"
          },
          {
            name: "Wall Signage Printing",
            image: require("../assets/products/Acrylic Signage/ACRYLIC POSTER SIGN/Wall Signage Printing.webp"),
            id: "wall-signage-printing"
          },
          {
            name: "中国外运门口亚克力招牌",
            image: require("../assets/products/Acrylic Signage/ACRYLIC POSTER SIGN/中国外运门口亚克力招牌.webp"),
            id: ""
          },
        ]
      },
      {
        category: "3D LED ACRYLIC BOX UP LETTERING SIGN",
        categoryImage: require("../assets/products/Acrylic Signage/3D LED ACRYLIC BOX UP LETTERING SIGN/Acrylic Box Up Lettering.webp"),
        id: "3d-led-acrylic-box-up-lettering-sign",
        description: ``,
        products: [
          {
            name: "Acrylic Box Up Lettering",
            image: require("../assets/products/Acrylic Signage/3D LED ACRYLIC BOX UP LETTERING SIGN/Acrylic Box Up Lettering.webp"),
            id: "acrylic-box-up-lettering"
          },
          {
            name: "Acrylic Signage",
            image: require("../assets/products/Acrylic Signage/3D LED ACRYLIC BOX UP LETTERING SIGN/Acrylic Signage.webp"),
            id: "acrylic-signage"
          },
          {
            name: "INDOOR 3D LED ACRYLIC BOX SIGNAGE MANUFACTURER AT BANDAR ENSTEK _ LABU _ ROMPIN _ BANDAR SERI JEMPOL _ LINGGI",
            image: require("../assets/products/Acrylic Signage/3D LED ACRYLIC BOX UP LETTERING SIGN/INDOOR 3D LED ACRYLIC BOX SIGNAGE MANUFACTURER AT BANDAR ENSTEK _ LABU _ ROMPIN _ BANDAR SERI JEMPOL _ LINGGI.webp"),
            id: "indoor-3d-led-acrylic-box-signage-manufacturer-at-bandar-enstek-labu-rompin-bandar-seri-jempol-linggi"
          },
          {
            name: "INDOOR 3D LED ACRYLIC BOX UP LETTERING SIGN INSTALLATION SERVICE AT KEPONG _ KUALA LUMPUR (KL) _ IJOK _ BANTING _ BESTARI JAYA",
            image: require("../assets/products/Acrylic Signage/3D LED ACRYLIC BOX UP LETTERING SIGN/INDOOR 3D LED ACRYLIC BOX UP LETTERING SIGN INSTALLATION SERVICE AT KEPONG _ KUALA LUMPUR (KL) _ IJOK _ BANTING _ BESTARI JAYA.webp"),
            id: "indoor-3d-led-acrylic-box-up-lettering-sign-installation-service-at-kepong-kuala-lumpur-kl-ijok-banting-bestari-jaya"
          },
          {
            name: "OFFICE 3D LED ACRYLIC WALL DECOR SIGNBOARD SUPPLY AT NILAI _ NILAI 2 _ SIMPANG DURIAN _ GEMENCHEH _ PORT DICKSON",
            image: require("../assets/products/Acrylic Signage/3D LED ACRYLIC BOX UP LETTERING SIGN/OFFICE 3D LED ACRYLIC WALL DECOR SIGNBOARD SUPPLY AT NILAI _ NILAI 2 _ SIMPANG DURIAN _ GEMENCHEH _ PORT DICKSON.webp"),
            id: "office-3d-led-acrylic-wall-decor-signboard-supply-at-nilai-nilai-2-simpang-durian-gemencheh-port-dickson"
          },
          {
            name: "_Beyond Water World_ Acrylic Box Up Lettering",
            image: require("../assets/products/Acrylic Signage/3D LED ACRYLIC BOX UP LETTERING SIGN/_Beyond Water World_ Acrylic Box Up Lettering.webp"),
            id: "beyond-water-world-acrylic-box-up-lettering"
          },
          {
            name: "_Sincero_ Acrylic Box Up Lettering",
            image: require("../assets/products/Acrylic Signage/3D LED ACRYLIC BOX UP LETTERING SIGN/_Sincero_ Acrylic Box Up Lettering.webp"),
            id: "sincero-acrylic-box-up-lettering"
          },
        ]
      },
      {
        category: "LASER CUT ACRYLIC LETTERING",
        categoryImage: require("../assets/products/Acrylic Signage/LASER CUT ACRYLIC LETTERING/ACRYLIC LASER CUT LETTERING WALL DECOR SIGNAGE MAKER AT JINJANG _ KEPONG _ AMPANG _ NILAI _ GOMBAK.webp"),
        id: "laser-cut-acrylic-lettering",
        description: ``,
        products: [
          {
            name: "ACRYLIC LASER CUT LETTERING WALL DECOR SIGNAGE MAKER AT JINJANG _ KEPONG _ AMPANG _ NILAI _ GOMBAK",
            image: require("../assets/products/Acrylic Signage/LASER CUT ACRYLIC LETTERING/ACRYLIC LASER CUT LETTERING WALL DECOR SIGNAGE MAKER AT JINJANG _ KEPONG _ AMPANG _ NILAI _ GOMBAK.webp"),
            id: "acrylic-laser-cut-lettering-wall-decor-signage-maker-at-jinjang-kepong-ampang-nilai-gombak"
          },
          {
            name: "Acrylic Box Up Lettering",
            image: require("../assets/products/Acrylic Signage/LASER CUT ACRYLIC LETTERING/Acrylic Box Up Lettering.webp"),
            id: "acrylic-box-up-lettering"
          },
          {
            name: "CUSTOM ACRYLIC LETTERING LASER CUT SERVICE AT SELANGOR _ SEREMBAN _ MELAKA _ MALAYSIA",
            image: require("../assets/products/Acrylic Signage/LASER CUT ACRYLIC LETTERING/CUSTOM ACRYLIC LETTERING LASER CUT SERVICE AT SELANGOR _ SEREMBAN _ MELAKA _ MALAYSIA.webp"),
            id: "custom-acrylic-lettering-laser-cut-service-at-selangor-seremban-melaka-malaysia"
          },
          {
            name: "LASER CUT ACRYLIC LETTERING SIGNAGE SUPPLY AT ROMPIN _ LUKUT _ SEREMBAN _ BATU KIKIR _ MANTIN _ SI RUSA",
            image: require("../assets/products/Acrylic Signage/LASER CUT ACRYLIC LETTERING/LASER CUT ACRYLIC LETTERING SIGNAGE SUPPLY AT ROMPIN _ LUKUT _ SEREMBAN _ BATU KIKIR _ MANTIN _ SI RUSA.webp"),
            id: "laser-cut-acrylic-lettering-signage-supply-at-rompin-lukut-seremban-batu-kikir-mantin-si-rusa"
          },
          {
            name: "LASER CUT ARCYLIC HOTEL ROOM LETTERING SIGN INSTALLED AT KUALA LUMPUR (KL) _ BUKIT BINTANG _ AMPANG _ CHERAS",
            image: require("../assets/products/Acrylic Signage/LASER CUT ACRYLIC LETTERING/LASER CUT ARCYLIC HOTEL ROOM LETTERING SIGN INSTALLED AT KUALA LUMPUR (KL) _ BUKIT BINTANG _ AMPANG _ CHERAS.webp"),
            id: "laser-cut-arcylic-hotel-room-lettering-sign-installed-at-kuala-lumpur-kl-bukit-bintang-ampang-cheras"
          },
        ]
      },
      {
        category: "OFFICE ACRYLIC SIGNAGE",
        categoryImage: require("../assets/products/Acrylic Signage/OFFICE ACRYLIC SIGNAGE/3D Letter Wall Decor _ Quote on Wall _ Wall Quote.webp"),
        id: "office-acrylic-signage",
        description: ``,
        products: [
          {
            name: "3D Letter Wall Decor _ Quote on Wall _ Wall Quote",
            image: require("../assets/products/Acrylic Signage/OFFICE ACRYLIC SIGNAGE/3D Letter Wall Decor _ Quote on Wall _ Wall Quote.webp"),
            id: "3d-letter-wall-decor-quote-on-wall-wall-quote"
          },
          {
            name: "ACRYLIC SIGNAGE INSTALLED AT KUALA LUMPUR (KL) _ SELAYANG _ SETAPAK",
            image: require("../assets/products/Acrylic Signage/OFFICE ACRYLIC SIGNAGE/ACRYLIC SIGNAGE INSTALLED AT KUALA LUMPUR (KL) _ SELAYANG _ SETAPAK.webp"),
            id: "acrylic-signage-installed-at-kuala-lumpur-kl-selayang-setapak"
          },
          {
            name: "Acrylic 3D Signage for Indoor Office AT SEGAMBUT _ MONT KIARA _ DAMANSARA HEIGHTS _ SENTUL _ TITIWANGSA",
            image: require("../assets/products/Acrylic Signage/OFFICE ACRYLIC SIGNAGE/Acrylic 3D Signage for Indoor Office AT SEGAMBUT _ MONT KIARA _ DAMANSARA HEIGHTS _ SENTUL _ TITIWANGSA.webp"),
            id: "acrylic-3d-signage-for-indoor-office-at-segambut-mont-kiara-damansara-heights-sentul-titiwangsa"
          },
          {
            name: "Acrylic Box Up Lettering",
            image: require("../assets/products/Acrylic Signage/OFFICE ACRYLIC SIGNAGE/Acrylic Box Up Lettering.webp"),
            id: "acrylic-box-up-lettering"
          },
          {
            name: "Acrylic Signage",
            image: require("../assets/products/Acrylic Signage/OFFICE ACRYLIC SIGNAGE/Acrylic Signage.webp"),
            id: "acrylic-signage"
          },
          {
            name: "KILANG BAHAN BINAAN 3D Letter Signage",
            image: require("../assets/products/Acrylic Signage/OFFICE ACRYLIC SIGNAGE/KILANG BAHAN BINAAN 3D Letter Signage.webp"),
            id: "kilang-bahan-binaan-3d-letter-signage"
          },
          {
            name: "OFFICE 3D STAINLESS STEEL GOLD PLATE LETTER ACRYLIC SIGNAGE MAKER AT MALAYSIA _ SELANGOR _ PANDAMARAN JAYA _ PORT KLANG _ USJ",
            image: require("../assets/products/Acrylic Signage/OFFICE ACRYLIC SIGNAGE/OFFICE 3D STAINLESS STEEL GOLD PLATE LETTER ACRYLIC SIGNAGE MAKER AT MALAYSIA _ SELANGOR _ PANDAMARAN JAYA _ PORT KLANG _ USJ.webp"),
            id: "office-3d-stainless-steel-gold-plate-letter-acrylic-signage-maker-at-malaysia-selangor-pandamaran-jaya-port-klang-usj"
          },
          {
            name: "OFFICE ACRYLIC SIGNAGE INSTALLED AT KLANG _ CHERAS _ NILAI",
            image: require("../assets/products/Acrylic Signage/OFFICE ACRYLIC SIGNAGE/OFFICE ACRYLIC SIGNAGE INSTALLED AT KLANG _ CHERAS _ NILAI.webp"),
            id: "office-acrylic-signage-installed-at-klang-cheras-nilai"
          },
          {
            name: "OFFICE ACRYLIC SIGNAGE WITH LED BACKLIT AT SELANGOR _ MALAYSIA _ SEREMBAN",
            image: require("../assets/products/Acrylic Signage/OFFICE ACRYLIC SIGNAGE/OFFICE ACRYLIC SIGNAGE WITH LED BACKLIT AT SELANGOR _ MALAYSIA _ SEREMBAN.webp"),
            id: "office-acrylic-signage-with-led-backlit-at-selangor-malaysia-seremban"
          },
          {
            name: "OFFICE ACRYLIC SIGNBOARD SUPPLY AT RAWANG _ SUNGAI BULOH _ KEPONG _ KAYU ARA",
            image: require("../assets/products/Acrylic Signage/OFFICE ACRYLIC SIGNAGE/OFFICE ACRYLIC SIGNBOARD SUPPLY AT RAWANG _ SUNGAI BULOH _ KEPONG _ KAYU ARA.webp"),
            id: "office-acrylic-signboard-supply-at-rawang-sungai-buloh-kepong-kayu-ara"
          },
          {
            name: "Papan Tanda Akrilik Klinik",
            image: require("../assets/products/Acrylic Signage/OFFICE ACRYLIC SIGNAGE/Papan Tanda Akrilik Klinik.webp"),
            id: "papan-tanda-akrilik-klinik"
          },
          {
            name: "_Gravit Klang By Mitraland_ Acrylic Signage",
            image: require("../assets/products/Acrylic Signage/OFFICE ACRYLIC SIGNAGE/_Gravit Klang By Mitraland_ Acrylic Signage.webp"),
            id: "gravit-klang-by-mitraland-acrylic-signage"
          },
        ]
      },
    ],
  },
  {
    category: "BILLBOARD",
    categoryImage: require("../assets/products/BILLBOARD/BILLBOARD PRINTING SPECIALIST AT MALAYSIA _ PAKAR PERCETAKAN PAPAN BILLBOARD DI MALAYSIA.webp"),
    id: "billboard",
    description: ``,
    products: [
      {
        name: "BILLBOARD PRINTING SPECIALIST AT MALAYSIA _ PAKAR PERCETAKAN PAPAN BILLBOARD DI MALAYSIA",
        image: require("../assets/products/BILLBOARD/BILLBOARD PRINTING SPECIALIST AT MALAYSIA _ PAKAR PERCETAKAN PAPAN BILLBOARD DI MALAYSIA.webp"),
        id: "billboard-printing-specialist-at-malaysia-pakar-percetakan-papan-billboard-di-malaysia"
      },
      {
        name: "BUILDING BILLBOARD PRINTING SPECIALIST AT GOMBAK _ BATU CAVES _ SETIA ALAM _ SHAH ALAM",
        image: require("../assets/products/BILLBOARD/BUILDING BILLBOARD PRINTING SPECIALIST AT GOMBAK _ BATU CAVES _ SETIA ALAM _ SHAH ALAM.webp"),
        id: "building-billboard-printing-specialist-at-gombak-batu-caves-setia-alam-shah-alam"
      },
      {
        name: "BUILDING BILLBOARD PRINTING SPECIALIST AT PUTRJAYA _ CYBERJAYA _ SAUJANA PUTRA _ KUALA SELANGOR",
        image: require("../assets/products/BILLBOARD/BUILDING BILLBOARD PRINTING SPECIALIST AT PUTRJAYA _ CYBERJAYA _ SAUJANA PUTRA _ KUALA SELANGOR.webp"),
        id: "building-billboard-printing-specialist-at-putrjaya-cyberjaya-saujana-putra-kuala-selangor"
      },
      {
        name: "BUILDING BILLBOARD PRINTING SPECIALIST AT SELANGOR, RAWANG, BATU CAVE, GOMBAK",
        image: require("../assets/products/BILLBOARD/BUILDING BILLBOARD PRINTING SPECIALIST AT SELANGOR, RAWANG, BATU CAVE, GOMBAK.webp"),
        id: "building-billboard-printing-specialist-at-selangor-rawang-batu-cave-gombak"
      },
      {
        name: "BUILDING BILLBOARD SUPPLY AT SEPANG _ SALAK SOUTH _ SALAT SELATAN",
        image: require("../assets/products/BILLBOARD/BUILDING BILLBOARD SUPPLY AT SEPANG _ SALAK SOUTH _ SALAT SELATAN.webp"),
        id: "building-billboard-supply-at-sepang-salak-south-salat-selatan"
      },
      {
        name: "GENERALI Pusat Insurance Signboard Billboard 1",
        image: require("../assets/products/BILLBOARD/GENERALI Pusat Insurance Signboard Billboard 1.webp"),
        id: "generali-pusat-insurance-signboard-billboard-1"
      },
      {
        name: "INDOOR WALL DECOR BILLBOARD PRINTING SUPPLIER AT KUALA LUMPUR (KL) _ SELANGOR _ BANTING _ SEPANG",
        image: require("../assets/products/BILLBOARD/INDOOR WALL DECOR BILLBOARD PRINTING SUPPLIER AT KUALA LUMPUR (KL) _ SELANGOR _ BANTING _ SEPANG.webp"),
        id: "indoor-wall-decor-billboard-printing-supplier-at-kuala-lumpur-kl-selangor-banting-sepang"
      },
      {
        name: "OUTDOOR BILLBOARD PRINTING AT BESTARI JAYA _ DAMANSARA _ KEPONG _ SUNGAI BULOH",
        image: require("../assets/products/BILLBOARD/OUTDOOR BILLBOARD PRINTING AT BESTARI JAYA _ DAMANSARA _ KEPONG _ SUNGAI BULOH.webp"),
        id: "outdoor-billboard-printing-at-bestari-jaya-damansara-kepong-sungai-buloh"
      },
      {
        name: "OUTDOOR BILLBOARD PRINTING AT BESTARI JAYA _ DAMANSARA _ KEPONG",
        image: require("../assets/products/BILLBOARD/OUTDOOR BILLBOARD PRINTING AT BESTARI JAYA _ DAMANSARA _ KEPONG.webp"),
        id: "outdoor-billboard-printing-at-bestari-jaya-damansara-kepong"
      },
      {
        name: "PROJECT BILLBOARD INSTALLED AT ELMINA _ KAJANG _ SETAPAK _ SEGAMBUT",
        image: require("../assets/products/BILLBOARD/PROJECT BILLBOARD INSTALLED AT ELMINA _ KAJANG _ SETAPAK _ SEGAMBUT.webp"),
        id: "project-billboard-installed-at-elmina-kajang-setapak-segambut"
      },
      {
        name: "QUALITY BILLBOARD PRINTING SERVICE AT KAJANG _ KEPONG _ SHAH ALAM _ BUKIT KEMUNING",
        image: require("../assets/products/BILLBOARD/QUALITY BILLBOARD PRINTING SERVICE AT KAJANG _ KEPONG _ SHAH ALAM _ BUKIT KEMUNING.webp"),
        id: "quality-billboard-printing-service-at-kajang-kepong-shah-alam-bukit-kemuning"
      },
      {
        name: "RETAIL BILLBOARD PRINTING SPECIALIST AT SELANGOR _ NILAI _ CHERAS _ SERDANG _ SERI KEMBANGAN",
        image: require("../assets/products/BILLBOARD/RETAIL BILLBOARD PRINTING SPECIALIST AT SELANGOR _ NILAI _ CHERAS _ SERDANG _ SERI KEMBANGAN.webp"),
        id: "retail-billboard-printing-specialist-at-selangor-nilai-cheras-serdang-seri-kembangan"
      },
      {
        name: "SHOPLOT BILLBOARD INSTALLED AT KAJANG _ SEREMBAN _ NEGERI SEMBILAN _ BANTING",
        image: require("../assets/products/BILLBOARD/SHOPLOT BILLBOARD INSTALLED AT KAJANG _ SEREMBAN _ NEGERI SEMBILAN _ BANTING.webp"),
        id: "shoplot-billboard-installed-at-kajang-seremban-negeri-sembilan-banting"
      },
      {
        name: "_Bottle World_ Billboard",
        image: require("../assets/products/BILLBOARD/_Bottle World_ Billboard.webp"),
        id: "bottle-world-billboard"
      },
      {
        name: "_KSL Maple Show Room_ Billboard",
        image: require("../assets/products/BILLBOARD/_KSL Maple Show Room_ Billboard.webp"),
        id: "ksl-maple-show-room-billboard"
      },
      {
        name: "_Kansai Paint_ Billboard",
        image: require("../assets/products/BILLBOARD/_Kansai Paint_ Billboard.webp"),
        id: "kansai-paint-billboard"
      },
      {
        name: "_Legend Height By JL 99 Group_ Billboard",
        image: require("../assets/products/BILLBOARD/_Legend Height By JL 99 Group_ Billboard.webp"),
        id: "legend-height-by-jl-99-group-billboard"
      },
      {
        name: "_Puchong_ Zig Zag Banner",
        image: require("../assets/products/BILLBOARD/_Puchong_ Zig Zag Banner.webp"),
        id: "puchong-zig-zag-banner"
      },
      {
        name: "_Pusat Tuisyen Credas Akademik_ Billboard",
        image: require("../assets/products/BILLBOARD/_Pusat Tuisyen Credas Akademik_ Billboard.webp"),
        id: "pusat-tuisyen-credas-akademik-billboard"
      },
    ],
  },
  {
    category: "BUILDING SIGNBOARD",
    categoryImage: require("../assets/products/BUILDING SIGNBOARD/3D BOX UP BUILDING SIGNAGE MAKER AT KUALA LUMPUR (KL) _ SEPANG _ CYBERJAYA _ AMPANG.webp"),
    id: "building-signboard",
    description: ``,
    products: [
      {
        name: "3D BOX UP BUILDING SIGNAGE MAKER AT KUALA LUMPUR (KL) _ SEPANG _ CYBERJAYA _ AMPANG",
        image: require("../assets/products/BUILDING SIGNBOARD/3D BOX UP BUILDING SIGNAGE MAKER AT KUALA LUMPUR (KL) _ SEPANG _ CYBERJAYA _ AMPANG.webp"),
        id: "3d-box-up-building-signage-maker-at-kuala-lumpur-kl-sepang-cyberjaya-ampang"
      },
      {
        name: "3D BUILDING SIGNAGE INSTALLED AT SEREMBAN _ NILAI _ KAJANG _ SERDANG _ KEPONG",
        image: require("../assets/products/BUILDING SIGNBOARD/3D BUILDING SIGNAGE INSTALLED AT SEREMBAN _ NILAI _ KAJANG _ SERDANG _ KEPONG.webp"),
        id: "3d-building-signage-installed-at-seremban-nilai-kajang-serdang-kepong"
      },
      {
        name: "ACADEMY 3D BUILDING SIGNAGE SUPPLY AT SELANGOR _ NILAI _ ROMPIN _ MELAKA",
        image: require("../assets/products/BUILDING SIGNBOARD/ACADEMY 3D BUILDING SIGNAGE SUPPLY AT SELANGOR _ NILAI _ ROMPIN _ MELAKA.webp"),
        id: "academy-3d-building-signage-supply-at-selangor-nilai-rompin-melaka"
      },
      {
        name: "BUILDING 3D BOX UP SIGNBOARD AT SELANGOR _ PUDU _ BUKIT JALIL",
        image: require("../assets/products/BUILDING SIGNBOARD/BUILDING 3D BOX UP SIGNBOARD AT SELANGOR _ PUDU _ BUKIT JALIL.webp"),
        id: "building-3d-box-up-signboard-at-selangor-pudu-bukit-jalil"
      },
      {
        name: "BUILDING 3D BOX UP SIGNBOARD INSTALLED AT SRI PETALING _ PETALING JAYA (PJ) _ KOTA DAMANSARA _ SUNGAI BULOH",
        image: require("../assets/products/BUILDING SIGNBOARD/BUILDING 3D BOX UP SIGNBOARD INSTALLED AT SRI PETALING _ PETALING JAYA (PJ) _ KOTA DAMANSARA _ SUNGAI BULOH.webp"),
        id: "building-3d-box-up-signboard-installed-at-sri-petaling-petaling-jaya-pj-kota-damansara-sungai-buloh"
      },
      {
        name: "BUILDING FACADE SIGNBOARD INSTALLED AT PUDU _ TTDI _ SUNGAI BULOH _ GOMBAK",
        image: require("../assets/products/BUILDING SIGNBOARD/BUILDING FACADE SIGNBOARD INSTALLED AT PUDU _ TTDI _ SUNGAI BULOH _ GOMBAK.webp"),
        id: "building-facade-signboard-installed-at-pudu-ttdi-sungai-buloh-gombak"
      },
      {
        name: "BUILDING SIGNAGE PROVIDER AT SELANGOR _ KUALA SELANGOR _ TANJUNG KARANG _ SIJANGKANG",
        image: require("../assets/products/BUILDING SIGNBOARD/BUILDING SIGNAGE PROVIDER AT SELANGOR _ KUALA SELANGOR _ TANJUNG KARANG _ SIJANGKANG.webp"),
        id: "building-signage-provider-at-selangor-kuala-selangor-tanjung-karang-sijangkang"
      },
      {
        name: "BUILDING SIGNAGE SPECIALIST AT KUALA LUMPUR (KL) _ MALAYSIA",
        image: require("../assets/products/BUILDING SIGNBOARD/BUILDING SIGNAGE SPECIALIST AT KUALA LUMPUR (KL) _ MALAYSIA.webp"),
        id: "building-signage-specialist-at-kuala-lumpur-kl-malaysia"
      },
      {
        name: "BUSINESS BUILDING SIGNAGE INSTALLATION SERVICE AT KUALA LUMPUR (KL) _ SEREMBAN _ MELAKA",
        image: require("../assets/products/BUILDING SIGNBOARD/BUSINESS BUILDING SIGNAGE INSTALLATION SERVICE AT KUALA LUMPUR (KL) _ SEREMBAN _ MELAKA.webp"),
        id: "business-building-signage-installation-service-at-kuala-lumpur-kl-seremban-melaka"
      },
      {
        name: "CORPORATE BUILDING SIGNBOARD SUPPLY AT MALAYSIA _ KEPONG _ JINJANG _ BATU CAVES",
        image: require("../assets/products/BUILDING SIGNBOARD/CORPORATE BUILDING SIGNBOARD SUPPLY AT MALAYSIA _ KEPONG _ JINJANG _ BATU CAVES.webp"),
        id: "corporate-building-signboard-supply-at-malaysia-kepong-jinjang-batu-caves"
      },
      {
        name: "CUSTOM BUILDING SIGNAGE SERVICE AT KEPONG _ SEGAMBUT _ IJOK _ BESTARI JAYA",
        image: require("../assets/products/BUILDING SIGNBOARD/CUSTOM BUILDING SIGNAGE SERVICE AT KEPONG _ SEGAMBUT _ IJOK _ BESTARI JAYA.webp"),
        id: "custom-building-signage-service-at-kepong-segambut-ijok-bestari-jaya"
      },
      {
        name: "FACTORY BUILDING 3D SIGNAGE MAKER AT KAPAR _ KLANG _ SELANGOR",
        image: require("../assets/products/BUILDING SIGNBOARD/FACTORY BUILDING 3D SIGNAGE MAKER AT KAPAR _ KLANG _ SELANGOR.webp"),
        id: "factory-building-3d-signage-maker-at-kapar-klang-selangor"
      },
      {
        name: "INDUSTRIAL BUILDING SIGNAGE INSTALLED AT KAPAR _ MERU _ PUNCAK ALAM _ SETIA IMPIAN",
        image: require("../assets/products/BUILDING SIGNBOARD/INDUSTRIAL BUILDING SIGNAGE INSTALLED AT KAPAR _ MERU _ PUNCAK ALAM _ SETIA IMPIAN.webp"),
        id: "industrial-building-signage-installed-at-kapar-meru-puncak-alam-setia-impian"
      },
      {
        name: "LOTUS_S BUILDING SIGN MAKER AT KLANG _ SELANGOR _ BANTING",
        image: require("../assets/products/BUILDING SIGNBOARD/LOTUS_S BUILDING SIGN MAKER AT KLANG _ SELANGOR _ BANTING.webp"),
        id: "lotus-s-building-sign-maker-at-klang-selangor-banting"
      },
      {
        name: "PEMBUAT PAPAN TANDA KOTAK 3D AT KAPAR _ MERU _ PUNCAK ALAM _ SETIA ALAM _ SHAH ALAM",
        image: require("../assets/products/BUILDING SIGNBOARD/PEMBUAT PAPAN TANDA KOTAK 3D AT KAPAR _ MERU _ PUNCAK ALAM _ SETIA ALAM _ SHAH ALAM.webp"),
        id: "pembuat-papan-tanda-kotak-3d-at-kapar-meru-puncak-alam-setia-alam-shah-alam"
      },
      {
        name: "ROOF BUILDING SIGNAGE SUPPLY AT BESTARI JAYA _ KEPONG _ AMPANG",
        image: require("../assets/products/BUILDING SIGNBOARD/ROOF BUILDING SIGNAGE SUPPLY AT BESTARI JAYA _ KEPONG _ AMPANG.webp"),
        id: "roof-building-signage-supply-at-bestari-jaya-kepong-ampang"
      },
      {
        name: "ROOF BUILDING SIGNBOARD MAKER AT SHAH ALAM _ BUKIT KEMUNING _ SRI MUDA",
        image: require("../assets/products/BUILDING SIGNBOARD/ROOF BUILDING SIGNBOARD MAKER AT SHAH ALAM _ BUKIT KEMUNING _ SRI MUDA.webp"),
        id: "roof-building-signboard-maker-at-shah-alam-bukit-kemuning-sri-muda"
      },
      {
        name: "ROOF BUILDING SIGNBOARD SPECIALIST AT KLANG _ SELANGOR _ MALAYSIA _ BANTING",
        image: require("../assets/products/BUILDING SIGNBOARD/ROOF BUILDING SIGNBOARD SPECIALIST AT KLANG _ SELANGOR _ MALAYSIA _ BANTING.webp"),
        id: "roof-building-signboard-specialist-at-klang-selangor-malaysia-banting"
      },
      {
        name: "SHOPFRONT 3D BUILDING SIGNBOARD MAKER AT KLANG _ SUBANG JAYA _ USJ _ KELANA JAYA _ TAMAN KINRARA",
        image: require("../assets/products/BUILDING SIGNBOARD/SHOPFRONT 3D BUILDING SIGNBOARD MAKER AT KLANG _ SUBANG JAYA _ USJ _ KELANA JAYA _ TAMAN KINRARA.webp"),
        id: "shopfront-3d-building-signboard-maker-at-klang-subang-jaya-usj-kelana-jaya-taman-kinrara"
      },
      {
        name: "SHOPPING MALL BUILDING SIGN SUPPLIER AT GOMBAK _ CHERAS _ NILAI _ BANGI",
        image: require("../assets/products/BUILDING SIGNBOARD/SHOPPING MALL BUILDING SIGN SUPPLIER AT GOMBAK _ CHERAS _ NILAI _ BANGI.webp"),
        id: "shopping-mall-building-sign-supplier-at-gombak-cheras-nilai-bangi"
      },
      {
        name: "SHOWROOM BUILDIN SIGN PROVIDER AT MALAYSIA _ SELANGOR _ IJOK _ BESTARI JAYA _ KEPONG",
        image: require("../assets/products/BUILDING SIGNBOARD/SHOWROOM BUILDIN SIGN PROVIDER AT MALAYSIA _ SELANGOR _ IJOK _ BESTARI JAYA _ KEPONG.webp"),
        id: "showroom-buildin-sign-provider-at-malaysia-selangor-ijok-bestari-jaya-kepong"
      },
      {
        name: "SHOWROOM SIGNAGE SUPPLY AT RIMBAYU _ SAUJANA PUTRA _ ALAM IMPIAN _ BUKIT KEMUNING _ RAWANG",
        image: require("../assets/products/BUILDING SIGNBOARD/SHOWROOM SIGNAGE SUPPLY AT RIMBAYU _ SAUJANA PUTRA _ ALAM IMPIAN _ BUKIT KEMUNING _ RAWANG.webp"),
        id: "showroom-signage-supply-at-rimbayu-saujana-putra-alam-impian-bukit-kemuning-rawang"
      },
      {
        name: "户外招牌制造商 _ 商业招牌制造商 _ 立体字招牌制作 AT KUALA LUMPUR (KL) _ MALAYSIA _ SELANGOR",
        image: require("../assets/products/BUILDING SIGNBOARD/户外招牌制造商 _ 商业招牌制造商 _ 立体字招牌制作 AT KUALA LUMPUR (KL) _ MALAYSIA _ SELANGOR.webp"),
        id: "at-kuala-lumpur-kl-malaysia-selangor"
      },
    ],
  },
  {
    category: "CLC _ PVC BOARD LETTER SIGNAGE",
    categoryImage: require("../assets/products/CLC _ PVC BOARD LETTER SIGNAGE/CHROME CLC CUTTING FOAMBOARD WALL LETTERING SIGNAGE KUALA LUMPUR.webp"),
    id: "clc-pvc-board-letter-signage",
    description: ``,
    products: [
      {
        name: "CHROME CLC CUTTING FOAMBOARD WALL LETTERING SIGNAGE KUALA LUMPUR",
        image: require("../assets/products/CLC _ PVC BOARD LETTER SIGNAGE/CHROME CLC CUTTING FOAMBOARD WALL LETTERING SIGNAGE KUALA LUMPUR.webp"),
        id: "chrome-clc-cutting-foamboard-wall-lettering-signage-kuala-lumpur"
      },
      {
        name: "CLC LETTERING WALL DECOR",
        image: require("../assets/products/CLC _ PVC BOARD LETTER SIGNAGE/CLC LETTERING WALL DECOR.webp"),
        id: "clc-lettering-wall-decor"
      },
      {
        name: "OFFICE GOLD CLC FOAMBOARD LETTERING SIGNAGE",
        image: require("../assets/products/CLC _ PVC BOARD LETTER SIGNAGE/OFFICE GOLD CLC FOAMBOARD LETTERING SIGNAGE.webp"),
        id: "office-gold-clc-foamboard-lettering-signage"
      },
    ],
  },
  {
    category: "Directory Signage",
    categoryImage: require("../assets/products/Directory Signage/Pylon Directory Sign.jpg"),
    id: "directory-signage",
    description: `Ensure easy navigation with our stylish and functional directory signage. Perfect for offices, malls, and large buildings, our directory signs help visitors find their way effortlessly. We provide customizable designs to fit the aesthetic and functional requirements of your space. Let us create a directory signage solution that enhances your facility\'s accessibility and professionalism.`,
    products: [
      {
        name: "Pylon Directory Sign",
        image: require("../assets/products/Directory Signage/Pylon Directory Sign.jpg"),
        id: "pylon-directory-sign"
      },
      {
        name: "Swimming Pool Directory Signage Pylon",
        image: require("../assets/products/Directory Signage/Swimming Pool Directory Signage Pylon.jpg"),
        id: "swimming-pool-directory-signage-pylon"
      },
    ],
  },
  {
    category: "ENTRANCE STATEMENT SIGNBOARD",
    categoryImage: require("../assets/products/ENTRANCE STATEMENT SIGNBOARD/3D BOX UP ENTRANCE STATEMENT SIGNAGE SPECIALIST AT SELANGOR _ SEREMBAN _ MELAKA.webp"),
    id: "entrance-statement-signboard",
    description: ``,
    products: [
      {
        name: "3D BOX UP ENTRANCE STATEMENT SIGNAGE SPECIALIST AT SELANGOR _ SEREMBAN _ MELAKA",
        image: require("../assets/products/ENTRANCE STATEMENT SIGNBOARD/3D BOX UP ENTRANCE STATEMENT SIGNAGE SPECIALIST AT SELANGOR _ SEREMBAN _ MELAKA.webp"),
        id: "3d-box-up-entrance-statement-signage-specialist-at-selangor-seremban-melaka"
      },
      {
        name: "3D ENTRANCE STATEMENT LETTERING SIGNBOARD SUPPLY AT BANDAR BOTANIC _ BUKIT TINGGI KLANG _ SEGAMBUT",
        image: require("../assets/products/ENTRANCE STATEMENT SIGNBOARD/3D ENTRANCE STATEMENT LETTERING SIGNBOARD SUPPLY AT BANDAR BOTANIC _ BUKIT TINGGI KLANG _ SEGAMBUT.webp"),
        id: "3d-entrance-statement-lettering-signboard-supply-at-bandar-botanic-bukit-tinggi-klang-segambut"
      },
      {
        name: "ENTRANCE STATEMENT SIGNAGE SUPPLY AT KLANG _ SELAYANG _ GOMBAK _ BESTARI JAYA",
        image: require("../assets/products/ENTRANCE STATEMENT SIGNBOARD/ENTRANCE STATEMENT SIGNAGE SUPPLY AT KLANG _ SELAYANG _ GOMBAK _ BESTARI JAYA.webp"),
        id: "entrance-statement-signage-supply-at-klang-selayang-gombak-bestari-jaya"
      },
      {
        name: "ENTRANCE STATEMENT SIGNBOARD MAKER AT KUALA LUMPUR (KL) _ MALAYSIA",
        image: require("../assets/products/ENTRANCE STATEMENT SIGNBOARD/ENTRANCE STATEMENT SIGNBOARD MAKER AT KUALA LUMPUR (KL) _ MALAYSIA.webp"),
        id: "entrance-statement-signboard-maker-at-kuala-lumpur-kl-malaysia"
      },
      {
        name: "KSL 3D EG FRONTLIT ENTRANCE STATEMENT SIGNBOARD CONTRACTOR AT SIJANGKANG _ BESTARI JAYA _ BANTING _ JENJAROM",
        image: require("../assets/products/ENTRANCE STATEMENT SIGNBOARD/KSL 3D EG FRONTLIT ENTRANCE STATEMENT SIGNBOARD CONTRACTOR AT SIJANGKANG _ BESTARI JAYA _ BANTING _ JENJAROM.webp"),
        id: "ksl-3d-eg-frontlit-entrance-statement-signboard-contractor-at-sijangkang-bestari-jaya-banting-jenjarom"
      },
      {
        name: "TAMAN ENTRANCE STATEMENT SIGNAGE INSTALLED AT KLANG _ SELANGOR _ BANGSAR CITY _ SALAK SOUTH",
        image: require("../assets/products/ENTRANCE STATEMENT SIGNBOARD/TAMAN ENTRANCE STATEMENT SIGNAGE INSTALLED AT KLANG _ SELANGOR _ BANGSAR CITY _ SALAK SOUTH.webp"),
        id: "taman-entrance-statement-signage-installed-at-klang-selangor-bangsar-city-salak-south"
      },
    ],
  },
  {
    category: "FABRIC LIGHT BOX",
    categoryImage: require("../assets/products/FABRIC LIGHT BOX/FABRIC LIGHTBOX DISPLAY SIGN AT SHAH ALAM _ SLEANGOR _ PUDU _ BUKIT BINTANG.webp"),
    id: "fabric-light-box",
    description: ``,
    products: [
      {
        name: "FABRIC LIGHTBOX DISPLAY SIGN AT SHAH ALAM _ SLEANGOR _ PUDU _ BUKIT BINTANG",
        image: require("../assets/products/FABRIC LIGHT BOX/FABRIC LIGHTBOX DISPLAY SIGN AT SHAH ALAM _ SLEANGOR _ PUDU _ BUKIT BINTANG.webp"),
        id: "fabric-lightbox-display-sign-at-shah-alam-sleangor-pudu-bukit-bintang"
      },
      {
        name: "FABRIC LIGHTBOX SIGN INSTALLED AT TTDI _ KOTA DAMANSARA _ ARA DAMANSARA",
        image: require("../assets/products/FABRIC LIGHT BOX/FABRIC LIGHTBOX SIGN INSTALLED AT TTDI _ KOTA DAMANSARA _ ARA DAMANSARA.webp"),
        id: "fabric-lightbox-sign-installed-at-ttdi-kota-damansara-ara-damansara"
      },
    ],
  },
  {
    category: "Factory",
    categoryImage: require("../assets/products/Factory/Factory Front Gate Signboard.jpeg"),
    id: "factory",
    description: `Enhance your factory\'s visibility and safety with our robust factory signage. Designed to withstand harsh industrial environments, our signage solutions include everything from directional signs to safety warnings. Customized to fit your specific operational needs, our factory signs ensure clear communication and a safer workplace. Contact us to design factory signage that meets your exact requirements.`,
    products: [
      {
        name: "Factory Front Gate Signboard",
        image: require("../assets/products/Factory/Factory Front Gate Signboard.jpeg"),
        id: "factory-front-gate-signboard"
      },
    ],
  },
  {
    category: "GI METAL SIGNBOARD",
    categoryImage: require("../assets/products/GI METAL SIGNBOARD/3D BOX UP LETTERING GI METAL SIGNAGE SOLUTIONS AT KUALA LUMPUR (KL) _ SHAH ALAM.webp"),
    id: "gi-metal-signboard",
    description: ``,
    products: [
      {
        name: "3D BOX UP LETTERING GI METAL SIGNAGE SOLUTIONS AT KUALA LUMPUR (KL) _ SHAH ALAM",
        image: require("../assets/products/GI METAL SIGNBOARD/3D BOX UP LETTERING GI METAL SIGNAGE SOLUTIONS AT KUALA LUMPUR (KL) _ SHAH ALAM.webp"),
        id: "3d-box-up-lettering-gi-metal-signage-solutions-at-kuala-lumpur-kl-shah-alam"
      },
      {
        name: "3D广告牌制作 _ 3D BOX UP SIGN FACTORY AT PANDAN JAYA _ BRICKFILEDS _ USJ HEIGHTS _ KUCHAI LAMA _ OLD KLANG ROAD",
        image: require("../assets/products/GI METAL SIGNBOARD/3D广告牌制作 _ 3D BOX UP SIGN FACTORY AT PANDAN JAYA _ BRICKFILEDS _ USJ HEIGHTS _ KUCHAI LAMA _ OLD KLANG ROAD.webp"),
        id: "3d-3d-box-up-sign-factory-at-pandan-jaya-brickfileds-usj-heights-kuchai-lama-old-klang-road"
      },
      {
        name: "FACTORY GI METAL SIGNAGE MANUFACTURER AT BUKIT RAJA _ CHERAS _ KAPAR",
        image: require("../assets/products/GI METAL SIGNBOARD/FACTORY GI METAL SIGNAGE MANUFACTURER AT BUKIT RAJA _ CHERAS _ KAPAR.webp"),
        id: "factory-gi-metal-signage-manufacturer-at-bukit-raja-cheras-kapar"
      },
      {
        name: "GI METAL SHEET SIGNBOARD INSTALLED AT BANGSAR _ KEPONG _ JINJANG _ NILAI",
        image: require("../assets/products/GI METAL SIGNBOARD/GI METAL SHEET SIGNBOARD INSTALLED AT BANGSAR _ KEPONG _ JINJANG _ NILAI.webp"),
        id: "gi-metal-sheet-signboard-installed-at-bangsar-kepong-jinjang-nilai"
      },
      {
        name: "GI METAL SHEET SIGNBOARD MAKER AT SELANGOR _ KUALA LUMPUR (KL) _ NILAI _ IJOK",
        image: require("../assets/products/GI METAL SIGNBOARD/GI METAL SHEET SIGNBOARD MAKER AT SELANGOR _ KUALA LUMPUR (KL) _ NILAI _ IJOK.webp"),
        id: "gi-metal-sheet-signboard-maker-at-selangor-kuala-lumpur-kl-nilai-ijok"
      },
      {
        name: "GI METAL SIGNBOARD MAKER AT SEPANG _ SETAPAK _ SEGAMBUT _ BATU CAVES",
        image: require("../assets/products/GI METAL SIGNBOARD/GI METAL SIGNBOARD MAKER AT SEPANG _ SETAPAK _ SEGAMBUT _ BATU CAVES.webp"),
        id: "gi-metal-signboard-maker-at-sepang-setapak-segambut-batu-caves"
      },
      {
        name: "KEDAI 3D SIGNBOARD _ PEMBUAT PAPAN TANDAN 3D AT LUKUT _ LENGGENG _ LANGKAP _ LABU",
        image: require("../assets/products/GI METAL SIGNBOARD/KEDAI 3D SIGNBOARD _ PEMBUAT PAPAN TANDAN 3D AT LUKUT _ LENGGENG _ LANGKAP _ LABU.webp"),
        id: "kedai-3d-signboard-pembuat-papan-tandan-3d-at-lukut-lenggeng-langkap-labu"
      },
      {
        name: "KEDAI PAPAN TANDA _ KILANG PAPAN TANDA DI SEPUTEH _ PUDU _ PUNCAK JALIL _ PUNCAK ALAM",
        image: require("../assets/products/GI METAL SIGNBOARD/KEDAI PAPAN TANDA _ KILANG PAPAN TANDA DI SEPUTEH _ PUDU _ PUNCAK JALIL _ PUNCAK ALAM.webp"),
        id: "kedai-papan-tanda-kilang-papan-tanda-di-seputeh-pudu-puncak-jalil-puncak-alam"
      },
      {
        name: "KILANG PAPAN TANDA 3D _ 3D BOX UP SIGN FACTORY AT SENTUL _ SEGAMBUT _ SRI DUTAMAS _ TAMAN DESA _ TAMAN DUTA",
        image: require("../assets/products/GI METAL SIGNBOARD/KILANG PAPAN TANDA 3D _ 3D BOX UP SIGN FACTORY AT SENTUL _ SEGAMBUT _ SRI DUTAMAS _ TAMAN DESA _ TAMAN DUTA.webp"),
        id: "kilang-papan-tanda-3d-3d-box-up-sign-factory-at-sentul-segambut-sri-dutamas-taman-desa-taman-duta"
      },
      {
        name: "KOPITIAM GI METAL SIGNBOARD INSTALLED AT KLANG _ KOTA KEMUNING _ SELAYANG _ RIMBAYU",
        image: require("../assets/products/GI METAL SIGNBOARD/KOPITIAM GI METAL SIGNBOARD INSTALLED AT KLANG _ KOTA KEMUNING _ SELAYANG _ RIMBAYU.webp"),
        id: "kopitiam-gi-metal-signboard-installed-at-klang-kota-kemuning-selayang-rimbayu"
      },
      {
        name: "OUTDOOR FACTORY GI METAL SIGNBOARD MAKER AT AMPANG _ KUALA SELANGOR _ SUNGAI BESAR _ TANJUNG KARANG",
        image: require("../assets/products/GI METAL SIGNBOARD/OUTDOOR FACTORY GI METAL SIGNBOARD MAKER AT AMPANG _ KUALA SELANGOR _ SUNGAI BESAR _ TANJUNG KARANG.webp"),
        id: "outdoor-factory-gi-metal-signboard-maker-at-ampang-kuala-selangor-sungai-besar-tanjung-karang"
      },
    ],
  },
  {
    category: "Inkjet Printing",
    categoryImage: require("../assets/products/Inkjet Printing/Backdrop Printing/BACKDROP PRINTING EXPERTS AT KUALA LUMPUR (KL).webp"),
    id: "inkjet-printing",
    description: ``,
    subcategories: [
      {
        category: "Backdrop Printing",
        categoryImage: require("../assets/products/Inkjet Printing/Backdrop Printing/BACKDROP PRINTING EXPERTS AT KUALA LUMPUR (KL).webp"),
        id: "backdrop-printing",
        description: ``,
        products: [
          {
            name: "BACKDROP PRINTING EXPERTS AT KUALA LUMPUR (KL)",
            image: require("../assets/products/Inkjet Printing/Backdrop Printing/BACKDROP PRINTING EXPERTS AT KUALA LUMPUR (KL).webp"),
            id: "backdrop-printing-experts-at-kuala-lumpur-kl"
          },
          {
            name: "BACKDROP PRINTING FOR COMPANY EVENT AT KUALA LUMPUR (KL) _ MALAYSIA _ SHAH ALAM _ SELANGOR",
            image: require("../assets/products/Inkjet Printing/Backdrop Printing/BACKDROP PRINTING FOR COMPANY EVENT AT KUALA LUMPUR (KL) _ MALAYSIA _ SHAH ALAM _ SELANGOR.webp"),
            id: "backdrop-printing-for-company-event-at-kuala-lumpur-kl-malaysia-shah-alam-selangor"
          },
        ]
      },
      {
        category: "Banner Printing",
        categoryImage: require("../assets/products/Inkjet Printing/Banner Printing/EXPRESS BANNER PRINTING SERVICE SUBANG JAYA KLANG PETALING JAYA.webp"),
        id: "banner-printing",
        description: ``,
        products: [
          {
            name: "EXPRESS BANNER PRINTING SERVICE SUBANG JAYA KLANG PETALING JAYA",
            image: require("../assets/products/Inkjet Printing/Banner Printing/EXPRESS BANNER PRINTING SERVICE SUBANG JAYA KLANG PETALING JAYA.webp"),
            id: "express-banner-printing-service-subang-jaya-klang-petaling-jaya"
          },
          {
            name: "Klang Outdoor Banner for property developer",
            image: require("../assets/products/Inkjet Printing/Banner Printing/Klang Outdoor Banner for property developer.jpeg"),
            id: "klang-outdoor-banner-for-property-developer"
          },
          {
            name: "PROPERTY AGENT BANNER PRINTING SPECIALIST FOR AMPANG",
            image: require("../assets/products/Inkjet Printing/Banner Printing/PROPERTY AGENT BANNER PRINTING SPECIALIST FOR AMPANG.webp"),
            id: "property-agent-banner-printing-specialist-for-ampang"
          },
          {
            name: "Ruang Iklan Untuk Disewa",
            image: require("../assets/products/Inkjet Printing/Banner Printing/Ruang Iklan Untuk Disewa.jpeg"),
            id: "ruang-iklan-untuk-disewa"
          },
        ]
      },
      {
        category: "Bunting",
        categoryImage: require("../assets/products/Inkjet Printing/Bunting/Bunting with Roll Up Stand.webp"),
        id: "bunting",
        description: ``,
        products: [
          {
            name: "Bunting with Roll Up Stand",
            image: require("../assets/products/Inkjet Printing/Bunting/Bunting with Roll Up Stand.webp"),
            id: "bunting-with-roll-up-stand"
          },
          {
            name: "Bunting with T-Stand (Round Base)",
            image: require("../assets/products/Inkjet Printing/Bunting/Bunting with T-Stand (Round Base).webp"),
            id: "bunting-with-t-stand-round-base"
          },
          {
            name: "Bunting with X-Stand (2)",
            image: require("../assets/products/Inkjet Printing/Bunting/Bunting with X-Stand (2).webp"),
            id: "bunting-with-x-stand-2"
          },
          {
            name: "Bunting with X-Stand",
            image: require("../assets/products/Inkjet Printing/Bunting/Bunting with X-Stand.webp"),
            id: "bunting-with-x-stand"
          },
          {
            name: "Roll Up Bunting",
            image: require("../assets/products/Inkjet Printing/Bunting/Roll Up Bunting.webp"),
            id: "roll-up-bunting"
          },
          {
            name: "T-Stand Bunting",
            image: require("../assets/products/Inkjet Printing/Bunting/T-Stand Bunting.webp"),
            id: "t-stand-bunting"
          },
          {
            name: "Tripod Stand Bunting",
            image: require("../assets/products/Inkjet Printing/Bunting/Tripod Stand Bunting.webp"),
            id: "tripod-stand-bunting"
          },
        ]
      },
      {
        category: "Frosted Sticker Printing",
        categoryImage: require("../assets/products/Inkjet Printing/Frosted Sticker Printing/Do Not Lean On Glass Railing Warning Sign.jpeg"),
        id: "frosted-sticker-printing",
        description: ``,
        products: [
          {
            name: "Do Not Lean On Glass Railing Warning Sign",
            image: require("../assets/products/Inkjet Printing/Frosted Sticker Printing/Do Not Lean On Glass Railing Warning Sign.jpeg"),
            id: "do-not-lean-on-glass-railing-warning-sign"
          },
        ]
      },
      {
        category: "Glass Sticker Printing",
        categoryImage: require("../assets/products/Inkjet Printing/Glass Sticker Printing/GLASS STICKER.webp"),
        id: "glass-sticker-printing",
        description: ``,
        products: [
          {
            name: "GLASS STICKER",
            image: require("../assets/products/Inkjet Printing/Glass Sticker Printing/GLASS STICKER.webp"),
            id: "glass-sticker"
          },
          {
            name: "Office Table Glass Sticker",
            image: require("../assets/products/Inkjet Printing/Glass Sticker Printing/Office Table Glass Sticker.jpeg"),
            id: "office-table-glass-sticker"
          },
        ]
      },
      {
        category: "Solvent Inkjet Printing",
        categoryImage: require("../assets/products/Inkjet Printing/Solvent Inkjet Printing/AUDI BACKDROP SOLVENT INKJET PRINTING MALAYSIA.webp"),
        id: "solvent-inkjet-printing",
        description: ``,
        products: [
          {
            name: "AUDI BACKDROP SOLVENT INKJET PRINTING MALAYSIA",
            image: require("../assets/products/Inkjet Printing/Solvent Inkjet Printing/AUDI BACKDROP SOLVENT INKJET PRINTING MALAYSIA.webp"),
            id: "audi-backdrop-solvent-inkjet-printing-malaysia"
          },
          {
            name: "AWARDS CEREMONY INKJET PRINTING KUALA LUMPUR",
            image: require("../assets/products/Inkjet Printing/Solvent Inkjet Printing/AWARDS CEREMONY INKJET PRINTING KUALA LUMPUR.webp"),
            id: "awards-ceremony-inkjet-printing-kuala-lumpur"
          },
          {
            name: "CHINESE SOLVENT INKJET PRINTING",
            image: require("../assets/products/Inkjet Printing/Solvent Inkjet Printing/CHINESE SOLVENT INKJET PRINTING.webp"),
            id: "chinese-solvent-inkjet-printing"
          },
          {
            name: "EVENT BACKDROP SOLVENT INKJET PRINTING",
            image: require("../assets/products/Inkjet Printing/Solvent Inkjet Printing/EVENT BACKDROP SOLVENT INKJET PRINTING.webp"),
            id: "event-backdrop-solvent-inkjet-printing"
          },
          {
            name: "JL99 GROUP OFFICE BANNER SOLVENT INKJET PRINTING",
            image: require("../assets/products/Inkjet Printing/Solvent Inkjet Printing/JL99 GROUP OFFICE BANNER SOLVENT INKJET PRINTING.webp"),
            id: "jl99-group-office-banner-solvent-inkjet-printing"
          },
          {
            name: "MCD SOLVENT INKJET PRINTING",
            image: require("../assets/products/Inkjet Printing/Solvent Inkjet Printing/MCD SOLVENT INKJET PRINTING.webp"),
            id: "mcd-solvent-inkjet-printing"
          },
          {
            name: "OFFICE DECOR SOLVENT INKJET PRINTING",
            image: require("../assets/products/Inkjet Printing/Solvent Inkjet Printing/OFFICE DECOR SOLVENT INKJET PRINTING.webp"),
            id: "office-decor-solvent-inkjet-printing"
          },
          {
            name: "OFFICE WALLPAPER INKJET PRINTING",
            image: require("../assets/products/Inkjet Printing/Solvent Inkjet Printing/OFFICE WALLPAPER INKJET PRINTING.webp"),
            id: "office-wallpaper-inkjet-printing"
          },
          {
            name: "OPENDAY SOLVENT INKJET PRINTING",
            image: require("../assets/products/Inkjet Printing/Solvent Inkjet Printing/OPENDAY SOLVENT INKJET PRINTING.webp"),
            id: "openday-solvent-inkjet-printing"
          },
        ]
      },
      {
        category: "UV Inkjet Printing",
        categoryImage: require("../assets/products/Inkjet Printing/UV Inkjet Printing/Reception counter sticker.jpeg"),
        id: "uv-inkjet-printing",
        description: ``,
        products: [
          {
            name: "Reception counter sticker",
            image: require("../assets/products/Inkjet Printing/UV Inkjet Printing/Reception counter sticker.jpeg"),
            id: "reception-counter-sticker"
          },
          {
            name: "Shopping Mall Shop Signage Printing",
            image: require("../assets/products/Inkjet Printing/UV Inkjet Printing/Shopping Mall Shop Signage Printing.jpg"),
            id: "shopping-mall-shop-signage-printing"
          },
          {
            name: "Wall Quote Printing",
            image: require("../assets/products/Inkjet Printing/UV Inkjet Printing/Wall Quote Printing.jpg"),
            id: "wall-quote-printing"
          },
        ]
      },
      {
        category: "Wallpaper Printing",
        categoryImage: require("../assets/products/Inkjet Printing/Wallpaper Printing/OFFICE WALLPAPER SOLVENT INKJET PRINTING.webp"),
        id: "wallpaper-printing",
        description: ``,
        products: [
          {
            name: "OFFICE WALLPAPER SOLVENT INKJET PRINTING",
            image: require("../assets/products/Inkjet Printing/Wallpaper Printing/OFFICE WALLPAPER SOLVENT INKJET PRINTING.webp"),
            id: "office-wallpaper-solvent-inkjet-printing"
          },
        ]
      },
    ],
  },
  {
    category: "JKR ROAD SIGN _ DIRECTION ROAD SIGNAGE",
    categoryImage: require("../assets/products/JKR ROAD SIGN _ DIRECTION ROAD SIGNAGE/3D Lettering Roadsign _ Papan Iklan Selangor.webp"),
    id: "jkr-road-sign-direction-road-signage",
    description: ``,
    products: [
      {
        name: "3D Lettering Roadsign _ Papan Iklan Selangor",
        image: require("../assets/products/JKR ROAD SIGN _ DIRECTION ROAD SIGNAGE/3D Lettering Roadsign _ Papan Iklan Selangor.webp"),
        id: "3d-lettering-roadsign-papan-iklan-selangor"
      },
      {
        name: "AWAS Kurangkan Laju road sign Malaysia _ SAFETY SIGBOARD AT SELANGOR, RAWANG, KUALA LUMPUR (KL), MALAYSIA",
        image: require("../assets/products/JKR ROAD SIGN _ DIRECTION ROAD SIGNAGE/AWAS Kurangkan Laju road sign Malaysia _ SAFETY SIGBOARD AT SELANGOR, RAWANG, KUALA LUMPUR (KL), MALAYSIA.webp"),
        id: "awas-kurangkan-laju-road-sign-malaysia-safety-sigboard-at-selangor-rawang-kuala-lumpur-kl-malaysia"
      },
      {
        name: "AWAS Papan Tanda Jalan Malaysia Roadsign",
        image: require("../assets/products/JKR ROAD SIGN _ DIRECTION ROAD SIGNAGE/AWAS Papan Tanda Jalan Malaysia Roadsign.webp"),
        id: "awas-papan-tanda-jalan-malaysia-roadsign"
      },
      {
        name: "HIGHWAY ROAD SIGNAGE INSTALLED AT KLANG _ BANDAR BESTARI _ SELANGOR _ KUALA LANGAT",
        image: require("../assets/products/JKR ROAD SIGN _ DIRECTION ROAD SIGNAGE/HIGHWAY ROAD SIGNAGE INSTALLED AT KLANG _ BANDAR BESTARI _ SELANGOR _ KUALA LANGAT.webp"),
        id: "highway-road-signage-installed-at-klang-bandar-bestari-selangor-kuala-langat"
      },
      {
        name: "JKR DIRECTION SIGN INSTALLED AT KLANG _ PORT KLANG _ BAYU EMAS _ JENJAROM",
        image: require("../assets/products/JKR ROAD SIGN _ DIRECTION ROAD SIGNAGE/JKR DIRECTION SIGN INSTALLED AT KLANG _ PORT KLANG _ BAYU EMAS _ JENJAROM.webp"),
        id: "jkr-direction-sign-installed-at-klang-port-klang-bayu-emas-jenjarom"
      },
      {
        name: "JKR PROJECT SIGNBOARD SUPPLY AT SHAH ALAM _ PUTRAJAYA _ CYBERJAYA _ HICOM",
        image: require("../assets/products/JKR ROAD SIGN _ DIRECTION ROAD SIGNAGE/JKR PROJECT SIGNBOARD SUPPLY AT SHAH ALAM _ PUTRAJAYA _ CYBERJAYA _ HICOM.webp"),
        id: "jkr-project-signboard-supply-at-shah-alam-putrajaya-cyberjaya-hicom"
      },
      {
        name: "JKR ROAD SIGN MANUFACTURER AT KLANG _ SEPANG _ DENGKIL _ KLIA",
        image: require("../assets/products/JKR ROAD SIGN _ DIRECTION ROAD SIGNAGE/JKR ROAD SIGN MANUFACTURER AT KLANG _ SEPANG _ DENGKIL _ KLIA.webp"),
        id: "jkr-road-sign-manufacturer-at-klang-sepang-dengkil-klia"
      },
      {
        name: "ROAD DIRECTIONAL SIGNBOARD MAKER AT KLANG, KAPAR",
        image: require("../assets/products/JKR ROAD SIGN _ DIRECTION ROAD SIGNAGE/ROAD DIRECTIONAL SIGNBOARD MAKER AT KLANG, KAPAR.webp"),
        id: "road-directional-signboard-maker-at-klang-kapar"
      },
      {
        name: "ROAD SIGNAGE AT KEPONG _ IJOK _ KUALA SELANGOR _ MALAYSIA",
        image: require("../assets/products/JKR ROAD SIGN _ DIRECTION ROAD SIGNAGE/ROAD SIGNAGE AT KEPONG _ IJOK _ KUALA SELANGOR _ MALAYSIA.webp"),
        id: "road-signage-at-kepong-ijok-kuala-selangor-malaysia"
      },
      {
        name: "ROAD SIGNBOARD MAKER AT NILAI _ SEREMBAN _ BANGI _ SENAWANG",
        image: require("../assets/products/JKR ROAD SIGN _ DIRECTION ROAD SIGNAGE/ROAD SIGNBOARD MAKER AT NILAI _ SEREMBAN _ BANGI _ SENAWANG.webp"),
        id: "road-signboard-maker-at-nilai-seremban-bangi-senawang"
      },
      {
        name: "Resort Directory Road Sign _ TRAFFIC SIGN AT SELANGOR, RAWANG, KUALA LUMPUR (KL), MALAYSIA",
        image: require("../assets/products/JKR ROAD SIGN _ DIRECTION ROAD SIGNAGE/Resort Directory Road Sign _ TRAFFIC SIGN AT SELANGOR, RAWANG, KUALA LUMPUR (KL), MALAYSIA.webp"),
        id: "resort-directory-road-sign-traffic-sign-at-selangor-rawang-kuala-lumpur-kl-malaysia"
      },
      {
        name: "STOP ROAD SIGNAGE SPECIALIST AT MALAYSIA _ SELANGOR _ KUALA LUMPUR (KL)",
        image: require("../assets/products/JKR ROAD SIGN _ DIRECTION ROAD SIGNAGE/STOP ROAD SIGNAGE SPECIALIST AT MALAYSIA _ SELANGOR _ KUALA LUMPUR (KL).webp"),
        id: "stop-road-signage-specialist-at-malaysia-selangor-kuala-lumpur-kl"
      },
      {
        name: "STREET NAME ROAD SIGNAGE SUPPLIER AT SELANGOR _ AMPANG _ BESTARI JAYA",
        image: require("../assets/products/JKR ROAD SIGN _ DIRECTION ROAD SIGNAGE/STREET NAME ROAD SIGNAGE SUPPLIER AT SELANGOR _ AMPANG _ BESTARI JAYA.webp"),
        id: "street-name-road-signage-supplier-at-selangor-ampang-bestari-jaya"
      },
      {
        name: "TRAFFIC LIGHT SIGNAGE AT CHERAS _ KEPONG _ TAMAN KENCANA",
        image: require("../assets/products/JKR ROAD SIGN _ DIRECTION ROAD SIGNAGE/TRAFFIC LIGHT SIGNAGE AT CHERAS _ KEPONG _ TAMAN KENCANA.webp"),
        id: "traffic-light-signage-at-cheras-kepong-taman-kencana"
      },
    ],
  },
  {
    category: "LANDMARK SIGNAGE",
    categoryImage: require("../assets/products/LANDMARK SIGNAGE/3d boxup front lit with metal frame support (1).webp"),
    id: "landmark-signage",
    description: ``,
    products: [
      {
        name: "3d boxup front lit with metal frame support (1)",
        image: require("../assets/products/LANDMARK SIGNAGE/3d boxup front lit with metal frame support (1).webp"),
        id: "3d-boxup-front-lit-with-metal-frame-support-1"
      },
      {
        name: "3d boxup front lit with metal frame support (2)",
        image: require("../assets/products/LANDMARK SIGNAGE/3d boxup front lit with metal frame support (2).webp"),
        id: "3d-boxup-front-lit-with-metal-frame-support-2"
      },
      {
        name: "BEST LANDMARK SIGNAGE SUPPLY COMPANY IN SELANGOR _ KEPONG _ IJOK _ AMPANG",
        image: require("../assets/products/LANDMARK SIGNAGE/BEST LANDMARK SIGNAGE SUPPLY COMPANY IN SELANGOR _ KEPONG _ IJOK _ AMPANG.webp"),
        id: "best-landmark-signage-supply-company-in-selangor-kepong-ijok-ampang"
      },
      {
        name: "COMMERCIAL LANDMARK SIGN DESIGN SERVICE AT SELANGOR _ MALAYSIA _ KUALA LUMPUR (KL)",
        image: require("../assets/products/LANDMARK SIGNAGE/COMMERCIAL LANDMARK SIGN DESIGN SERVICE AT SELANGOR _ MALAYSIA _ KUALA LUMPUR (KL).webp"),
        id: "commercial-landmark-sign-design-service-at-selangor-malaysia-kuala-lumpur-kl"
      },
      {
        name: "CUSTOM LANDMARK SIGNS AT MALAYSIA _ GOMBAK _ MELAKA _ SEREMBAN",
        image: require("../assets/products/LANDMARK SIGNAGE/CUSTOM LANDMARK SIGNS AT MALAYSIA _ GOMBAK _ MELAKA _ SEREMBAN.webp"),
        id: "custom-landmark-signs-at-malaysia-gombak-melaka-seremban"
      },
      {
        name: "Daerah Klang 3D Landmark Signage Supplier at Selangor _ Ampang _ Ijok _ Kelana Jaya _ Bestari Jaya _ Sungai Buloh",
        image: require("../assets/products/LANDMARK SIGNAGE/Daerah Klang 3D Landmark Signage Supplier at Selangor _ Ampang _ Ijok _ Kelana Jaya _ Bestari Jaya _ Sungai Buloh.webp"),
        id: "daerah-klang-3d-landmark-signage-supplier-at-selangor-ampang-ijok-kelana-jaya-bestari-jaya-sungai-buloh"
      },
      {
        name: "GIANT STAINLESS STEEL MIRROR BOX UP LANDMARK SIGANGE PROVIDER AT SHAH ALAM _ ELMINA _ SEREMBAN _ MELAKA",
        image: require("../assets/products/LANDMARK SIGNAGE/GIANT STAINLESS STEEL MIRROR BOX UP LANDMARK SIGANGE PROVIDER AT SHAH ALAM _ ELMINA _ SEREMBAN _ MELAKA.webp"),
        id: "giant-stainless-steel-mirror-box-up-landmark-sigange-provider-at-shah-alam-elmina-seremban-melaka"
      },
      {
        name: "LANDMARK SIGN INSTALLATION SERVICE AT SHAH ALAM _ PUNCAK ALAM _ BUKIT JALIL _ PUNCAK JALIL",
        image: require("../assets/products/LANDMARK SIGNAGE/LANDMARK SIGN INSTALLATION SERVICE AT SHAH ALAM _ PUNCAK ALAM _ BUKIT JALIL _ PUNCAK JALIL.webp"),
        id: "landmark-sign-installation-service-at-shah-alam-puncak-alam-bukit-jalil-puncak-jalil"
      },
      {
        name: "LANDMARK SIGN SUPPLIER AT AMPANG _ KEPONG _ TAMAN KENCANA",
        image: require("../assets/products/LANDMARK SIGNAGE/LANDMARK SIGN SUPPLIER AT AMPANG _ KEPONG _ TAMAN KENCANA.webp"),
        id: "landmark-sign-supplier-at-ampang-kepong-taman-kencana"
      },
      {
        name: "LANDMARK SIGNBOARD MAKER AT SELAYANG _ SETAPAK _ SEGAMBUT _ ALAM IMPIAN",
        image: require("../assets/products/LANDMARK SIGNAGE/LANDMARK SIGNBOARD MAKER AT SELAYANG _ SETAPAK _ SEGAMBUT _ ALAM IMPIAN.webp"),
        id: "landmark-signboard-maker-at-selayang-setapak-segambut-alam-impian"
      },
      {
        name: "LANDMARK SIGNBOARD SUPPLIER AT KLANG _ SELANGOR",
        image: require("../assets/products/LANDMARK SIGNAGE/LANDMARK SIGNBOARD SUPPLIER AT KLANG _ SELANGOR.webp"),
        id: "landmark-signboard-supplier-at-klang-selangor"
      },
      {
        name: "LANDMARK SIGNBOARD SUPPLY AT NILAI _ SEPUTEH _ BALAKONG _ KAJANG",
        image: require("../assets/products/LANDMARK SIGNAGE/LANDMARK SIGNBOARD SUPPLY AT NILAI _ SEPUTEH _ BALAKONG _ KAJANG.webp"),
        id: "landmark-signboard-supply-at-nilai-seputeh-balakong-kajang"
      },
      {
        name: "OUTDOOR 3D BOX UP FRONTLIT SIGN AT KLANG _ SELANGOR _ BANTING _ MALAYSIA",
        image: require("../assets/products/LANDMARK SIGNAGE/OUTDOOR 3D BOX UP FRONTLIT SIGN AT KLANG _ SELANGOR _ BANTING _ MALAYSIA.webp"),
        id: "outdoor-3d-box-up-frontlit-sign-at-klang-selangor-banting-malaysia"
      },
      {
        name: "OUTDOOR 3D BOX UP FRONTLIT SIGN AT KLANG _ SELANGOR _ RAMUAN CHINA BESAR _ KUALA SUNGAI BARU",
        image: require("../assets/products/LANDMARK SIGNAGE/OUTDOOR 3D BOX UP FRONTLIT SIGN AT KLANG _ SELANGOR _ RAMUAN CHINA BESAR _ KUALA SUNGAI BARU.webp"),
        id: "outdoor-3d-box-up-frontlit-sign-at-klang-selangor-ramuan-china-besar-kuala-sungai-baru"
      },
      {
        name: "OUTDOOR 3D BOX UP FRONTLIT SIGN AT TANJUNG MALIM _ TANJUNG SEPAT _ TASEK PERMAI _ TELOK GONG _ TERAS JERNANG",
        image: require("../assets/products/LANDMARK SIGNAGE/OUTDOOR 3D BOX UP FRONTLIT SIGN AT TANJUNG MALIM _ TANJUNG SEPAT _ TASEK PERMAI _ TELOK GONG _ TERAS JERNANG.webp"),
        id: "outdoor-3d-box-up-frontlit-sign-at-tanjung-malim-tanjung-sepat-tasek-permai-telok-gong-teras-jernang"
      },
      {
        name: "OUTDOOR LANDMAKR SIGN SUPPLIER AT MALAYSIA _ PETALING JAYA (PJ) _ SUBANG _ KOTA DAMANSARA",
        image: require("../assets/products/LANDMARK SIGNAGE/OUTDOOR LANDMAKR SIGN SUPPLIER AT MALAYSIA _ PETALING JAYA (PJ) _ SUBANG _ KOTA DAMANSARA.webp"),
        id: "outdoor-landmakr-sign-supplier-at-malaysia-petaling-jaya-pj-subang-kota-damansara"
      },
      {
        name: "OUTDOOR LANDMARK SIGN MANUFACTURER & INSTALLED AT RAWANG _ MALAYSIA _ SUNGAI BULOH _ KLANG _ SEPANG",
        image: require("../assets/products/LANDMARK SIGNAGE/OUTDOOR LANDMARK SIGN MANUFACTURER & INSTALLED AT RAWANG _ MALAYSIA _ SUNGAI BULOH _ KLANG _ SEPANG.webp"),
        id: "outdoor-landmark-sign-manufacturer-installed-at-rawang-malaysia-sungai-buloh-klang-sepang"
      },
      {
        name: "OUTDOOR LANDMARK SIGN MANUFACTURER & INSTALLED AT RAWANG _ MALAYSIA _ SUNGAI BULOH _ PUCHONG JAYA _ USJ",
        image: require("../assets/products/LANDMARK SIGNAGE/OUTDOOR LANDMARK SIGN MANUFACTURER & INSTALLED AT RAWANG _ MALAYSIA _ SUNGAI BULOH _ PUCHONG JAYA _ USJ.webp"),
        id: "outdoor-landmark-sign-manufacturer-installed-at-rawang-malaysia-sungai-buloh-puchong-jaya-usj"
      },
      {
        name: "OUTDOOR LANDMARK SIGNAGE MAKER AT BANTING, JENJAROM, SELANGOR",
        image: require("../assets/products/LANDMARK SIGNAGE/OUTDOOR LANDMARK SIGNAGE MAKER AT BANTING, JENJAROM, SELANGOR.webp"),
        id: "outdoor-landmark-signage-maker-at-banting-jenjarom-selangor"
      },
      {
        name: "Outdoor Acrylic Model Decoration AT MALAYSIA, KUALA LUMPUR (KL), SEGAMBUT, GENTING HIGHLAND, GOMBAK",
        image: require("../assets/products/LANDMARK SIGNAGE/Outdoor Acrylic Model Decoration AT MALAYSIA, KUALA LUMPUR (KL), SEGAMBUT, GENTING HIGHLAND, GOMBAK.webp"),
        id: "outdoor-acrylic-model-decoration-at-malaysia-kuala-lumpur-kl-segambut-genting-highland-gombak"
      },
      {
        name: "Outdoor Park Decor Sign MAKER AT KLANG, SELANGOR, SHAH ALAM, KOTA KEMUNING, BUKIT KEMUNING, RIM BAYU",
        image: require("../assets/products/LANDMARK SIGNAGE/Outdoor Park Decor Sign MAKER AT KLANG, SELANGOR, SHAH ALAM, KOTA KEMUNING, BUKIT KEMUNING, RIM BAYU.webp"),
        id: "outdoor-park-decor-sign-maker-at-klang-selangor-shah-alam-kota-kemuning-bukit-kemuning-rim-bayu"
      },
      {
        name: "PBSA LANDMARK SIGN INSTALLED AT SUNGAI BULOH _ KEPONG _ SERDANG _ C180",
        image: require("../assets/products/LANDMARK SIGNAGE/PBSA LANDMARK SIGN INSTALLED AT SUNGAI BULOH _ KEPONG _ SERDANG _ C180.webp"),
        id: "pbsa-landmark-sign-installed-at-sungai-buloh-kepong-serdang-c180"
      },
      {
        name: "ROAD SIDE LANDMARK SIGNAGE MANUFACTURER AT KUALA LUMPUR (KL) _ SELANGOR _ NILAI _ SEREMBAN",
        image: require("../assets/products/LANDMARK SIGNAGE/ROAD SIDE LANDMARK SIGNAGE MANUFACTURER AT KUALA LUMPUR (KL) _ SELANGOR _ NILAI _ SEREMBAN.webp"),
        id: "road-side-landmark-signage-manufacturer-at-kuala-lumpur-kl-selangor-nilai-seremban"
      },
      {
        name: "SCHOOL 3D LANDMARK SIGNAGE MAKER AT KLANG",
        image: require("../assets/products/LANDMARK SIGNAGE/SCHOOL 3D LANDMARK SIGNAGE MAKER AT KLANG.webp"),
        id: "school-3d-landmark-signage-maker-at-klang"
      },
    ],
  },
  {
    category: "LED NEON SIGNAGE _ GLASS NEON SIGNAGE",
    categoryImage: require("../assets/products/LED NEON SIGNAGE _ GLASS NEON SIGNAGE/ACRYLIC LED NEON SIGNBOARD AT KLANG, BANDAR BOTANIC, TAMAN OUG.webp"),
    id: "led-neon-signage-glass-neon-signage",
    description: ``,
    products: [
      {
        name: "ACRYLIC LED NEON SIGNBOARD AT KLANG, BANDAR BOTANIC, TAMAN OUG",
        image: require("../assets/products/LED NEON SIGNAGE _ GLASS NEON SIGNAGE/ACRYLIC LED NEON SIGNBOARD AT KLANG, BANDAR BOTANIC, TAMAN OUG.webp"),
        id: "acrylic-led-neon-signboard-at-klang-bandar-botanic-taman-oug"
      },
      {
        name: "CUSTOM GLASS NEON SIGNAGE DESIGN AT KEPONG _ JINJANG _ IJOK _ BESTARI JAYA _ RAWANG",
        image: require("../assets/products/LED NEON SIGNAGE _ GLASS NEON SIGNAGE/CUSTOM GLASS NEON SIGNAGE DESIGN AT KEPONG _ JINJANG _ IJOK _ BESTARI JAYA _ RAWANG.webp"),
        id: "custom-glass-neon-signage-design-at-kepong-jinjang-ijok-bestari-jaya-rawang"
      },
      {
        name: "CUSTOM LED NEON SIGN AT SELANGOR _ MALAYSIA _ SUBANG _ USJ",
        image: require("../assets/products/LED NEON SIGNAGE _ GLASS NEON SIGNAGE/CUSTOM LED NEON SIGN AT SELANGOR _ MALAYSIA _ SUBANG _ USJ.webp"),
        id: "custom-led-neon-sign-at-selangor-malaysia-subang-usj"
      },
      {
        name: "HOTEL GLASS NEON SIGNAGE MAKER AT MALAYSIA _ AMPANG _ NILAI _ KEPONG _ TAMAN KENCANA",
        image: require("../assets/products/LED NEON SIGNAGE _ GLASS NEON SIGNAGE/HOTEL GLASS NEON SIGNAGE MAKER AT MALAYSIA _ AMPANG _ NILAI _ KEPONG _ TAMAN KENCANA.webp"),
        id: "hotel-glass-neon-signage-maker-at-malaysia-ampang-nilai-kepong-taman-kencana"
      },
      {
        name: "INDOOR LED NEON SIGN AT SELANGOR _ MALAYSIA _ SUBANG _ USJ",
        image: require("../assets/products/LED NEON SIGNAGE _ GLASS NEON SIGNAGE/INDOOR LED NEON SIGN AT SELANGOR _ MALAYSIA _ SUBANG _ USJ.webp"),
        id: "indoor-led-neon-sign-at-selangor-malaysia-subang-usj"
      },
      {
        name: "INDOOR LED NEON SIGN MAKER AT KLANG, SEMENYIH, RAWANG, SUNGAI BULOH",
        image: require("../assets/products/LED NEON SIGNAGE _ GLASS NEON SIGNAGE/INDOOR LED NEON SIGN MAKER AT KLANG, SEMENYIH, RAWANG, SUNGAI BULOH.webp"),
        id: "indoor-led-neon-sign-maker-at-klang-semenyih-rawang-sungai-buloh"
      },
      {
        name: "LED NED STUDIO AT BESTARI JAYA, SUBANG, SHAH ALAM, KEPONG, TAMAN KENCANA, KUALA LUMPUR (KL)",
        image: require("../assets/products/LED NEON SIGNAGE _ GLASS NEON SIGNAGE/LED NED STUDIO AT BESTARI JAYA, SUBANG, SHAH ALAM, KEPONG, TAMAN KENCANA, KUALA LUMPUR (KL).webp"),
        id: "led-ned-studio-at-bestari-jaya-subang-shah-alam-kepong-taman-kencana-kuala-lumpur-kl"
      },
      {
        name: "LED NED STUDIO AT GOMBAK, PUCHONG, SHAH ALAM, KEPONG, TAMAN KENCANA, KUALA LUMPUR (KL)",
        image: require("../assets/products/LED NEON SIGNAGE _ GLASS NEON SIGNAGE/LED NED STUDIO AT GOMBAK, PUCHONG, SHAH ALAM, KEPONG, TAMAN KENCANA, KUALA LUMPUR (KL).webp"),
        id: "led-ned-studio-at-gombak-puchong-shah-alam-kepong-taman-kencana-kuala-lumpur-kl"
      },
      {
        name: "LED NED STUDIO AT KLANG, RAWANG, SHAH ALAM, KEPONG, TAMAN KENCANA, KUALA LUMPUR (KL)",
        image: require("../assets/products/LED NEON SIGNAGE _ GLASS NEON SIGNAGE/LED NED STUDIO AT KLANG, RAWANG, SHAH ALAM, KEPONG, TAMAN KENCANA, KUALA LUMPUR (KL).webp"),
        id: "led-ned-studio-at-klang-rawang-shah-alam-kepong-taman-kencana-kuala-lumpur-kl"
      },
      {
        name: "LED NEON SIGNAGE SPECIALIST AT KLANG _ PUCHONG _ KUCHAI LAMA _ KEPONG",
        image: require("../assets/products/LED NEON SIGNAGE _ GLASS NEON SIGNAGE/LED NEON SIGNAGE SPECIALIST AT KLANG _ PUCHONG _ KUCHAI LAMA _ KEPONG.webp"),
        id: "led-neon-signage-specialist-at-klang-puchong-kuchai-lama-kepong"
      },
      {
        name: "NEON LIGHT SIGNAGE SPECIALIST AT KUALA LUMPUR (KL) _ SALAK SOUTH _ SALAK SELATAN _ SUNGAI BESI _ AMPANG",
        image: require("../assets/products/LED NEON SIGNAGE _ GLASS NEON SIGNAGE/NEON LIGHT SIGNAGE SPECIALIST AT KUALA LUMPUR (KL) _ SALAK SOUTH _ SALAK SELATAN _ SUNGAI BESI _ AMPANG.webp"),
        id: "neon-light-signage-specialist-at-kuala-lumpur-kl-salak-south-salak-selatan-sungai-besi-ampang"
      },
      {
        name: "OUTDOOR LED NEON INSTALLATION SERVICE AT SUBANG, USJ, SELANGOR",
        image: require("../assets/products/LED NEON SIGNAGE _ GLASS NEON SIGNAGE/OUTDOOR LED NEON INSTALLATION SERVICE AT SUBANG, USJ, SELANGOR.webp"),
        id: "outdoor-led-neon-installation-service-at-subang-usj-selangor"
      },
      {
        name: "PAKAR PAPAN TANDA NEON LED AT RAWANG _ KEPONG _ SRI PETALING _ BUKIT JALIL",
        image: require("../assets/products/LED NEON SIGNAGE _ GLASS NEON SIGNAGE/PAKAR PAPAN TANDA NEON LED AT RAWANG _ KEPONG _ SRI PETALING _ BUKIT JALIL.webp"),
        id: "pakar-papan-tanda-neon-led-at-rawang-kepong-sri-petaling-bukit-jalil"
      },
      {
        name: "RESTAURANT INDOOR LED NEON MAKER AT BANDAR PUTERI PUCHONG, SELANGOR",
        image: require("../assets/products/LED NEON SIGNAGE _ GLASS NEON SIGNAGE/RESTAURANT INDOOR LED NEON MAKER AT BANDAR PUTERI PUCHONG, SELANGOR.webp"),
        id: "restaurant-indoor-led-neon-maker-at-bandar-puteri-puchong-selangor"
      },
      {
        name: "RESTAURANT LED NEON SIGNAGE MAKER AT SELANGOR _ MALAYSIA _ SUBANG _ USJ",
        image: require("../assets/products/LED NEON SIGNAGE _ GLASS NEON SIGNAGE/RESTAURANT LED NEON SIGNAGE MAKER AT SELANGOR _ MALAYSIA _ SUBANG _ USJ.webp"),
        id: "restaurant-led-neon-signage-maker-at-selangor-malaysia-subang-usj"
      },
    ],
  },
  {
    category: "LED Neon",
    categoryImage: require("../assets/products/LED Neon/Chicken LED Neon Signage.jpeg"),
    id: "led-neon",
    description: `Add a vibrant touch to your establishment with our LED neon signs. Perfect for cafes, bars, and retail spaces, these signs are energy-efficient and long-lasting. Whether you need a standard design or a custom creation, our LED neon signs can be tailored to reflect your brand’s personality. Let us light up your space with our stunning and customizable LED neon signag`,
    products: [
      {
        name: "Chicken LED Neon Signage",
        image: require("../assets/products/LED Neon/Chicken LED Neon Signage.jpeg"),
        id: "chicken-led-neon-signage"
      },
      {
        name: "Farm Fresh Neon LED Decor",
        image: require("../assets/products/LED Neon/Farm Fresh Neon LED Decor.jpeg"),
        id: "farm-fresh-neon-led-decor"
      },
      {
        name: "Order Now Neon LED Signage",
        image: require("../assets/products/LED Neon/Order Now Neon LED Signage.jpeg"),
        id: "order-now-neon-led-signage"
      },
    ],
  },
  {
    category: "LED SCREEN PANEL",
    categoryImage: require("../assets/products/LED SCREEN PANEL/BEST CUSTOM LED SCREEN SIGN CONTRACTOR AT MALAYSIA.webp"),
    id: "led-screen-panel",
    description: ``,
    products: [
      {
        name: "BEST CUSTOM LED SCREEN SIGN CONTRACTOR AT MALAYSIA",
        image: require("../assets/products/LED SCREEN PANEL/BEST CUSTOM LED SCREEN SIGN CONTRACTOR AT MALAYSIA.webp"),
        id: "best-custom-led-screen-sign-contractor-at-malaysia"
      },
      {
        name: "DIGITAL LED SIGN SPECIALIST AT MALAYSIA _ KUALA LUMPUR (KL) _ SUBANG _ KEPONG",
        image: require("../assets/products/LED SCREEN PANEL/DIGITAL LED SIGN SPECIALIST AT MALAYSIA _ KUALA LUMPUR (KL) _ SUBANG _ KEPONG.webp"),
        id: "digital-led-sign-specialist-at-malaysia-kuala-lumpur-kl-subang-kepong"
      },
      {
        name: "LARGE LED ADVERTISING PANEL AT KUALA LUMPUR (KL)",
        image: require("../assets/products/LED SCREEN PANEL/LARGE LED ADVERTISING PANEL AT KUALA LUMPUR (KL).webp"),
        id: "large-led-advertising-panel-at-kuala-lumpur-kl"
      },
      {
        name: "LED ADVERTISING SCREEN PANEL SUPPLY AT SELAYANG _ BUKIT BINTANG _ JALAN ALOR",
        image: require("../assets/products/LED SCREEN PANEL/LED ADVERTISING SCREEN PANEL SUPPLY AT SELAYANG _ BUKIT BINTANG _ JALAN ALOR.webp"),
        id: "led-advertising-screen-panel-supply-at-selayang-bukit-bintang-jalan-alor"
      },
      {
        name: "LED DISPLAY PANEL SPECIALIST AT PETALING JAYA (PJ)",
        image: require("../assets/products/LED SCREEN PANEL/LED DISPLAY PANEL SPECIALIST AT PETALING JAYA (PJ).webp"),
        id: "led-display-panel-specialist-at-petaling-jaya-pj"
      },
      {
        name: "LED DISPLAY SIGNS INSTALLED AT KUALA LUMPUR (KL) _ MALAYSIA _ SELANGOR",
        image: require("../assets/products/LED SCREEN PANEL/LED DISPLAY SIGNS INSTALLED AT KUALA LUMPUR (KL) _ MALAYSIA _ SELANGOR.webp"),
        id: "led-display-signs-installed-at-kuala-lumpur-kl-malaysia-selangor"
      },
      {
        name: "LED PANEL SIGN EXPERTS AT SETIA ALAM _ PUNCAK ALAM _ BUKIT JALIL _ KELANA JAYA",
        image: require("../assets/products/LED SCREEN PANEL/LED PANEL SIGN EXPERTS AT SETIA ALAM _ PUNCAK ALAM _ BUKIT JALIL _ KELANA JAYA.webp"),
        id: "led-panel-sign-experts-at-setia-alam-puncak-alam-bukit-jalil-kelana-jaya"
      },
      {
        name: "LED Panel",
        image: require("../assets/products/LED SCREEN PANEL/LED Panel.webp"),
        id: "led-panel"
      },
      {
        name: "LED SCREEN SIGNAGE SERVICE AT KUALA LUMPUR (KL) _ PETALING JAYA (PJ)",
        image: require("../assets/products/LED SCREEN PANEL/LED SCREEN SIGNAGE SERVICE AT KUALA LUMPUR (KL) _ PETALING JAYA (PJ).webp"),
        id: "led-screen-signage-service-at-kuala-lumpur-kl-petaling-jaya-pj"
      },
      {
        name: "OUTDOOR FULL COLOR LED DISPLAY PANEL AT MALAYSIA _ NILAI _ SEREMBAN",
        image: require("../assets/products/LED SCREEN PANEL/OUTDOOR FULL COLOR LED DISPLAY PANEL AT MALAYSIA _ NILAI _ SEREMBAN.webp"),
        id: "outdoor-full-color-led-display-panel-at-malaysia-nilai-seremban"
      },
      {
        name: "OUTDOOR LED DISPLAY PANEL SPECIALIST AT BALAKONG _ SERDANG _ KUALA LUMPUR (KL) _ PUDU",
        image: require("../assets/products/LED SCREEN PANEL/OUTDOOR LED DISPLAY PANEL SPECIALIST AT BALAKONG _ SERDANG _ KUALA LUMPUR (KL) _ PUDU.webp"),
        id: "outdoor-led-display-panel-specialist-at-balakong-serdang-kuala-lumpur-kl-pudu"
      },
      {
        name: "OUTDOOR LED SCREEN DISPLAY SIGN INSTALLED AT KLANG _ SELANGOR _ MALAYSIA _ SHAH ALAM",
        image: require("../assets/products/LED SCREEN PANEL/OUTDOOR LED SCREEN DISPLAY SIGN INSTALLED AT KLANG _ SELANGOR _ MALAYSIA _ SHAH ALAM.webp"),
        id: "outdoor-led-screen-display-sign-installed-at-klang-selangor-malaysia-shah-alam"
      },
      {
        name: "OUTDOOR LED SCREEN DISPLAY SIGN INSTALLED AT KUALA LUMPUR (KL) _ SUBANG _ CHERAS _ KAJANG",
        image: require("../assets/products/LED SCREEN PANEL/OUTDOOR LED SCREEN DISPLAY SIGN INSTALLED AT KUALA LUMPUR (KL) _ SUBANG _ CHERAS _ KAJANG.webp"),
        id: "outdoor-led-screen-display-sign-installed-at-kuala-lumpur-kl-subang-cheras-kajang"
      },
      {
        name: "OUTDOOR LED SCREEN PANEL DISPLAY SIGN INSTALLED AT SETAPAK _ SERENDAH _ SENAWANG _ NILAI",
        image: require("../assets/products/LED SCREEN PANEL/OUTDOOR LED SCREEN PANEL DISPLAY SIGN INSTALLED AT SETAPAK _ SERENDAH _ SENAWANG _ NILAI.webp"),
        id: "outdoor-led-screen-panel-display-sign-installed-at-setapak-serendah-senawang-nilai"
      },
    ],
  },
  {
    category: "LIGHT BULB SIGNAGE",
    categoryImage: require("../assets/products/LIGHT BULB SIGNAGE/3D BOX UP LIGHT BULB SIGNBOARD AT AMPANG _ SELANGOR _ NEGERI SEMBILAN _ SENAWANG.webp"),
    id: "light-bulb-signage",
    description: ``,
    products: [
      {
        name: "3D BOX UP LIGHT BULB SIGNBOARD AT AMPANG _ SELANGOR _ NEGERI SEMBILAN _ SENAWANG",
        image: require("../assets/products/LIGHT BULB SIGNAGE/3D BOX UP LIGHT BULB SIGNBOARD AT AMPANG _ SELANGOR _ NEGERI SEMBILAN _ SENAWANG.webp"),
        id: "3d-box-up-light-bulb-signboard-at-ampang-selangor-negeri-sembilan-senawang"
      },
      {
        name: "BAR LIGHT BULB SIGNBOARD SUPPLY AT SELANGOR _ AMPANG",
        image: require("../assets/products/LIGHT BULB SIGNAGE/BAR LIGHT BULB SIGNBOARD SUPPLY AT SELANGOR _ AMPANG.webp"),
        id: "bar-light-bulb-signboard-supply-at-selangor-ampang"
      },
      {
        name: "LIGHT BULB SIGNAGE SUPPLIER AT MALAYSIA _ KUALA LUMPUR (KL) _ SETAPAK _ SEREMBAN",
        image: require("../assets/products/LIGHT BULB SIGNAGE/LIGHT BULB SIGNAGE SUPPLIER AT MALAYSIA _ KUALA LUMPUR (KL) _ SETAPAK _ SEREMBAN.webp"),
        id: "light-bulb-signage-supplier-at-malaysia-kuala-lumpur-kl-setapak-seremban"
      },
      {
        name: "LIGHT BULB SIGNBOARD SOLUTIONS AT MALAYSIA _ KEPONG _ TAMAN KENCANA",
        image: require("../assets/products/LIGHT BULB SIGNAGE/LIGHT BULB SIGNBOARD SOLUTIONS AT MALAYSIA _ KEPONG _ TAMAN KENCANA.webp"),
        id: "light-bulb-signboard-solutions-at-malaysia-kepong-taman-kencana"
      },
    ],
  },
  {
    category: "LIGHTBOX",
    categoryImage: require("../assets/products/LIGHTBOX/ALUMINIUM CASING LIGHTBOX SIGNAGE INSTALLED AT KAJANG _ C180 _ TAMAN EQUINE _ SELAYANG.webp"),
    id: "lightbox",
    description: ``,
    products: [
      {
        name: "ALUMINIUM CASING LIGHTBOX SIGNAGE INSTALLED AT KAJANG _ C180 _ TAMAN EQUINE _ SELAYANG",
        image: require("../assets/products/LIGHTBOX/ALUMINIUM CASING LIGHTBOX SIGNAGE INSTALLED AT KAJANG _ C180 _ TAMAN EQUINE _ SELAYANG.webp"),
        id: "aluminium-casing-lightbox-signage-installed-at-kajang-c180-taman-equine-selayang"
      },
      {
        name: "CLINIC OUTDOOR LIGHTBOX SIGN MAKER AT KEPONG, BESTARI JAYA, SEPANG",
        image: require("../assets/products/LIGHTBOX/CLINIC OUTDOOR LIGHTBOX SIGN MAKER AT KEPONG, BESTARI JAYA, SEPANG.webp"),
        id: "clinic-outdoor-lightbox-sign-maker-at-kepong-bestari-jaya-sepang"
      },
      {
        name: "DOUBLE SIDED LIGHTBOX DESIGN SERVICE AT BANDAR UTAMA _ JINJANG _ KEPONG _ IJOK",
        image: require("../assets/products/LIGHTBOX/DOUBLE SIDED LIGHTBOX DESIGN SERVICE AT BANDAR UTAMA _ JINJANG _ KEPONG _ IJOK.webp"),
        id: "double-sided-lightbox-design-service-at-bandar-utama-jinjang-kepong-ijok"
      },
      {
        name: "Double sided circle lightbox KOTA KEMUNING, PUDU, TRX, KUALA LUMPUR (KL), JALAN CHANGKAT",
        image: require("../assets/products/LIGHTBOX/Double sided circle lightbox KOTA KEMUNING, PUDU, TRX, KUALA LUMPUR (KL), JALAN CHANGKAT.webp"),
        id: "double-sided-circle-lightbox-kota-kemuning-pudu-trx-kuala-lumpur-kl-jalan-changkat"
      },
      {
        name: "Double sided lightbox AT AMAN PERDANA, SETIA ALAM, SRI MUDA, SHAH ALAM",
        image: require("../assets/products/LIGHTBOX/Double sided lightbox AT AMAN PERDANA, SETIA ALAM, SRI MUDA, SHAH ALAM.webp"),
        id: "double-sided-lightbox-at-aman-perdana-setia-alam-sri-muda-shah-alam"
      },
      {
        name: "FACTORY LIGHTBOX SIGNBOARD MASTER AT PANDAMARAN JAYA _ TELOK GONG _ TELOK PANGLIMA GARANG _ BANTING",
        image: require("../assets/products/LIGHTBOX/FACTORY LIGHTBOX SIGNBOARD MASTER AT PANDAMARAN JAYA _ TELOK GONG _ TELOK PANGLIMA GARANG _ BANTING.webp"),
        id: "factory-lightbox-signboard-master-at-pandamaran-jaya-telok-gong-telok-panglima-garang-banting"
      },
      {
        name: "Factory front lightbox AT PORT KLANG, KAPAR, BATU BELAH, MERU, SETIA ALAM",
        image: require("../assets/products/LIGHTBOX/Factory front lightbox AT PORT KLANG, KAPAR, BATU BELAH, MERU, SETIA ALAM.webp"),
        id: "factory-front-lightbox-at-port-klang-kapar-batu-belah-meru-setia-alam"
      },
      {
        name: "High Heel Logo customized double sided lightbox AT BANGSAR, PETALING JAYA (PJ), KUALA LUMPUR (KL), BANGI, NILAI",
        image: require("../assets/products/LIGHTBOX/High Heel Logo customized double sided lightbox AT BANGSAR, PETALING JAYA (PJ), KUALA LUMPUR (KL), BANGI, NILAI.webp"),
        id: "high-heel-logo-customized-double-sided-lightbox-at-bangsar-petaling-jaya-pj-kuala-lumpur-kl-bangi-nilai"
      },
      {
        name: "INDOOR CIRCLE LIGHTBOX SUPPLIER AT AMPANG _ KEPONG _ KELANA JAYA _ SRI DAMANSARA",
        image: require("../assets/products/LIGHTBOX/INDOOR CIRCLE LIGHTBOX SUPPLIER AT AMPANG _ KEPONG _ KELANA JAYA _ SRI DAMANSARA.webp"),
        id: "indoor-circle-lightbox-supplier-at-ampang-kepong-kelana-jaya-sri-damansara"
      },
      {
        name: "INDOOR Cube Lightbox MAKER AT SEPANG, DENGKIL, PUTRAJAYA, CYBERJAYA",
        image: require("../assets/products/LIGHTBOX/INDOOR Cube Lightbox MAKER AT SEPANG, DENGKIL, PUTRAJAYA, CYBERJAYA.webp"),
        id: "indoor-cube-lightbox-maker-at-sepang-dengkil-putrajaya-cyberjaya"
      },
      {
        name: "KEDAI PAPAN TANDA _ KEDAI PAPAN IKLAN AT CYBERJAYA _ PUTRAJAYA _ KUALA LUMPUR (KL) _ PUNCAK ALAM",
        image: require("../assets/products/LIGHTBOX/KEDAI PAPAN TANDA _ KEDAI PAPAN IKLAN AT CYBERJAYA _ PUTRAJAYA _ KUALA LUMPUR (KL) _ PUNCAK ALAM.webp"),
        id: "kedai-papan-tanda-kedai-papan-iklan-at-cyberjaya-putrajaya-kuala-lumpur-kl-puncak-alam"
      },
      {
        name: "LIGHTBOX MAKER AT SELANGOR",
        image: require("../assets/products/LIGHTBOX/LIGHTBOX MAKER AT SELANGOR.webp"),
        id: "lightbox-maker-at-selangor"
      },
      {
        name: "OFFICE LIGHTBOX SIGN MAKER AT BUKIT JALIL _ PUNCAK ALAM",
        image: require("../assets/products/LIGHTBOX/OFFICE LIGHTBOX SIGN MAKER AT BUKIT JALIL _ PUNCAK ALAM.webp"),
        id: "office-lightbox-sign-maker-at-bukit-jalil-puncak-alam"
      },
      {
        name: "OFFICE LIGHTBOX SIGN SPECIALIST AT KLANG, PETALING JAYA (PJ), RAWANG, KEPONG, MALAYSIA",
        image: require("../assets/products/LIGHTBOX/OFFICE LIGHTBOX SIGN SPECIALIST AT KLANG, PETALING JAYA (PJ), RAWANG, KEPONG, MALAYSIA.webp"),
        id: "office-lightbox-sign-specialist-at-klang-petaling-jaya-pj-rawang-kepong-malaysia"
      },
      {
        name: "OFFICE Reception Wall Background Signage AT BANGSAR, PETALING JAYA (PJ), KUALA LUMPUR (KL), BANGI, NILAI",
        image: require("../assets/products/LIGHTBOX/OFFICE Reception Wall Background Signage AT BANGSAR, PETALING JAYA (PJ), KUALA LUMPUR (KL), BANGI, NILAI.webp"),
        id: "office-reception-wall-background-signage-at-bangsar-petaling-jaya-pj-kuala-lumpur-kl-bangi-nilai"
      },
      {
        name: "RETAIL LIGHTBOX SIGNBOARD INSTALLED AT SOUTH CITY _ SALAK SOUTH _ BALAKONG",
        image: require("../assets/products/LIGHTBOX/RETAIL LIGHTBOX SIGNBOARD INSTALLED AT SOUTH CITY _ SALAK SOUTH _ BALAKONG.webp"),
        id: "retail-lightbox-signboard-installed-at-south-city-salak-south-balakong"
      },
      {
        name: "RETAIL LIGHTBOX SIGNBOARD SUPPLIER AT MERU, CHERAS, BALAKONG, KAJANG, KL",
        image: require("../assets/products/LIGHTBOX/RETAIL LIGHTBOX SIGNBOARD SUPPLIER AT MERU, CHERAS, BALAKONG, KAJANG, KL.webp"),
        id: "retail-lightbox-signboard-supplier-at-meru-cheras-balakong-kajang-kl"
      },
      {
        name: "RETAIL LIGHTBOX SUPPLIER AT SEPUTEH _ SENTUL _ SEGAMBUT _ SRI DUTAMAS _ TAMAN DESA",
        image: require("../assets/products/LIGHTBOX/RETAIL LIGHTBOX SUPPLIER AT SEPUTEH _ SENTUL _ SEGAMBUT _ SRI DUTAMAS _ TAMAN DESA.webp"),
        id: "retail-lightbox-supplier-at-seputeh-sentul-segambut-sri-dutamas-taman-desa"
      },
      {
        name: "RETAIL SHOP LIGHTBOX SIGNAGE MAKER AT NILAI, BANGI, RAWANG, KAJANG, IJOK",
        image: require("../assets/products/LIGHTBOX/RETAIL SHOP LIGHTBOX SIGNAGE MAKER AT NILAI, BANGI, RAWANG, KAJANG, IJOK.webp"),
        id: "retail-shop-lightbox-signage-maker-at-nilai-bangi-rawang-kajang-ijok"
      },
      {
        name: "SALON LIGHTBOX SIGN SUPPLY AT SELANGOR _ PUCHONG _ USJ",
        image: require("../assets/products/LIGHTBOX/SALON LIGHTBOX SIGN SUPPLY AT SELANGOR _ PUCHONG _ USJ.webp"),
        id: "salon-lightbox-sign-supply-at-selangor-puchong-usj"
      },
    ],
  },
  {
    category: "Landmark Sign",
    categoryImage: require("../assets/products/Landmark Sign/Daerah Klang 3D Landmark Signage.jpg"),
    id: "landmark-sign",
    description: `Make a memorable impression with our landmark signs. Ideal for city landmarks, parks, and significant locations, our signs are designed to stand out and endure the elements. We offer bespoke designs to highlight the unique features of your site, ensuring your landmark is easily recognizable and aesthetically pleasing. Contact us to create a landmark sign that leaves a lasting impact.`,
    products: [
      {
        name: "Daerah Klang 3D Landmark Signage",
        image: require("../assets/products/Landmark Sign/Daerah Klang 3D Landmark Signage.jpg"),
        id: "daerah-klang-3d-landmark-signage"
      },
      {
        name: "Outdoor Acrylic Model Decoration",
        image: require("../assets/products/Landmark Sign/Outdoor Acrylic Model Decoration.jpeg"),
        id: "outdoor-acrylic-model-decoration"
      },
      {
        name: "Outdoor Park Decor ",
        image: require("../assets/products/Landmark Sign/Outdoor Park Decor .jpeg"),
        id: "outdoor-park-decor"
      },
    ],
  },
  {
    category: "PYLON SIGNAGE",
    categoryImage: require("../assets/products/PYLON SIGNAGE/COMMERCIAL PYLON SIGNAGE AT KUALA LUMPUR (KL).webp"),
    id: "pylon-signage",
    description: ``,
    products: [
      {
        name: "COMMERCIAL PYLON SIGNAGE AT KUALA LUMPUR (KL)",
        image: require("../assets/products/PYLON SIGNAGE/COMMERCIAL PYLON SIGNAGE AT KUALA LUMPUR (KL).webp"),
        id: "commercial-pylon-signage-at-kuala-lumpur-kl"
      },
      {
        name: "CORPORATE PYLON SIGNAGE INSTALLED AT GOMBAK _ NILAI _ MELAKA _ KAJANG",
        image: require("../assets/products/PYLON SIGNAGE/CORPORATE PYLON SIGNAGE INSTALLED AT GOMBAK _ NILAI _ MELAKA _ KAJANG.webp"),
        id: "corporate-pylon-signage-installed-at-gombak-nilai-melaka-kajang"
      },
      {
        name: "CUSTOM PYLON SIGN INSTALLED AT KLANG _ SELANGOR _ MALAYSIA",
        image: require("../assets/products/PYLON SIGNAGE/CUSTOM PYLON SIGN INSTALLED AT KLANG _ SELANGOR _ MALAYSIA.webp"),
        id: "custom-pylon-sign-installed-at-klang-selangor-malaysia"
      },
      {
        name: "GARDEN PYLON SIGNAGE SUPPLY AT SEKSYEN 7 SHAH ALAM _ KOTA KEMUNING",
        image: require("../assets/products/PYLON SIGNAGE/GARDEN PYLON SIGNAGE SUPPLY AT SEKSYEN 7 SHAH ALAM _ KOTA KEMUNING.webp"),
        id: "garden-pylon-signage-supply-at-seksyen-7-shah-alam-kota-kemuning"
      },
      {
        name: "HOTEL Swimming Pool Directory Signage Pylon MAKER AT KUALA LUMPUR (KL), MALAYSIA, SELANGOR, TAMAN KENCANA",
        image: require("../assets/products/PYLON SIGNAGE/HOTEL Swimming Pool Directory Signage Pylon MAKER AT KUALA LUMPUR (KL), MALAYSIA, SELANGOR, TAMAN KENCANA.webp"),
        id: "hotel-swimming-pool-directory-signage-pylon-maker-at-kuala-lumpur-kl-malaysia-selangor-taman-kencana"
      },
      {
        name: "INDOOR PYLON SIGNBOARD MANUFACTURER AT SRI PETALING, SELANGOR",
        image: require("../assets/products/PYLON SIGNAGE/INDOOR PYLON SIGNBOARD MANUFACTURER AT SRI PETALING, SELANGOR.webp"),
        id: "indoor-pylon-signboard-manufacturer-at-sri-petaling-selangor"
      },
      {
        name: "INFORMATIONAL PYLON SIGNAGE SUPPLY AT MALAYSIA _ KUALA LUMPUR (KL) _ AMPANG",
        image: require("../assets/products/PYLON SIGNAGE/INFORMATIONAL PYLON SIGNAGE SUPPLY AT MALAYSIA _ KUALA LUMPUR (KL) _ AMPANG.webp"),
        id: "informational-pylon-signage-supply-at-malaysia-kuala-lumpur-kl-ampang"
      },
      {
        name: "PYLON POLE SIGNAGE AT MALAYSIA _ KUALA LUMPUR (KL) _ AMPANG",
        image: require("../assets/products/PYLON SIGNAGE/PYLON POLE SIGNAGE AT MALAYSIA _ KUALA LUMPUR (KL) _ AMPANG.webp"),
        id: "pylon-pole-signage-at-malaysia-kuala-lumpur-kl-ampang"
      },
      {
        name: "PYLON SIGNAGE SPECIALIST MALAYSIA",
        image: require("../assets/products/PYLON SIGNAGE/PYLON SIGNAGE SPECIALIST MALAYSIA.webp"),
        id: "pylon-signage-specialist-malaysia"
      },
      {
        name: "PYLON SIGNBOARD AT MALAYSIA",
        image: require("../assets/products/PYLON SIGNAGE/PYLON SIGNBOARD AT MALAYSIA.webp"),
        id: "pylon-signboard-at-malaysia"
      },
      {
        name: "PYLON SIGNBOARD SPECIALIST AT SHAH ALAM, SELANGOR",
        image: require("../assets/products/PYLON SIGNAGE/PYLON SIGNBOARD SPECIALIST AT SHAH ALAM, SELANGOR.webp"),
        id: "pylon-signboard-specialist-at-shah-alam-selangor"
      },
      {
        name: "Pylon Directory Sign SUPPLY AT KEPONG, TAMAN INDSUTRI BATU, IJOK, BESTARI JAYA, SERENDAH",
        image: require("../assets/products/PYLON SIGNAGE/Pylon Directory Sign SUPPLY AT KEPONG, TAMAN INDSUTRI BATU, IJOK, BESTARI JAYA, SERENDAH.webp"),
        id: "pylon-directory-sign-supply-at-kepong-taman-indsutri-batu-ijok-bestari-jaya-serendah"
      },
      {
        name: "ROAD SIDE PYLON SIGNBOARD AT KLANG _ PORT KLANG _ MERU _ IJOK",
        image: require("../assets/products/PYLON SIGNAGE/ROAD SIDE PYLON SIGNBOARD AT KLANG _ PORT KLANG _ MERU _ IJOK.webp"),
        id: "road-side-pylon-signboard-at-klang-port-klang-meru-ijok"
      },
      {
        name: "ROADSIDE PYLON SIGNAGE MAKER AT SELANGOR _ PUNCAK ALAM _ SUBANG _ USJ",
        image: require("../assets/products/PYLON SIGNAGE/ROADSIDE PYLON SIGNAGE MAKER AT SELANGOR _ PUNCAK ALAM _ SUBANG _ USJ.webp"),
        id: "roadside-pylon-signage-maker-at-selangor-puncak-alam-subang-usj"
      },
      {
        name: "ROADSIDE PYLON STAND SIGNAGE AT MALAYSIA _ KUALA LUMPUR (KL) _ AMPANG",
        image: require("../assets/products/PYLON SIGNAGE/ROADSIDE PYLON STAND SIGNAGE AT MALAYSIA _ KUALA LUMPUR (KL) _ AMPANG.webp"),
        id: "roadside-pylon-stand-signage-at-malaysia-kuala-lumpur-kl-ampang"
      },
      {
        name: "TENANT PYLON SIGNAGE INSTALLED AT KLANG _ BAYU EMAS _ JENJAROM",
        image: require("../assets/products/PYLON SIGNAGE/TENANT PYLON SIGNAGE INSTALLED AT KLANG _ BAYU EMAS _ JENJAROM.webp"),
        id: "tenant-pylon-signage-installed-at-klang-bayu-emas-jenjarom"
      },
      {
        name: "_WCT Project_ Pylon Signage",
        image: require("../assets/products/PYLON SIGNAGE/_WCT Project_ Pylon Signage.webp"),
        id: "wct-project-pylon-signage"
      },
    ],
  },
  {
    category: "Printing",
    categoryImage: require("../assets/products/Printing/Event Backdrop Printing.jpeg"),
    id: "printing",
    description: `Enhance your events and promotions with our high-quality printing services. From event backdrops to promotional banners, we offer a wide range of printing solutions that can be customized to suit your needs. Our prints are vibrant, durable, and designed to make a strong visual impact. Contact us to discuss your printing requirements and let us help you create stunning printed materials.`,
    products: [
      {
        name: "Event Backdrop Printing",
        image: require("../assets/products/Printing/Event Backdrop Printing.jpeg"),
        id: "event-backdrop-printing"
      },
    ],
  },
  {
    category: "Project",
    categoryImage: require("../assets/products/Project/Project Hoarding Board.jpg"),
    id: "project",
    description: `Ensure your projects are well-communicated with our professional project signage. Ideal for construction sites, development projects, and public notices, our signs are designed for clarity and durability. We offer custom designs to suit the specific needs of your project, ensuring that all information is clearly conveyed. Let us help you manage your project communications with our bespoke signage solutions.`,
    products: [
      {
        name: "Project Hoarding Board",
        image: require("../assets/products/Project/Project Hoarding Board.jpg"),
        id: "project-hoarding-board"
      },
    ],
  },
  {
    category: "Road Sign",
    categoryImage: require("../assets/products/Road Sign/AWAS Kurangkan Laju road sign Malaysia.jpg"),
    id: "road-sign",
    description: `Enhance road safety with our high-visibility road signs. Designed to meet regulatory standards, our signs are durable and weather-resistant. Suitable for highways, streets, and private roads, we offer a range of customizable options to suit different requirements. Contact us to create road signs that ensure clear and effective communication on the road.`,
    products: [
      {
        name: "AWAS Kurangkan Laju road sign Malaysia",
        image: require("../assets/products/Road Sign/AWAS Kurangkan Laju road sign Malaysia.jpg"),
        id: "awas-kurangkan-laju-road-sign-malaysia"
      },
      {
        name: "Auto City Centre Entrance Banner",
        image: require("../assets/products/Road Sign/Auto City Centre Entrance Banner.jpeg"),
        id: "auto-city-centre-entrance-banner"
      },
      {
        name: "Outdoor Advertisement Board",
        image: require("../assets/products/Road Sign/Outdoor Advertisement Board.jpeg"),
        id: "outdoor-advertisement-board"
      },
      {
        name: "Resort Directory Road Sign",
        image: require("../assets/products/Road Sign/Resort Directory Road Sign.jpg"),
        id: "resort-directory-road-sign"
      },
    ],
  },
  {
    category: "Shop Lot Signboard",
    categoryImage: require("../assets/products/Shop Lot Signboard/Cafe 3D Boxup Signboard.jpeg"),
    id: "shop-lot-signboard",
    description: `Attract customers with our eye-catching shop lot signboards. Perfect for retail spaces, restaurants, and service businesses, our signboards are designed for maximum visibility and appeal. We offer custom designs to match your branding and help your business stand out. Let us create a shop lot signboard that draws attention and enhances your storefront.`,
    products: [
      {
        name: "Cafe 3D Boxup Signboard",
        image: require("../assets/products/Shop Lot Signboard/Cafe 3D Boxup Signboard.jpeg"),
        id: "cafe-3d-boxup-signboard"
      },
      {
        name: "Coliving Shoplot Signboard",
        image: require("../assets/products/Shop Lot Signboard/Coliving Shoplot Signboard.jpg"),
        id: "coliving-shoplot-signboard"
      },
      {
        name: "Hair Salon Signboard",
        image: require("../assets/products/Shop Lot Signboard/Hair Salon Signboard.jpeg"),
        id: "hair-salon-signboard"
      },
      {
        name: "Kedai Kosmetik Signboard 3D Boxup",
        image: require("../assets/products/Shop Lot Signboard/Kedai Kosmetik Signboard 3D Boxup.jpeg"),
        id: "kedai-kosmetik-signboard-3d-boxup"
      },
      {
        name: "Kedai Kosmetik Signboard 3D",
        image: require("../assets/products/Shop Lot Signboard/Kedai Kosmetik Signboard 3D.jpeg"),
        id: "kedai-kosmetik-signboard-3d"
      },
      {
        name: "Klinik 3D Boxup Customised Signboard",
        image: require("../assets/products/Shop Lot Signboard/Klinik 3D Boxup Customised Signboard.jpeg"),
        id: "klinik-3d-boxup-customised-signboard"
      },
      {
        name: "Shop Entrace Signboard ",
        image: require("../assets/products/Shop Lot Signboard/Shop Entrace Signboard .jpeg"),
        id: "shop-entrace-signboard"
      },
      {
        name: "Shoplot billboard banner signboard",
        image: require("../assets/products/Shop Lot Signboard/Shoplot billboard banner signboard.jpg"),
        id: "shoplot-billboard-banner-signboard"
      },
      {
        name: "Shoplot signboard",
        image: require("../assets/products/Shop Lot Signboard/Shoplot signboard.jpg"),
        id: "shoplot-signboard"
      },
      {
        name: "Thai Kitchen 3D Box Up Shoplot Signboard",
        image: require("../assets/products/Shop Lot Signboard/Thai Kitchen 3D Box Up Shoplot Signboard.jpeg"),
        id: "thai-kitchen-3d-box-up-shoplot-signboard"
      },
    ],
  },
  {
    category: "Signbased Series",
    categoryImage: require("../assets/products/Signbased Series/ALUMINIUM STRIP CEILING/ALUMINIUM CEILING TRIM CASING 3D BOX UP SIGNBOARD AT KEPONG _ TAMAN OUG _ KAJANG.webp"),
    id: "signbased-series",
    description: ``,
    subcategories: [
      {
        category: "ALUMINIUM STRIP CEILING",
        categoryImage: require("../assets/products/Signbased Series/ALUMINIUM STRIP CEILING/ALUMINIUM CEILING TRIM CASING 3D BOX UP SIGNBOARD AT KEPONG _ TAMAN OUG _ KAJANG.webp"),
        id: "aluminium-strip-ceiling",
        description: ``,
        products: [
          {
            name: "ALUMINIUM CEILING TRIM CASING 3D BOX UP SIGNBOARD AT KEPONG _ TAMAN OUG _ KAJANG",
            image: require("../assets/products/Signbased Series/ALUMINIUM STRIP CEILING/ALUMINIUM CEILING TRIM CASING 3D BOX UP SIGNBOARD AT KEPONG _ TAMAN OUG _ KAJANG.webp"),
            id: "aluminium-ceiling-trim-casing-3d-box-up-signboard-at-kepong-taman-oug-kajang"
          },
          {
            name: "ALUMINUM STRIP CEILING BASE SIGNAGE MANUFACTURER AT IJOK _ KEPONG _ KLANG _ SUBANG",
            image: require("../assets/products/Signbased Series/ALUMINIUM STRIP CEILING/ALUMINUM STRIP CEILING BASE SIGNAGE MANUFACTURER AT IJOK _ KEPONG _ KLANG _ SUBANG.webp"),
            id: "aluminum-strip-ceiling-base-signage-manufacturer-at-ijok-kepong-klang-subang"
          },
          {
            name: "PET SHOP 3D ALUMINIUM CEILING TRIM CASING SIGNAGE SUPPLIER AT KUALA LUMPUR (KL) _ AMPANG _ KEPONG",
            image: require("../assets/products/Signbased Series/ALUMINIUM STRIP CEILING/PET SHOP 3D ALUMINIUM CEILING TRIM CASING SIGNAGE SUPPLIER AT KUALA LUMPUR (KL) _ AMPANG _ KEPONG.webp"),
            id: "pet-shop-3d-aluminium-ceiling-trim-casing-signage-supplier-at-kuala-lumpur-kl-ampang-kepong"
          },
          {
            name: "PET SHOP 3D ALUMINIUM CEILING TRIM CASING SIGNAGE SUPPLIER AT KUALA LUMPUR (KL)",
            image: require("../assets/products/Signbased Series/ALUMINIUM STRIP CEILING/PET SHOP 3D ALUMINIUM CEILING TRIM CASING SIGNAGE SUPPLIER AT KUALA LUMPUR (KL).webp"),
            id: "pet-shop-3d-aluminium-ceiling-trim-casing-signage-supplier-at-kuala-lumpur-kl"
          },
        ]
      },
      {
        category: "Aluminium Box Louves",
        categoryImage: require("../assets/products/Signbased Series/Aluminium Box Louves/Aluminium box louves facade.jpg"),
        id: "aluminium-box-louves",
        description: ``,
        products: [
          {
            name: "Aluminium box louves facade",
            image: require("../assets/products/Signbased Series/Aluminium Box Louves/Aluminium box louves facade.jpg"),
            id: "aluminium-box-louves-facade"
          },
          {
            name: "Aluminium box louves(1)",
            image: require("../assets/products/Signbased Series/Aluminium Box Louves/Aluminium box louves(1).jpg"),
            id: "aluminium-box-louves-1"
          },
          {
            name: "Aluminium box louves",
            image: require("../assets/products/Signbased Series/Aluminium Box Louves/Aluminium box louves.jpg"),
            id: "aluminium-box-louves"
          },
        ]
      },
      {
        category: "METAL PERFORATED",
        categoryImage: require("../assets/products/Signbased Series/METAL PERFORATED/METAL PERFORATED LED SIGNAGE SUPPLIER AT KUALA LUMPUR (KL) _ PETALING JAYA (PJ) _ MALAYSIA.webp"),
        id: "metal-perforated",
        description: ``,
        products: [
          {
            name: "METAL PERFORATED LED SIGNAGE SUPPLIER AT KUALA LUMPUR (KL) _ PETALING JAYA (PJ) _ MALAYSIA",
            image: require("../assets/products/Signbased Series/METAL PERFORATED/METAL PERFORATED LED SIGNAGE SUPPLIER AT KUALA LUMPUR (KL) _ PETALING JAYA (PJ) _ MALAYSIA.webp"),
            id: "metal-perforated-led-signage-supplier-at-kuala-lumpur-kl-petaling-jaya-pj-malaysia"
          },
          {
            name: "METAL PERFORATED SIGNBASED MAKER AT SELANGOR _ KUALA LUMPUR (KL) _ MALAYSIA",
            image: require("../assets/products/Signbased Series/METAL PERFORATED/METAL PERFORATED SIGNBASED MAKER AT SELANGOR _ KUALA LUMPUR (KL) _ MALAYSIA.webp"),
            id: "metal-perforated-signbased-maker-at-selangor-kuala-lumpur-kl-malaysia"
          },
        ]
      },
      {
        category: "Metal Zinc Deck",
        categoryImage: require("../assets/products/Signbased Series/Metal Zinc Deck/BEST OUTDOOR METAL ZINC DECK SIGNBOARD AT SELANGOR _ KUALA LUMPUR (KL) _ SUBANG _ IJOK.webp"),
        id: "metal-zinc-deck",
        description: ``,
        products: [
          {
            name: "BEST OUTDOOR METAL ZINC DECK SIGNBOARD AT SELANGOR _ KUALA LUMPUR (KL) _ SUBANG _ IJOK",
            image: require("../assets/products/Signbased Series/Metal Zinc Deck/BEST OUTDOOR METAL ZINC DECK SIGNBOARD AT SELANGOR _ KUALA LUMPUR (KL) _ SUBANG _ IJOK.webp"),
            id: "best-outdoor-metal-zinc-deck-signboard-at-selangor-kuala-lumpur-kl-subang-ijok"
          },
          {
            name: "zinc base",
            image: require("../assets/products/Signbased Series/Metal Zinc Deck/zinc base.jpg"),
            id: "zinc-base"
          },
        ]
      },
    ],
  },
  {
    category: "Sticker",
    categoryImage: require("../assets/products/Sticker/Reception counter sticker.jpeg"),
    id: "sticker",
    description: `Personalize your space with our custom stickers. Ideal for promotional purposes, branding, and decorative uses, our stickers are durable and vibrant. We offer a wide range of sizes and designs to fit your specific needs. Contact us to create unique stickers that help you make a statement.`,
    products: [
      {
        name: "Reception counter sticker",
        image: require("../assets/products/Sticker/Reception counter sticker.jpeg"),
        id: "reception-counter-sticker"
      },
      {
        name: "Wall Quote",
        image: require("../assets/products/Sticker/Wall Quote.jpg"),
        id: "wall-quote"
      },
      {
        name: "Wall Sticker Printing",
        image: require("../assets/products/Sticker/Wall Sticker Printing.jpg"),
        id: "wall-sticker-printing"
      },
    ],
  },
  {
    category: "Wall Decor",
    categoryImage: require("../assets/products/Wall Decor/3D LED Backlit Signage .jpeg"),
    id: "wall-decor",
    description: `Transform your space with our stunning wall decor solutions. Perfect for offices, homes, and commercial spaces, our wall decor options include everything from 3D signs to backlit displays. Customized to reflect your style and preferences, our wall decor enhances the ambiance and aesthetic of any room. Let us help you create a captivating and personalized wall decor solution.`,
    products: [
      {
        name: "3D LED Backlit Signage ",
        image: require("../assets/products/Wall Decor/3D LED Backlit Signage .jpeg"),
        id: "3d-led-backlit-signage"
      },
      {
        name: "3D LED Backlit Signage",
        image: require("../assets/products/Wall Decor/3D LED Backlit Signage.jpeg"),
        id: "3d-led-backlit-signage"
      },
      {
        name: "Acrylic 3D Alphabet Wall Decorr",
        image: require("../assets/products/Wall Decor/Acrylic 3D Alphabet Wall Decorr.jpeg"),
        id: "acrylic-3d-alphabet-wall-decorr"
      },
      {
        name: "Acrylic 3D LED Backlit Office Wall Decor Signage",
        image: require("../assets/products/Wall Decor/Acrylic 3D LED Backlit Office Wall Decor Signage.jpeg"),
        id: "acrylic-3d-led-backlit-office-wall-decor-signage"
      },
      {
        name: "Acrylic 3D Office Wall Decor Signage",
        image: require("../assets/products/Wall Decor/Acrylic 3D Office Wall Decor Signage.jpeg"),
        id: "acrylic-3d-office-wall-decor-signage"
      },
      {
        name: "Acrylic 3D Signage for Indoor Office",
        image: require("../assets/products/Wall Decor/Acrylic 3D Signage for Indoor Office.jpeg"),
        id: "acrylic-3d-signage-for-indoor-office"
      },
      {
        name: "Acrylic Frame Wall Decor ",
        image: require("../assets/products/Wall Decor/Acrylic Frame Wall Decor .jpeg"),
        id: "acrylic-frame-wall-decor"
      },
      {
        name: "Office Signboard ",
        image: require("../assets/products/Wall Decor/Office Signboard .jpeg"),
        id: "office-signboard"
      },
      {
        name: "Stainless Steel 3D Signage Indoor Wall Decor",
        image: require("../assets/products/Wall Decor/Stainless Steel 3D Signage Indoor Wall Decor.jpeg"),
        id: "stainless-steel-3d-signage-indoor-wall-decor"
      },
    ],
  },
];

export default productData;