import React from 'react';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import "./contact_us.scss";

export default function ContactUs() {
  return (
    <div id="contact" className="contact-container">
      <div className="container">
        <div className="row justify-content-center text-center mb-5">
          <div className="col-md-8">
            <h2 className="section-title">Get in Touch</h2>
            <p className="section-subtitle">Want to get in touch? We'd love to hear from you. Here's how you can reach us.</p>
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-5 mb-4">
            <div className="contact-card">
              <div className="contact-icon">
                <FaPhoneAlt />
              </div>
              <h3 className="contact-title">Phone Number</h3>
              <p>Interested in our services? Just pick up the phone to chat with a member of our team.</p>
              <p className="contact-info"><a href="tel:+60162250017" >016 - 225 0017</a></p>
            </div>
          </div>
          <div className="col-md-5 mb-4">
            <div className="contact-card">
              <div className="contact-icon">
                <FaEnvelope />
              </div>
              <h3 className="contact-title">Email</h3>
              <p>Interested in our services? Just send us an email to reach out to a member of our team.</p>
              <p className="contact-info"><a href="mailto:lalasigns99@gmail.com">lalasigns99@gmail.com</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
