import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Landing from "./Home/Landing";
import About from "./About/About";
import ContactUs from "./ContactUs/ContactUs";
import ProductCatalogPage from "./ProductCatalogPage/ProductCatalogPage";
import ProductSubCatalogPage from "./ProductSubCatalogPage/ProductSubCatalogPage";
import ProductDetail from "./ProductDetailPages/ProductDetailsPage";

export default function Router() {
    return (
        <ScrollToTop>
            <Routes>
                <Route path='/' element={<Landing />} />
                <Route path='/about' element={<About />} />
                <Route path='/products' element={<ProductCatalogPage />} />
                <Route path='/products/:categoryName/p/:productName' element={<ProductDetail />} />
                <Route path='/products/:categoryName/:subcategoryName/p/:productName' element={<ProductDetail />} />
                <Route path='/products/:categoryName' element={<ProductSubCatalogPage />} />
                <Route path="/products/:categoryName/:subcategoryName" element={<ProductSubCatalogPage />} />
                <Route path='/contact' element={<ContactUs />} />
            </Routes>
        </ScrollToTop>
    )
}
