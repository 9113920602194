import React, { useEffect, useState } from 'react';
import productData from '../../data/ProductData.js'; // Import the dynamically generated product data
import './ProductCatalogPage.scss'; 

const ProductCatalogPage = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setCategories(productData);
  }, []);

  return (
    <section id="collection-1577">
      <div className="cs-container">
        <div className="cs-content">
          <h2 className="cs-title">Products</h2>
        </div>
        <ul className="cs-card-group">
          {categories.map((category, index) => (
            <li key={index} className="cs-item">
              <div className="cs-card-wrapper">
                <a href={"/products/" + category.id} className="cs-link">
                  <h5 className="cs-category">{category.category}</h5>
                  <picture className="cs-background" aria-hidden="true">
                    <source media="(max-width: 600px)" srcSet={category.categoryImage} />
                    <source media="(min-width: 601px)" srcSet={category.categoryImage} />
                    <img
                      loading="lazy"
                      decoding="async"
                      src={category.categoryImage}
                      width="365"
                      height="201"
                      alt={category.category}
                    />
                  </picture>
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ProductCatalogPage;
