import '../Footer/footer.scss';
import logo_dark from "../../assets/logo_dark.jpg";

export default function Footer() {
	return (
		<footer className="footer bg-dark">
			<div className="container py-4" style={{ paddingBottom: "0px" }}>
				<div className="row py-1">
					<div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
						<img src={logo_dark} alt="Lalasigns" width="150" className="mb-3" />
					</div>
					<div className="col-lg-2 col-md-6 mb-4 mb-lg-0 mt-2">
						<h6 className="text-uppercase font-weight-bold mb-4 footer-title-text">Our Group</h6>
						<ul className="list-unstyled mb-0">
							<li className="mb-2">
								<a href="/about" className="className footer-link footer-text">About Us</a>
							</li>
							<li className="mb-2">
								<a href="/products" className="className footer-link footer-text">Products</a>
							</li>
							<li className="mb-2">
								<a href="/contact" className="className footer-link footer-text">Contact Us</a>
							</li>
						</ul>
					</div>
					<div className="col-lg-4 col-md-6 mb-lg-0">
						<div className='footer-padding-bottom' style={{ display: "flex", alignItems: "column" }}>
							<div className="bi bi-geo-alt-fill className mb-4 footer-text"></div>
							<p className="footer-text" style={{ marginTop: 8 }}> No.  210, jalan Batu unjur 7, taman bayu perdana,  41200 klang,  Selangor</p>
						</div>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div className='footer-padding-bottom'>
								<div className="footer-text bi bi-telephone-fill" style={{ color: "white" }} ><a className="footer-text footer-link" href="tel:+60162250017" >016 - 225 0017</a></div>
							</div>
						</div>
						<div className='footer-padding-bottom'>
							<div className="bi bi-envelope-fill className mb-4 footer-text" style={{ color: "white" }}><a className="footer-link footer-text" href="mailto:lalasigns99@gmail.com">lalasigns99@gmail.com</a></div>
						</div>
						
					</div>
				</div>
			</div>
			<hr className='container white-line' />
			<div className="container all-right-reserved py-3 d-flex justify-content-between">
				<div className="text-left p-1">
					<p className="className mb-0">© 2024 Lalasigns. All rights reserved.</p>
				</div>
			</div>

		</footer>
	)
}