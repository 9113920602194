import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './productdetailpage.scss';
import productData from '../../data/ProductData.js';
import WhyUsComponent from './WhyUsComponent/WhyUsComponent';

const ProductDetail = () => {
  const { categoryName, subcategoryName, productName } = useParams();
  const [selectedImage, setSelectedImage] = useState(0);
  const [subcategory, setSubcategory] = useState(null);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    const categoryData = productData.find(cat => cat.id === categoryName);
    setCategory(categoryData);

    if (categoryData && categoryData.subcategories && subcategoryName) {
      const foundSubcategory = categoryData.subcategories.find(sub => sub.id === subcategoryName);
      setSubcategory(foundSubcategory);
    } else {
      setSubcategory(null);
    }
  }, [categoryName, subcategoryName]);

  if (!category || (subcategoryName && !subcategory)) {
    return <div>Product not found</div>;
  }

  let product = null;

  if (subcategory) {
    product = subcategory.products.find(prod => prod.id === productName);
  } else {
    product = category.products.find(prod => prod.id === productName);
  }

  if (!product) {
    return <div>Product not found</div>;
  }


  return (
    <>
      <div className="product-detail-container">
        <div className="breadcrumb">
          <span className="breadcrumb-path">
            <span><Link to="/"><i className="fa fa-home" aria-hidden="true"></i> Home</Link></span>
            <span> &gt; </span> 
            <span><Link to="/products">Products</Link></span> 
            <span> &gt; </span>
            <span><Link to={`/products/${categoryName}`}>{category.category}</Link></span>
            {subcategory && <>
              <span> &gt; </span>
              <span><Link to={`/products/${categoryName}/${subcategoryName}`}>{subcategory.category}</Link></span>
            </>}
            <span> &gt; </span>
            <span>{product.name}</span>
          </span>
        </div>
        <div className="product-detail-card">
          <div className="image-section">
            <div className="main-image-container">
              <img src={product.image} alt={`${product.name} main`} className="main-product-image" />
            </div>
            {/* Uncomment and update this section if you have multiple images */}
            {/* <div className="thumbnail-images">
              {product.imageList.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.name} thumbnail ${index + 1}`}
                  className={`thumbnail-image ${index === selectedImage ? 'selected' : ''}`}
                  onClick={() => setSelectedImage(index)}
                />
              ))}
            </div> */}
          </div>
          <div className="info-section">
            <h2 className="product-title">{product.name}</h2>
            <hr style={{ color: '#FE7F00', borderTopWidth: '3px' }} />
            <div className="product-description">
              <p>Premium Outdoor Signboard</p>
              <p>Elevate your business visibility with our {product.name}, designed for durability and maximum impact. Crafted from high-quality materials, this signboard is perfect for both commercial and personal use, ensuring that your message is clearly seen day or night.</p>
              <p><b>Key Features:</b></p>
              <p>High-Visibility Design: Available in a range of vibrant colors and sizes, our signboards ensure that your message stands out, catching the eye of potential customers from a distance.</p>
              <p>Durable Materials: Made from weather-resistant aluminum and reinforced plastic, this signboard withstands harsh weather conditions, ensuring longevity and reliability.</p>
              <p>Customizable Options: Personalize your signboard with custom graphics, logos, and text to reflect your brand identity and message effectively.</p>
              <p>Easy Installation: With pre-drilled holes and mounting hardware included, installation is a breeze, allowing you to set up your signboard quickly and securely.</p>
              <p>LED Illumination (Optional): Upgrade to our LED-lit signboards for enhanced nighttime visibility, ensuring your message is seen 24/7.</p>
              <p>Applications:</p>
              <p>Why Choose Us?</p>
              <p>At Lalasign, we are committed to providing high-quality and express signage solutions that meet your needs.</p>
              <p>Order Yours Today!</p>
            </div>
            <div>
              <a href="/contact"><button className="product-button">Contact Us</button></a>
            </div>
          </div>
        </div>
      </div>
      <WhyUsComponent />
    </>
  );
};

export default ProductDetail;