import '../Home/landing.scss';
import Carousel from '../../component/Carousel/Carousel';
import Services from './Services/Services';
import TopCategories from './TopCategories/TopCategories';
import Pricing from './Pricing/Pricing';
import Introduction from './Introduction/Introduction';
import ContactUs from './ContactUs/ContactUs';
import WhyUs from './WhyUs/whyus';
import TopCategoriesStyle2 from './TopCategoriesStyle2/TopCategoriesStyle2';

export default function Landing() {
    return (
        <div>
            <Carousel />
            <Services />
            <TopCategoriesStyle2 />
            {/* <Pricing /> */}
            <TopCategories />
            <Introduction />
            <WhyUs />
            <ContactUs />
            {/* <TopProducts /> */}
        </div>
        
        
    );
}