import React from 'react';
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import contactImage from '../../../assets/logo.jpg'; 
import './contactus.scss';

const ContactUs = () => {
  return (
    <section className="contact-us-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h2 className="section-title">Get in Touch</h2>
            <p className="section-subtitle">Want to know more? Contact us, we will reply ASAP.</p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="contact-info text-center">
              <div className="contact-item">
                <FaEnvelope className="contact-icon" />
                <span>Email: <a href="mailto:lalasigns99@gmail.com">lalasigns99@gmail.com</a></span>
              </div>
              <div className="contact-item">
                <FaPhoneAlt className="contact-icon" />
                <span>Phone: <a href="tel:+60162250017" >016 - 225 0017</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
